let userService = require("../services/userService");
const loading = require('../helpers/loading');
const logger = require('../helpers/clientLogger');

$("#change-password-button").on("click", function (e) {
    e.preventDefault();
    loading.block();

    var data = {
        NewPassword: $('#new-password').val(),
        Token: $("#token").val()
    }

    userService.completePasswordReset(data)
    .then(function(){
        bootoast.toast({
            message: "Password has been reset successfully. Please try logging in now.",
            timeout: false,
            type: "success",
            icon: "alert"
        });
    }).fail(function(err) {
        logger.error(err);
        bootoast.toast({
            message: "Request to change the password has failed. Please try refreshing the page to try again or submit a new request for a password reset.",
            timeout: false,
            type: "success",
            icon: "alert"
        });
    }).always(function(){
        loading.release();
    });

});

$('#new-password').on('input', function () {
    checkLength($('#new-password').val());
    checkCase($('#new-password').val());
    checkNumber($('#new-password').val());
});

$('#r-new-password').on('input', function () {
    comparePasswords($("#new-password").val(), $("#r-new-password").val())
});



function checkLength(pass) {
    if (pass == "" || pass == null)
        toggleIcon("pc-length", "Clear");
    else
        if (pass.length < 8)
            toggleIcon("pc-length", "Invalid");
        else
            toggleIcon("pc-length", "Valid");
}

function checkCase(pass) {
    if (pass == "" || pass == null) {
        toggleIcon("pc-uppercase", "Clear");
        toggleIcon("pc-lowercase", "Clear");
    }
    else {
        if ((pass.match(/[A-Z]/g) || []).length > 0)
            toggleIcon("pc-uppercase", "Valid");
        else
            toggleIcon("pc-uppercase", "Invalid");


        if ((pass.match(/[a-z]/g) || []).length > 0)
            toggleIcon("pc-lowercase", "Valid");
        else
            toggleIcon("pc-lowercase", "Invalid");
    }
}
function checkNumber(pass) {
    if (pass == "" || pass == null)
        toggleIcon("pc-number", "Clear");
    else
        if ((pass.match(/[0-9]/g) || []).length > 0)
            toggleIcon("pc-number", "Valid");
        else
            toggleIcon("pc-number", "Invalid");
}
function comparePasswords(pass, passr) {
    if (pass.length == 0 || passr.length == 0)
        toggleIcon("pc-match", "Clear");
    else
        if (pass == passr)
            toggleIcon("pc-match", "Valid");
        else
            toggleIcon("pc-match", "Invalid");
}


function toggleIcon(iconClass, state) {
    switch (state) {
        case "Valid":
            $("." + iconClass).addClass("text-success").removeClass("text-danger");
            $("."+iconClass+" i").addClass("fa-check").removeClass("fa-times");
            break;
        case "Invalid":
            $("." + iconClass).removeClass("text-success").addClass("text-danger");
            $("."+iconClass+" i").removeClass("fa-check").addClass("fa-times");
            break;
        case "Clear":
            $("." + iconClass).removeClass("text-success").removeClass("text-danger");
            $("."+iconClass+" i").removeClass("fa-check").addClass("fa-times");
            break;
    }

    if ($(".password-requirements .text-success").length < 5) {
        $("#change-password-button").addClass("disabled");
    }
    else {
        $("#change-password-button").removeClass("disabled");
    }

}