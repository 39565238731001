const loading = require("../helpers/loading");
const logger = require("../helpers/clientLogger");

/**
 * return true if parsed
 */
function parseError(error) {
    if (error.responseJSON && error.responseJSON.Error && error.responseJSON.Error.Message && error.responseJSON.Error.Message === "Invalid credentials!") {
        bootoast.toast({
            message: error.responseJSON.Error.Message,
            timeout: false,
            type: "danger",
            icon: "alert"
        });
        return true;
    } else if (error.responseJSON && error.responseJSON.Error && error.responseJSON.Error.Message && error.responseJSON.Error.Message.includes("User account is disabled")) {
        bootoast.toast({
            message: error.responseJSON.Error.Message,
            timeout: false,
            type: "danger",
            icon: "alert"
        });
        return true;
    } else if (error.status === 401) {
        localStorage.clear();
        loading.release();
        window.location.href = "/expired";
        return true;
    } else {
        logger.error(error);
    }
    return false;
}

function ajaxErrors(jqXHR, textStatus, err) {
    console.log("Error: " + err);
    console.log("Text Status: " + textStatus);
    console.log(jqXHR);
    if (jqXHR.responseJSON != undefined) {
        console.log(jqXHR.responseJSON.Exception.originalError);
    }
}

function log(errNo, errMsg, resText) {
    try {
        console.error("Error: " + errNo + " - " + errMsg + "\n Response:", JSON.parse(resText));
    } catch (error) {
        console.error("Error: " + errNo + " - " + errMsg + "\n Response: " + resText);
    }
}

/**
 * Handles an error by logging it to error console and displaying a toast message to user
 * 
 * @param err {object} - the error object
 * @param msg {string} - the message to display to the web user
 */
function handleWithToast(err, msg) {
    if (!parseError(err)) {
        log(err.status, err.statusText, err.responseText);
        bootoast.toast({
            message: msg,
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    }
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    ajaxErrors: ajaxErrors,
    log: log,
    handleWithToast: handleWithToast
}