const error = require('./errorHandling');
const microLayer = require('./microLayerService');
const logger = require('../helpers/clientLogger');

function getUniversities() {
    return microLayer.execute(microLayer.httpMethods.GET, "public/organisations")
        .then(function(response) {
            return response
        })
        .fail(function(err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving universities.")
        });
}

function getOrganisationById(id) { 
    return microLayer.execute(microLayer.httpMethods.GET, "public/organisations/" + id)
        .then(function(response) {
            return response
        })
        .fail(function(err) {
            logger.error(err);        
            error.handleWithToast(err, "There was an error retrieving university.")
        });
}

function getSettingByOrganisationSettingsKey(data) {
    return microLayer.execute(microLayer.httpMethods.PUT, "organisationSettings/byOrganisationSettingsKey", data)
        .then(function(response) {
            return response
        })
        .fail(function(err) {
            logger.error(err);            
            error.handleWithToast(err, "There was an error retrieving university.")
        });
}

function getPageSettingByOrganisationId(organisationId) {
    return microLayer.execute(microLayer.httpMethods.GET, "public/organisationSettings/" +organisationId + "/InstitutePageSettings")
        .then(function(response) {
            return response
        })
        .fail(function(err) {
            logger.error(err);            
            error.handleWithToast(err, "There was an error retrieving institute page setting.")
        });
}

function getFacultiesByUniversityId(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "public/universities/" + id + "/faculties")
        .then(function(response) {
            return response
        })
        .fail(function(err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving university faculties.")
        });
}

function getDepartmentsByUniversityId(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "public/universities/" + id + "/departments")
        .then(function(response) {
            return response
        })
        .fail(function(err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving university departments.")
        });
}

function getDepartmentsByUniversityIdFacultyId(uniId, facultyId) {
    return microLayer.execute(microLayer.httpMethods.GET, "public/universities/" + uniId + "/faculties/" + facultyId + "/departments")
        .then(function(response) {
            return response
        })
        .fail(function(err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving university faculty departments.")
        });
}

function getCourseTypesByUniversityId(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "public/universities/" + id + "/courseTypes")
        .then(function(response) {
            return response
        })
        .fail(function(err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving course types.")
        });
}

function getAllStudyModes() {
    return microLayer.execute(microLayer.httpMethods.GET, "public/studymodes")
        .then(function(response) {
            return response
        })
        .fail(function(err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving study modes.")
        });
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    getUniversities: getUniversities,
    getOrganisationById: getOrganisationById,
    getSettingByOrganisationSettingsKey: getSettingByOrganisationSettingsKey,
    getPageSettingByOrganisationId: getPageSettingByOrganisationId,
    getFacultiesByUniversityId: getFacultiesByUniversityId,
    getDepartmentsByUniversityId: getDepartmentsByUniversityId,
    getDepartmentsByUniversityIdFacultyId: getDepartmentsByUniversityIdFacultyId,
    getCourseTypesByUniversityId: getCourseTypesByUniversityId,
    getAllStudyModes: getAllStudyModes
}