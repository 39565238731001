const instituteBuilder = require('../builders/instituteBuilder');
const instituteService = require('../services/instituteService');
const applyService = require('../services/applyService');
const registerService = require('../services/registerService');
const coursesService = require('../services/coursesService');
const userService = require("../services/userService");
const v1Service = require("../services/v1Service");
const urlHelper = require("../helpers/urlHelper");
const loading = require("../helpers/loading");
const logger = require('../helpers/clientLogger');
const { getSettingByOrganisationSettingsKey } = require('../services/instituteService');

/*****************************************************************
 *                          EVENTS
 ****************************************************************/

$("#courseSearchForm").on("submit", function(e) {
    e.preventDefault();
    sessionStorage.removeItem("courseId"); // Clear any hidden course ids
    loading.block();
    let searchObj = {
        OpenOnly: 0,
        SearchQuery: $("#courseSearchTerm").val().trim(),
        OrganisationId: parseInt(urlHelper.getUrlParam(2))
    };
    let facId = parseInt($("#facultySelect").val());
    let deptId = parseInt($("#departmentSelect").val());
    let typeId = parseInt($("#courseTypeSelect").val());
    // ID's that equal zero mean 'Any', so exlude from search object
    if (facId > 0) {
        searchObj.FacultyId = facId;
    }
    if (deptId > 0) {
        searchObj.DepartmentId = deptId;
    }
    if (typeId > 0) {
        searchObj.CourseTypeId = typeId;
    }
    if ($("#courseAvailibility").prop("checked")) {
        searchObj.OpenOnly = 1;
    }
    // TODO: remove console log
    console.log("searchObj", searchObj);

    // Check if org is v1 or v2
    if (sessionStorage.getItem("orgv") === "1") {
        if (localStorage.getItem("SessionToken") === null) {
            v1Service.getCourses(searchObj, null).then(function(courses) {
                instituteBuilder.populateSearchResults(courses, null, null);
            }).fail(function (err) {
                logger.error(err);
                bootoast.toast({
                    message: "An error has occurred.",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
            }).always(function(e){
                loading.release();
            });
        } else {
            userService.me().then(function(me) {
                v1Service.getCourses(searchObj, me).then(function(courses) {
                    instituteBuilder.populateSearchResults(courses, me, null);
                }).fail(function (err) {
                    logger.error(err);
                    bootoast.toast({
                        message: "An error has occurred.",
                        timeout: false,
                        type: "danger",
                        icon: "alert"
                    });
                }).always(function(e){
                    loading.release();
                });
            });
        }
    } else {
        // Get v2 study modes
        let studyModes = {};
        instituteService.getAllStudyModes().then(function(modes) {
            modes.forEach(function(mode) {
                if (mode.OrganisationId === searchObj.OrganisationId) {
                    studyModes[mode.Id] = mode.Name;
                }
            });
            console.log("modes", modes);
            console.log("studyModes",studyModes);
            // Check if a user is logged in (to get eu status)
            if (localStorage.getItem("SessionToken") === null) {
                coursesService.searchCourses(searchObj).then(function(courses) {
                    instituteBuilder.populateSearchResults(courses, null, studyModes);
                    loading.release();
                }).fail(function (err) {
                    logger.error(err);
                    bootoast.toast({
                        message: "An error has occurred.",
                        timeout: false,
                        type: "danger",
                        icon: "alert"
                    });
                }).always(function(e){
                    loading.release();
                });
            } else {
                userService.me().then(function(me) {
                    coursesService.searchCourses(searchObj).then(function(courses) {
                        instituteBuilder.populateSearchResults(courses, me, studyModes);
                        loading.release();
                    }).fail(function (err) {
                        logger.error(err);
                        bootoast.toast({
                            message: "An error has occurred.",
                            timeout: false,
                            type: "danger",
                            icon: "alert"
                        });
                    }).always(function(e){
                        loading.release();
                    });
                }).fail(function (err) {
                    loading.release();
                    logger.error(err);
                    bootoast.toast({
                        message: "An error has occurred.",
                        timeout: false,
                        type: "danger",
                        icon: "alert"
                    });
                })
            }
        });
    }
});

$("#advancedSearchBtn").on("click", function(e) {
    e.preventDefault();
    $("#advancedSearchDiv").toggle(400);
});

$("#courseSearchTermClearBtn").on("click", function(e) {
    e.preventDefault();
    $("#courseSearchTerm").val("");
});

$("#faqBtn").on("click", function(e) {
    e.preventDefault();
    $("#faqModal").modal("show");
});

$(document.body).on("click", "#returnToTopBtn", function() {
    instituteBuilder.scrollToTopOfResults();
});

$("#facultySelect").on("change", function(e) {
    loading.block();
    instituteBuilder.buildDepartments($(this).val());
});

$("input:radio[name='courseType']").on("click", function(e) {
    console.log($("input:radio[name='courseType']:checked").val());
});

$("input:radio[name='courseAvailibility']").on("click", function(e) {
    console.log($("input:radio[name='courseAvailibility']:checked").val());
});

$(document.body).on("click", ".applyBtns", function(e) {
    e.preventDefault();
    loading.block();
    // let courseId = JSON.stringify([$(this).data("courseid")]);
    let courseId = $(this).data("courseid");
    let workflowid = $(this).data('workflowid');
    let options = {CourseIds: courseId};
    userService.me().then(function(me) {
        if (localStorage.getItem("UserTypeId") === "1") {
            options.UserId = me.UserId;
            checkForExistingBatches(options, me.UserName, workflowid);
        } else {

            $.when(
                instituteService.getSettingByOrganisationSettingsKey({ OrganisationId: urlHelper.getUrlParam(2), SettingsKey: "EnableAgents" }),
                userService.GetOrganisationAgentByUserIdOrganisationId(me.UserId, urlHelper.getUrlParam(2))
            ).done(function (orgSetting, orgAgent) {
                if(orgSetting.SettingsValue == false || orgSetting.SettingsValue == "false")
                {
                    bootoast.toast({
                        message: "This organisation does not accept agent applications.",
                        timeout: false,
                        type: "danger",
                        icon: "alert"
                    });
                    loading.release();
                    return;
                }
                if(orgAgent)
                {
                    if(orgAgent.AgentStatusId == 90)
                    {
                        bootoast.toast({
                            message: "You have been rejected from this organisation and can not create applications.",
                            timeout: false,
                            type: "danger",
                            icon: "alert"
                        });
                        loading.release();
                        return;
                    }
                }

                loading.release();

                $("#furtherDetails").hide();
                $("#applicantDetailsModal #applicantEmail").val("");
                $("#applicantDetailsModal #applicantFirstName").val("");
                $("#applicantDetailsModal #applicantLastName").val("");
                $("#applicantDetailsModal #address1").val("");
                $("#applicantDetailsModal #address2").val("");
                $("#applicantDetailsModal #city").val("");
                $("#applicantDetailsModal #postalcode").val("");
                $("#applicantDetailsModal #countryid option").first().prop("selected", true)
                $("#applicantDetailsModal #phonenumber").val("");
                $("#applicantDetailsModal #dateofbirth").val("");
                $("#applicantDetailsModal #eucitizen").prop("checked", false);
    
                $("#applicantDetailsModal").data("AgentUserId", me.UserId);
                $("#applicantDetailsModal").data("CourseId", courseId);
                $("#applicantDetailsModal").data("WorkflowId", workflowid);
                $("#applicantDetailsModal").modal("show");
            }).fail(function(err) {
                reject(err);
            });
            
        }
    }).fail(function (err) {
        logger.error(err);
        loading.release();
        bootoast.toast({
            message: "An error has occurred.",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    })
});

$("body").on('click', "#courseBatchModalAddAdditionalCoursesContinue", function(e) {
    e.preventDefault();
    loading.block();

    let batchid = $(this).data("batchid");
    let courseArray = [];

    $('#courseBatchModalCourseTable > tbody').children().each(function () {
        if ($(this).find(".fa-close").length > 0) {
            if ($(this).data('courseid') != undefined) {
                courseArray.push($(this).data('courseid'));
            }
        }
    });

    if (courseArray.length > 0) {
        var data = {
            CourseIds: JSON.stringify(courseArray)
        }
    
        applyService.addCoursesToBatch(batchid, data)
        .then(function () {
            $("#courseBatchModal").modal("hide");
            window.location.href = "/apply?b=" + batchid;
        }).fail(function (err) {
            logger.error(err);
            loading.release();
            bootoast.toast({
                message: "An error has occurred.",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
        })
    } else {
        if (batchid) {
            window.location.href = "/apply?b=" + batchid;
        } else {
            loading.release();
            logger.error(new Error("Add course - No batch found"));
        }
    }
});

$("body").on('click', "#applicantDetailsModalContinue", function(e) {
    e.preventDefault();
    if ($("#applicantEmail")[0].checkValidity()) {
        loading.block();
        applyService.getUserByUserName($("#applicantEmail").val()).then(function(user) {
            if ($.isEmptyObject(user) == false && user.UserTypeId != 1)
            {
                var errorMessage = $("#applicantEmail").val() + " is not an applicant account! Creating applications for non-applicant account is not allowed!";
                logger.error(errorMessage);
                bootoast.toast({
                    message: errorMessage,
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
                loading.release();              
                return;
            }

            if ($.isEmptyObject(user)) {
                if ($("#furtherDetails").is(":visible")) {
                    let newUser = {
                        UserName: $("#applicantEmail").val().trim(),
                        FirstName: $("#applicantFirstName").val().trim(),
                        LastName: $("#applicantLastName").val().trim(),
                        AddressLine1: $("#address1").val().trim(),
                        AddressLine2: $("#address2").val().trim(),
                        City: $("#city").val().trim(),
                        PostalCode: $("#postalcode").val().trim(),
                        CountryId: $("#countryid").val(),
                        PhoneNumber: $("#phonenumber").val().trim(),
                        DateOfBirth: $("#dateofbirth").val().trim(),
                        EUCitizen:  $("#eucitizen").prop("checked"),
                        OrganisationId: parseInt(urlHelper.getUrlParam(2))
                    }
                    console.log(newUser);
                    // TODO: remove this check when IE is no longer being supported (reportValidity is not available in IE)
                    if (typeof $('#applicantInfoForm')[0].reportValidity === "function") {
                        if ($('#applicantInfoForm')[0].reportValidity()) {
                            console.log("Form Valid (not IE)");
                            applyService.createApplicantForAgentApplication(newUser).then(function(user) {
                                let options = {
                                    CourseIds: $("#applicantDetailsModal").data("CourseId"),
                                    UserId: user.Data.UserV2.UserId,
                                    AgentUserId: $("#applicantDetailsModal").data("AgentUserId")
                                };
                                createBatchAndOpenCourseSearchModal(options, $("#applicantEmail").val(), $("#applicantDetailsModal").data("WorkflowId"));
                            }).fail(function (err) {
                                logger.error(err);
                            }).always(function(e){
                                loading.release();
                            });
                        } else {
                            loading.release();
                        }
                    } else {
                        if ($('#applicantInfoForm')[0].checkValidity()) {
                            console.log("Form Valid (IE)");
                            applyService.createApplicantForAgentApplication(newUser).then(function(user) {
                                let options = {
                                    CourseIds: $("#applicantDetailsModal").data("CourseId"),
                                    UserId: user.Data.UserV2.UserId,
                                    AgentUserId: $("#applicantDetailsModal").data("AgentUserId")
                                };
                                createBatchAndOpenCourseSearchModal(options, $("#applicantEmail").val(), $("#applicantDetailsModal").data("WorkflowId"));
                            }).fail(function (err) {
                                logger.error(err);
                                bootoast.toast({
                                    message: "An error has occurred.",
                                    timeout: false,
                                    type: "danger",
                                    icon: "alert"
                                });
                            }).always(function(e){
                                loading.release();
                            });
                        } else {
                            bootoast.toast({
                                message: "There are errors in the form, please update and try again.",
                                type: "danger",
                                icon: "alert"
                            });
                        }
                    }
                } else {
                    registerService.getCountries().then(function(countries) {
                        instituteBuilder.populateCountriesSelect(countries);
                        $("#furtherDetails").show(600);
                    }).fail(function (err) {
                        logger.error(err);
                        bootoast.toast({
                            message: "An error has occurred.",
                            timeout: false,
                            type: "danger",
                            icon: "alert"
                        });
                    }).always(function(e){
                        loading.release();
                    });
                }
            } else {
                let options = {
                    CourseIds: $("#applicantDetailsModal").data("CourseId"),
                    UserId: user.UserId,
                    AgentUserId: $("#applicantDetailsModal").data("AgentUserId")
                };
                createBatchAndOpenCourseSearchModal(options, $("#applicantEmail").val(), $("#applicantDetailsModal").data("WorkflowId"));
            }
        }).fail(function (err) {
            logger.error(err);
            loading.release();
            bootoast.toast({
                message: "An error has occurred.",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
        });
    } else {
        if (typeof $('#applicantEmail')[0].reportValidity === "function") {
            $('#applicantEmail')[0].reportValidity();
        } else {
            bootoast.toast({
                message: "Please enter a valid email address.",
                type: "danger",
                icon: "alert"
            });
        }
    }
});

function checkForExistingBatches(batchOptions, username, workflowid) {
    loading.block();
    applyService.getAvailableBatchesByCourseId(batchOptions.CourseIds).then(function(batches) {
        if (Array.isArray(batches) && batches.length > 0) {
            if (batches.length > 0) {
                applyService.addCoursesToBatch(batches[0].BatchId, { CourseIds: JSON.stringify([batchOptions.CourseIds]) }).then(function(batchId) {
                    window.location.href = "/apply?b=" + batchId;
                }).always(function(e){
                    loading.release();
                });
            } else {
                createBatchAndOpenCourseSearchModal(batchOptions, username, workflowid)
            }
        } else {
            createBatchAndOpenCourseSearchModal(batchOptions, username, workflowid)
        }
    }).fail(function (err) {
        logger.error(err);
        bootoast.toast({
            message: "An error has occurred.",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    }).always(function(e){
        loading.release();
    });
}

function createBatchAndOpenCourseSearchModal(batchOptions, username, workflowid) {
    $("#applicantDetailsModal").modal("hide");
    loading.block();
    batchOptions.CourseIds = JSON.stringify([batchOptions.CourseIds]);
    applyService.createBatch(batchOptions).then(function(batch) {
        applyService.GetCourseInfoByBatchId(batch[0].BatchId)
        .then(function(courses) {
            let orgid = urlHelper.getUrlParam();
            instituteBuilder.prepareAdditionalCourseModal(username, orgid, workflowid, courses).then(function () {
                $("#courseBatchModalAddAdditionalCourses").data("batchid", batch[0].BatchId);
                $("#courseBatchModalAddAdditionalCoursesContinue").data("batchid", batch[0].BatchId);
                $("#courseBatchModal").modal("show");
            }).catch(function (err) {
                logger.error(err);
                bootoast.toast({
                    message: "An error has occurred.",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
            }).always(function(e){
                loading.release();
            })
        }).fail(function (err) {
            logger.error(err);
            loading.release();
            bootoast.toast({
                message: "An error has occurred.",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
        })
    }).fail(function (err) {
        logger.error(err);
        loading.release();
        bootoast.toast({
            message: "An error has occurred.",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    })
}

$(document.body).on("click", ".courseBatchModalCourseCloseLink", function (e) {
    e.preventDefault();
    let id = $(this).data("courseid");
    instituteBuilder.deleteRowFromCourseBatch(id);
});


$(document.body).on("change", "#batchSelectModalList input", function (e) {
    e.preventDefault();
    $("#batchSelectModalList > label").removeClass("batchSelectModalListItemSelected");
    $(this).closest("label").addClass("batchSelectModalListItemSelected");
});

$("#blurbContent").on('click', '.cke-panel-header.cke-panel-loaded', function(e){ 
    var nextElemDOM = $(this).next();
    $(this).toggleClass("cke-header-collapsed");
    if($(nextElemDOM).hasClass("cke-panel-body"))
    {
        $(nextElemDOM).collapse('toggle');
    }
});