const moment = require('moment');
const fileService = require('../services/fileService');
const fileContainerService = require('../services/fileContainerService');
const applicationDetailsService = require('../services/applicationDetailsService');
const applyService = require('../services/applyService');
const instituteService = require('../services/instituteService');
const validActionTypes = require("../helpers/validActionTypes");
const pageSettingsHelper = require("../helpers/pageSettingsHelper");

// Stages are used as labels for the status/progress bar
const stages = [
    "Submitted",
    "Review",
    "Offer",
    "Post Offer",
    "Outcome"
];
// statusIdMap is used to map application status to the stages above (stages have a starting index of 1)
// Left number (as string) is the statusId
// Right number is the index of the 'stages' const above (starting index of 1)
let statusIdMap = {
    "5": 2,         // Review
    "7": 3,         // Offer
    "8": 4,         // Accepted
    "9": 5,         // Rejected
    "10": 5,        // Withdrawn
    "11": 4,        // Post Offer
    "15": 1,        // Submitted
    "17": 0,        // Unsubmitted
    "39": 2,        // Interview
    "50": 3,        // Conditional Offer
    "60": 5,        // Expired
    "70": 4,        // Paid
    "80": 2,        // On Hold
    "90": 5,        // Declined
    "100": 5,       // Successful
    "110": 5,       // Deferred
    "120": 5        // Cancelled
};

let _depositMatrix = [];
let _AttributeData = {};
let _Countries = [];

_depositMatrix.push([], []); //add status states
_depositMatrix.forEach(function (statusMatrix, i) {
    statusMatrix.push([], []) //add batch paid states
    statusMatrix.forEach(function (paidMatrix, j) {
        paidMatrix.push([], []) //add step deposit required states
        paidMatrix.forEach(function (stepMatrix, k) {
            //false / true for assessor request
            if (j == 1) {
                if (i == 0 && k == 0)
                    stepMatrix.push("Nothing", "Accept");
                else {
                    stepMatrix.push("Accept", "Accept");
                }
            }
            else {
                if (i == 0 && k == 0)
                    stepMatrix.push("Nothing", "Pay");
                else {
                    if (i == 1 && k == 0)
                        stepMatrix.push("Accept", "Pay");
                    else
                        stepMatrix.push("Pay", "Pay");
                }
            }
        })
    })
})

/****************************************************************
 *                          EXPORTED FUNCTIONS
 ****************************************************************/

/**
 * Attaches the details of a specific application to the page DOM
 * 
 * @param {object} application - the selected application
 * @param {array} pages - all the pages of the application template
 * @param {array} attributes - all the attributes (questions) of the application template
 * @param {array} values - all the values for the corresponding application template attributes
 */
function populateApplication(application, batch, pages, attributes, values, countries, logs, step, course, courseType, university, allowApplicantMessageKey, messages, depositPayment, assessorDocuments, applicationDocuments, applicantsDocuments, appsPerBatchLimit, isApplicationFormInvalid, applicantInfo, venue) {
    let pagesTabsHtml = "";
    let pagesTabsContentHtml = "";
    let pagesObj = {};
    let logsHtml = "";
    let pagesOrdered = [];
    let statusColHtml = "";
    let messageColHtml = "";
    let actionColHtml = "";
    let ReturnToAssessorMessagePrompt = false;
    let applicantReadOnly = (parseInt(localStorage.UserTypeId) == 1 && application.AgentUserId != null);
    let depositBtnFlag = false;
    let freeOfferFlag = false;
    let depositPaidFlag = false;

    let cardholderCountryCodeSelect = $("#cardholderCountryCode");
    countries.forEach(function(country) {
        $(cardholderCountryCodeSelect).append(`<option value="${country.CountryId}" data-isocode="${country.Iso3166Code}">${country.Name}</option>`);
    });

    $('#cardholderEmailAddress').val(applicantInfo.UserName);
    $('#cardholderAddressLine1').val(applicantInfo.AddressLine1);
    $('#cardholderAddressLine2').val(applicantInfo.AddressLine2);
    $('#cardholderCity').val(applicantInfo.City);
    $('#cardholderPostalCode').val(applicantInfo.PostalCode);
    $('#cardholderCountryCode').val(applicantInfo.CountryId);

    _Countries = generateCountriesDictionary(countries);

    fillcardHolderCountry(countries, "#cardholderMobilePhoneCountry")

    prepareAttributeValues(attributes, values);

    if (depositPayment.IsPaid) {
        depositPaidFlag = true;
    }
    pageSettingsHelper.setCourseText(course.OrganisationId);
    let courseStr = pageSettingsHelper.getCourseStringByOrganisationId(course.OrganisationId);
    $("#applicationDataContainer").data("batchId", batch[0].BatchId);
    $("#applicationDataContainer").data("applicationId", application.ApplicationId);
    $("#applicationDataContainer").data("organisationId", course.OrganisationId);
    $("#applicationDataContainer").data("workflowId", course.WorkflowId);
    $("#applicationDataContainer").data("IsApplicantEUCitizen", applicantInfo.EUCitizen);
    $("#applicationDataContainer").data("userId", application.AgentUserId ? application.AgentUserId : application.UserId);

    if (JSON.stringify(allowApplicantMessageKey) !== JSON.stringify({})) {
        if (allowApplicantMessageKey.SettingsValue == "true") {
            ReturnToAssessorMessagePrompt = true;
        }
    }

    if (messages != null) {
        console.log(messages);
    }

    if (step.DisplayConditionalOfferCheckMessages) {
        if (messages != null) {
            if (messages.length > 0) {
                $(".conditional-offer-checks").show();
                messages.forEach(function (message) {
                    $("#conditionalOfferChecks").append("<p>" + message.Message + "</p>");
                })
            }
        }
    }

    var closingDatePassed = !(applicantInfo.EUCitizen?applyService.isCourseOpen(course.EUOpeningDate, course.EUClosingDate):applyService.isCourseOpen(course.NonEUOpeningDate, course.NonEUClosingDate));


    if(closingDatePassed && step.StatusId == 17)
    {
        $("#closingDatePassedModal .panel-title").text("Closing date has passed");
        $("#closingDatePassedModal .panel-text").html(`<p>Closing date for this ${courseStr} has passed and it can no longer be submitted.<br />You may review the application and your answers or return to the dashboard.</p>`);
        
        $("#closingDatePassedModal").modal({
            backdrop: "static",
            keyboard: false
        });
    }
    if(application.IsCourseApplicationLimitReached && step.StatusId == 17)
    {
        $("#closingDatePassedModal .panel-title").text("Application limit reached");
        $("#closingDatePassedModal .panel-text").html(`<p>Your application can not be submitted as there is a maximum number of applications for this ${courseStr} already submitted.<br />You may review the application and your answers or return to the dashboard.</p>`);

        $("#closingDatePassedModal").modal({
            backdrop: "static",
            keyboard: false
        });
    }
    
    fillBatchInformation(batch, application.ApplicationId);

    // Loops to generate the HTML content for form answers modal
    pages.forEach(function(page, index) {
        pagesObj[page.PageId] = index;
        pagesOrdered.push([]);
        pagesTabsHtml += `<li id="liPage${index}" role="presentation"><a href="#page${index}" data-toggle="tab">${page.PageName}</a></li>`;
    });
    attributes.forEach(function (element) {
        pagesOrdered[ pagesObj[element.Page] ].push(element);
    });
    pagesOrdered.forEach(function (page, index) {
        pagesTabsContentHtml += `<div id="page${index}" class="tab-pane" role="tabpanel">`;
        page.forEach(function (element) {
            if(element.ColumnName in values && !checkHideCondition(element))
            {
                let fileinstance = "";
                let fileclass = "";
                if (element.AttributeTypeId == 10 || element.AttributeTypeId == 14 || element.AttributeTypeId == 15) {
                    fileclass = "file-container";
                    fileinstance = 'data-attribute-instance-id="' + element.AttributeInstanceId + '"';
                    pagesTabsContentHtml += `
                        <div class="row appFormRow ${fileclass}" id="${element.ColumnName}" ${fileinstance}>
                        <div class="col-xs-6">
                            <strong>${ element.DisplayName}</strong>
                        </div>
                        <div class="col-xs-6">
                            <div class="attribute-file-table" data-attribute-instance-id="${element.AttributeInstanceId}">
                                <table id="report-table" class=" report-table-1">
                                    <tbody class="file-list" id="file-table-${element.ColumnName}" data-file-table-fieldName="${element.ColumnName}">
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>`;
                } else if (element.AttributeTypeId == 17) {
                    pagesTabsContentHtml += `
                        <div class="row appFormRow ${fileclass}" id="${element.ColumnName}" ${fileinstance}>
                            <div class="col-xs-6">
                                <strong>${ element.DisplayName}</strong>
                            </div>
                            <div class="col-xs-6">${ values[element.ColumnName] === null ? "" : _Countries[values[element.ColumnName]]}</div>
                        </div>
                    `;
                } else if (element.AttributeTypeId == 6) {
                    pagesTabsContentHtml += `
                        <div class="row appFormRow ${fileclass}" id="${element.ColumnName}" ${fileinstance}>
                            <div class="col-xs-6">
                                <strong>${ element.DisplayName}</strong>
                            </div>
                            <div class="col-xs-6">${ values[element.ColumnName] === null ? "" : moment(values[element.ColumnName] ? values[element.ColumnName].replace("Z", "") : null).format("DD/MM/YYYY")}</div>
                        </div>
                    `;
                } else if (element.AttributeTypeId == 5 || element.AttributeTypeId == 16) {
                    pagesTabsContentHtml += `
                        <div class="row appFormRow ${fileclass}" id="${element.ColumnName}" ${fileinstance}>
                            <div class="col-xs-6">
                                <strong>${ element.DisplayName}</strong>
                            </div>
                            <div class="col-xs-6">${ values[element.ColumnName] === null ? "N/A" : values[element.ColumnName] === "true" || values[element.ColumnName] === true ? "Yes" : "No"}</div>
                        </div>`;
                }
                else if (element.AttributeTypeId == 18) { 
                    var priorityListValueIds = JSON.parse(values[element.ColumnName]);
                    var priorityListHtml = '';

                    if(priorityListValueIds && priorityListValueIds.length > 0)
                    {
                        for (var i = 0; i < priorityListValueIds.length; i++)
                        {
                            var priorityListItem = element.LookupValues.find(x => x.ValueId == priorityListValueIds[i]);
                            var priorityListItemValue = '-- Not Set --';
                            if(priorityListItem && priorityListItem.Value){
                                priorityListItemValue = priorityListItem.Value;
                            }
                            priorityListHtml += `<div>${i+1}. ${priorityListItemValue}</div>`;
                        }
                    }

                    pagesTabsContentHtml += `
                        <div class="row appFormRow ${fileclass}" id="${element.ColumnName}" ${fileinstance}>
                            <div class="col-xs-6">
                                <strong>${ element.DisplayName}</strong>
                            </div>
                            <div class="col-xs-6">${values[element.ColumnName] === null ? "" : priorityListHtml}</div>
                        </div>`;
                } else {
                    pagesTabsContentHtml += `
                        <div class="row appFormRow ${fileclass}" id="${element.ColumnName}" ${fileinstance}>
                            <div class="col-xs-6">
                                <strong>${ element.DisplayName}</strong>
                            </div>
                            <div class="col-xs-6">${ values[element.ColumnName] === null ? "" : values[element.ColumnName]}</div>
                        </div>
                    `;
                }
            }
        });
        pagesTabsContentHtml += `</div>`;
    });

    // Loop to generate the HTML content for LOG column
    for (let ii = logs.length - 1; ii >= 0; ii--) {
        const log = logs[ii];

        if (log.ActionTypeId == 12020) {
            try {
                data = JSON.parse("[" + log.UglyDescription + "]");
                if (data) {
                    if (data.length > 0) {
                        var text = "Following attributes have been invalidated:<br />";
                        data.forEach(function (elem) {
                            text += '<span class="invalidated-attribute-desc">' + Object.keys(elem)[0] + ' - <span class="pac_red">' + elem[Object.keys(elem)[0]] + '</span></span><br />'
                        })
                        log.FriendlyDescription = text;
                    }
                }
            } catch{
                console.log("Couldn't parse attributes from description");
            }

        }

        let prettyTime = moment(log.TimeStamp ? log.TimeStamp.replace("Z", "") : null).format("DD/MM/YYYY HH:mm");
        logsHtml += `
            <li>
                <strong><u>${log.Title}</u></strong>
                <br>
                ${log.FriendlyDescription}
                <br>
                <strong>From:</strong> ${log.DisplayName} [ <i>${prettyTime}</i> ]
            </li>
        `;
    }

    // Add default actions to ACTIONS column
    actionColHtml += `
        <a class="btn btn-info btn-block actionButton" href="#myAnswersModal" role="button" data-toggle="modal">View application</a>
    `;

    if (step.AllowApplicantAgentToReUploadDocuments) {
        actionColHtml += `
            <a class="btn btn-info btn-block actionButton" href="#uploadDocModal" role="button" data-toggle="modal">Upload a document</a>
        `;
    }

    assessorDocColHtml = "";
    if (assessorDocuments && assessorDocuments.length > 0) {
            assessorDocColHtml += `
            <h4 class="dashboardHead">Assessors documents</h4>
            <div class="row text-left">
                <div class="col-md-12 ">
            `
            assessorDocuments.forEach(function (doc) {
                assessorDocColHtml += `
                <div class="file-row" title="${ moment(doc.CreationDate ? doc.CreationDate.replace("Z", "") : null).format("DD/MM/YYYY HH:mm")}">
                    <a href="#" class="application-file-link" data-file-id="${doc.FileId}"><span class="fa fa-file"></span>${doc.Title}<span class="file-uploader-span">${moment(doc.CreationDate.replace("Z", "")).format("DD/MM/YY")} - ${doc.DisplayName}</span></a>
                </div>`;
            });

            assessorDocColHtml += `
            </div>
            </div>
            `
    }

    let applicantsDocColHtmlTemp = "";
    var applicantsDocColHtml = "";
    if (applicantsDocuments && applicantsDocuments.length > 0) {
        applicantsDocuments.forEach(function (doc) {
            applicantsDocColHtmlTemp += `
                <div class="file-row" title="${ moment(doc.CreationDate ? doc.CreationDate.replace("Z", "") : null).format("DD/MM/YYYY HH:mm")}">
                    <a href="#" class="application-file-link" data-file-id="${doc.FileId}"><span class="fa fa-file"></span>${doc.Title}<span class="file-uploader-span">${moment(doc.CreationDate.replace("Z", "")).format("DD/MM/YY")}</span></a>
                </div>
            `;
        });
    }
    applicantsDocColHtml = `
    <h4 class="dashboardHead applicant-document-section" ${applicantsDocColHtmlTemp==''?'style="display:none;"':''}>Applicant documents</h4>
    <div class="row text-left applicant-document-section" ${applicantsDocColHtmlTemp==''?'style="display:none;"':''}>
        <div id="applicantDocumentsContainer" class="col-sm-12">${applicantsDocColHtmlTemp}</div>
    </div>`;

    let applicationDocColHtmlTemp = "";
    var applicationDocColHtml = "";
    if (applicationDocuments && applicationDocuments.length > 0) {
        applicationDocuments.forEach(function (doc) {
            applicationDocColHtmlTemp += `
                <div class="file-row" title="${ moment(doc.CreationDate ? doc.CreationDate.replace("Z", "") : null).format("DD/MM/YYYY HH:mm")}">
                    <a href="#" class="application-file-link" data-file-id="${doc.FileId}"><span class="fa fa-file"></span>${doc.Name}<span class="file-uploader-span">${moment(doc.CreationDate.replace("Z", "")).format("DD/MM/YY")}</span></a>
                </div>
            `;
        });
        applicationDocColHtml = `
        <h4 class="dashboardHead">Application documents</h4>
        <div class="row text-left">
            <div id="applicationDocumentsContainer" class="col-sm-12">${applicationDocColHtmlTemp}</div>
        </div>
    `;
    }

    $(".application-right-hand-col").prepend(assessorDocColHtml);
    $(".application-right-hand-col").prepend(applicantsDocColHtml);
    $(".application-right-hand-col").prepend(applicationDocColHtml);

    if (step.StatusName === "Unsubmitted" || step.StatusName === "Withdrawn") { } else {
        actionColHtml += `
            <a class="btn btn-danger btn-block actionButton withdrawBtn" href="#withdrawModal" role="button" data-toggle="modal">Withdraw my application</a>
        `;
    }

    if(application.OfferAcceptanceResult == 1)
    {
        actionColHtml += `<a class="btn btn-warning btn-block actionButton" id="openPayDepositModalBtn" href="#payDepositModal" role="button" data-toggle="modal">Pay deposit</a>`;           
    }
    else if(application.OfferAcceptanceResult == 2)
    {
        actionColHtml += `<a id="acceptOfferBtn" class="btn btn-success btn-block actionButton" href="#" role="button" >Accept Offer</a>`;
    }

    let offerCountdownFlag = false;
    if(application.OfferAcceptanceResult == 1 || application.OfferAcceptanceResult == 2)
    {
        offerCountdownFlag = true;
    }

    if (offerCountdownFlag) {
        actionColHtml += `
            <a id="declineOfferBtn" class="btn btn-danger btn-block actionButton" href="#" role="button" >Decline Offer</a>
        `;
    }

    // Set the deposit amount
    $(".depositAmount").text(applicantInfo.EUCitizen ? course.DepositAmount : course.NonEUDepositAmount > 0 ? course.NonEUDepositAmount : course.DepositAmount);

    // Set the main status text
    statusColHtml += `
        <div class="h1"><strong>${ step.StatusName}${step.StatusName == "Interview" ? "" : step.SectionName ? " : " + step.SectionName : ""}</strong></div>
    `;
    if (step.StatusId === 60) { // Expired
        statusColHtml += `
            <div class="h3"><span class="label label-danger">${moment(application.OfferExpirationDate ? application.OfferExpirationDate.replace("Z", "") : null).format("DD/MM/YYYY - HH:mm")}</span></div>
        `;
    }

    $("#statusCol").append(statusColHtml);

    if (!applicantReadOnly) {
        // Add edit button, if permitted
        if ((step.StatusName === "Unsubmitted" && !closingDatePassed && !application.IsCourseApplicationLimitReached) || (step.StatusName != "Unsubmitted" && step.AllowApplicantAgentToEditAttributes && application.CurrentActorId !== 1 && isApplicationFormInvalid)) {
            // Check if current app is 1st in the batch and don't 're-order' form if it is
            if (batch[0].ApplicationId === application.ApplicationId) {
                actionColHtml += `
                    <a class="btn btn-success btn-block actionButton" href="/apply?b=${batch[0].BatchId}">Modify application</a>
                `;
            } else {
                actionColHtml += `
                    <a class="btn btn-success btn-block actionButton" href="/apply?b=${batch[0].BatchId}&id=${application.ApplicationId}">Modify application</a>
                `;
            }
        }

        // Check current Actor
        if (application.CurrentActorId === 2) {
            let message = GetLatestActionMessage(logs);
            if (message != undefined) {
                messageColHtml += `
                <h3>
                    <div class="alert alert-danger">
                        ** Attention Required **
                        <br>
                        ${message}
                    </div>
                </h3>
            `;
            }
            var isInterviewButtonVisible = false;
            if (step.StatusName == "Interview") {
                if(course.InterviewTypeId == 1)
                {
                    actionColHtml += `
                        <a class="btn btn-success btn-block actionButton" href="/interview?a=${application.ApplicationId}">Pick interview date</a>
                    `;
                    isInterviewButtonVisible = true;
                }
                if(course.InterviewTypeId == 2)
                {
                    if(application.InvitationId != null)
                    {
                        actionColHtml += `
                            <a class="btn btn-success btn-block actionButton" href="https://interview.zilter.io/view/${application.InvitationId}">Start interview</a>
                        `;
                        isInterviewButtonVisible = true;
                    }
                }
            }

            var successButtonClass = isInterviewButtonVisible?"btn-primary":"btn-success";

            if (ReturnToAssessorMessagePrompt) {
                actionColHtml += `
                    <a data-appId="${application.ApplicationId}" class="btn ${successButtonClass} btn-block actionButton" href="#applicantMessageModal" role="button" data-toggle="modal">Submit changes</a>
                `;
            } else {
                actionColHtml += `
                    <a id="return-to-assessors-btn" data-appId="${application.ApplicationId}" class="btn ${successButtonClass} btn-block actionButton">Submit changes</a>
                `;
            }

        }
    }
    else
    {
        //If applicant is viewing an unsubmitted agent app, allow him to go to the apply page.
        if (step.StatusName === "Unsubmitted" && !closingDatePassed) 
        {
            // Check if current app is 1st in the batch and don't 're-order' form if it is
            if (batch[0].ApplicationId === application.ApplicationId) 
            {
                actionColHtml += `<a class="btn btn-success btn-block actionButton" href="/apply?b=${batch[0].BatchId}">Modify application</a>`;
            } 
            else 
            {
                actionColHtml += ` <a class="btn btn-success btn-block actionButton" href="/apply?b=${batch[0].BatchId}&id=${application.ApplicationId}">Modify application</a>`;
            }
        }
    }
   
    if (appsPerBatchLimit.AppPerBatchLimit > batch.length && appsPerBatchLimit.AppPerBatchLimit != 1) {      
        actionColHtml += `<a class="btn btn-primary btn-block actionButton" id="openCourseBatchModalBtn" href="#" role="button" style="display:none;">Add ${courseStr} </a>`;
    }
    if(application.IsCourseApplicationLimitReached || step.StatusId == 17)
        actionColHtml += `<a class="btn btn-danger btn-block actionButton" id="deleteApplication" href="" role="button">Delete application</a>`;

    // Add return button to action buttons
    actionColHtml += `<a class="btn btn-default btn-block actionButton" href="/dashboard" role="button">Back to list</a>`;

    $("#statusCol").append(messageColHtml);


    if (offerCountdownFlag) setOfferExpirationCountdown(application, step);

    $("#applicationMeta").html(`
        <div><strong>Institution:</strong> ${university.Name}</div>
        <div><strong>Venue:</strong> ${application.VenueId==null?"N/A":application.Venue}</div>
        <div><strong>Course Type:</strong> ${courseType.Name}</div>
        <div><strong>Starting Date:</strong> ${application.CourseStartDate}</div>
        <div><strong>Academic Year:</strong> ${application.AcademicYear}</div>
        <div><strong>Application Number:</strong> ${application.ApplicationNumber == null ? "N/A" : application.ApplicationNumber}</div>
        <div><strong>Batch Number:</strong> ${batch[0].BatchId}</div>
        <div><strong>Created:</strong> ${ moment(application.CreationDate ? application.CreationDate.replace("Z", "") : null).format("DD/MM/YYYY HH:mm")}</div>
    `);

    // If this is an agent application, add the applicant's name to the details
    if (application.AgentUserId) {
        applicationDetailsService.getApplicantByUserId(application.UserId).then(function (user) {
            $("#applicationMeta").prepend(`<div><strong>Applicant:</strong> ${user.DisplayName}</div>`);
        });
    }

    // Attach all the generated HTML into DOM

    $("#actionsCol").html(actionColHtml);
    if($("#openCourseBatchModalBtn").length)  {
            if (application.BatchStatusId == 40) {                    //batch already submitted so may need to prevent Add Course
                HideAddCourseForOldBatches(application.OrganisationId, application.AcademicYear);
            }
            else $("#openCourseBatchModalBtn").show(); 
    }

    $("#logCol").html("<ul id=\"actionLogList\">" + logsHtml + "</ul>");
    $("#myAnswersModalContent").html(`
        <div class="row">
            <div class="col-xs-12 col-sm-3 col-md-2">
                <ul id="answersTabs" class="nav nav-pills nav-stacked">
                    ${pagesTabsHtml}
                </ul>
            </div>
            <div class="col-xs-12 col-sm-9 col-md-10">
                <div class="tab-content">
                    ${pagesTabsContentHtml}
                </div>
            </div>
        </div>
    `);
    $("#answersTabs a:first").tab("show");

    getFiles(application.ApplicationId);

    $("#ms1").milestones({
        checks: statusIdMap[step.StatusId], // apply a checkmark to the 1st milestone
        stage: statusIdMap[step.StatusId],  // progress to the 2nd milestone
        checkclass: "checks", // apply this class to the checkmarks
        stageclass: "doneclass", // apply this class to the completed tickmarks
        labels: stages  // array of labels
    });

    $(".tab-pane").each(function(i,e){
        if($(e).html() == "")
        {
            $("#liPage"+i).remove();
            $(e).remove();
        }
    })
}

/****************************************************************
 *                          LOCAL FUNCTIONS
 ****************************************************************/

function prepareAttributeValues(attributes, values) {
    attributes.forEach(function(item) {
        if (item.ColumnName in values) {
            if (item.AttributeTypeId == 17) {
                _AttributeData[item.ColumnName] = _Countries[values[item.ColumnName]];
            } else {
                _AttributeData[item.ColumnName] = values[item.ColumnName];
            }
        }
    });
}

function checkHideCondition(attribute) {
    if (!attribute.HideCondition || attribute.HideCondition == "") return false;
    var condJSON = JSON.parse(attribute.HideCondition);
    if (condJSON) {
        var condBuilder = {};

        condJSON.conditions.forEach(function (cond) {

            if (condBuilder.hasOwnProperty(cond.Key))
                condBuilder[cond.Key] += ", " + String(cond.Value);
            else {
                condBuilder[cond.Key] = {};
                condBuilder[cond.Key] = String(cond.Value);
            }
        })

        var keys = Object.keys(condBuilder);

        responses = [];

        keys.forEach(function (key) {
            responses.push(condBuilder[key].indexOf(_AttributeData[key]) > -1);
        })

        if (responses.length == 0)
            return false;
        else {
            if (condJSON.showConditions) {
                if (responses.indexOf(false) < 0) {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                if (responses.indexOf(true) < 0) {
                    return false;
                }
                else {
                    return true;
                }
            }
        }
    }
    return false;
}


function depositMatrix(status, paid, step, assessor) {
    return _depositMatrix[status][paid][step][assessor];
}

function generateCountriesDictionary(countries) {
    arr = {};
    countries.forEach(function (country) {
        arr[country.CountryId.toString()] = country.Name;
    });
    return arr;
}

function setOfferExpirationCountdown(application, step) {
    if (application.HasOfferExpired != null && application.HasOfferExpired == false) {
        if (application.OfferExpirationDate) {
            $("#statusCol").append('<div class="countdown-timer"></div>');
            // Set the date we're counting down to
            var OfferExpirationTime = new Date(application.OfferExpirationDate).getTime();

            // Update the count down every 1 second
            var x = setInterval(function () {

                // Get today's date and time
                var now = new Date().getTime();

                // Find the distance between now and the count down date
                var distance = OfferExpirationTime - now;

                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                // Display the result in the element with id="demo"
                $(".countdown-timer").html("Offer Expires in: " + days + "d " + hours + "h " + minutes + "m " + seconds + "s ");

                // If the count down is finished, write some text 
                if (distance < 0) {
                    clearInterval(x);
                    $(".countdown-timer").html("Offer has expired");
                }
            }, 1000);
        }
    }
}

function getFiles(applicationId) {
    $("#myAnswersModalContent .file-container").each(function (i, e) {
        var fieldName = $(this).attr("id");
        var attributeInstanceId = $(this).data('attribute-instance-id');

        fileService.getByApplicationIdAttributeInstanceId(applicationId, attributeInstanceId)
            .then(function (filesInContainer) {
                fileContainerService.getByApplicationIdAttributeInstanceId(applicationId, attributeInstanceId)
                    .then(function (container) {
                        renderFileList(container.ContainerId, fieldName, filesInContainer);
                    })
            })
    })
}

function renderFileList(containerId, fieldName, filesInContainer) {
    $('#file-table-' + fieldName).empty();
    for (var i = 0; i < filesInContainer.length; i++) {
        var file = filesInContainer[i];
        var fileId = file.fileId == undefined ? file.FileId : file.fileId;
        var fileName = file.name == undefined ? file.Name : file.name;
        var fileRowId = 'file-tr-' + fieldName + '-' + i;
        $('#file-table-' + fieldName).append('<tr id="' + fileRowId + '" data-file-id="' + fileId + '" data-container-id="' + containerId + '"></tr>');
        //$('#' + fileRowId).append('<td class="displaytd file-delete-td pac_red" style="text-align: center;"><div class="btn btn-danger delete-file">Delete</div></td>');
        $('#' + fileRowId).append('<td class="displaytd pac_blue file-click-open" style="text-align: center;"><span class="file-item" data-file-id="' + fileId + '">' + fileName + '</span></td>');
    }
}

function GetLatestActionMessage(log) {

    var latestId = log.map(function(e) { return e.ActionTypeId; }).lastIndexOf(11900);

    if(!latestId) latestId = 0;

    var tempLog = log.slice(latestId, log.length);

    for (i = tempLog.length - 1; i > -1; i--) {
        if (validActionTypes.includes(tempLog[i].ActionTypeId)) {
            return tempLog[i].FriendlyDescription
        }
    }
    return undefined;
}

function prepareAdditionalCourseModal(OrganisationId, WorkflowId, coursesInBatch) {
    let courseStr = pageSettingsHelper.getCourseStringByOrganisationId(OrganisationId);
    pageSettingsHelper.setCourseText(OrganisationId);
    $("#courseBatchModalCourseTable > tbody").html("");
    return new Promise(function (resolve, reject) {
        $.when(
            applyService.getCoursesByOrganisationIdWorkflowId(OrganisationId, WorkflowId),
            applyService.getApplicationsPerBatchLimitByCourseId(coursesInBatch[0].CourseId)
        ).done(function (coursesByOrganisationId, applicationsPerBatchLimit) {
            var limit = applicationsPerBatchLimit.AppPerBatchLimit ? applicationsPerBatchLimit.AppPerBatchLimit : 3;
            let now = new Date();
            now.setHours(0, 0, 0, 0);
            let courseAutoComplete = [];
            let courseNameAndIds = {};
            let usedCourses = [];

            if (limit == coursesInBatch.length) {
                $("#courseBatchModalAddAdditionalCourses").hide();
            }

            coursesInBatch.forEach(function (elem) {
                addRowToCourseBatch(elem.CourseId, elem.Name, elem.CourseCode, false);
                usedCourses.push(elem.CourseId);
            })

            let existingCoursesInBatch = coursesInBatch.map(x => x.CourseId);

            $(".additional-course-counter-max").text(limit);
            $(".additional-course-counter-current").text($("#courseBatchModalCourseTable > tbody tr").length);

            if(limit == 1)
            {
                $(".multiple-courses-true").hide();
                $(".multiple-courses-false").show();
            }
            else
            {
                $(".multiple-courses-true").show();
                $(".multiple-courses-false").hide();
            }

            for (let i = 0, len = coursesByOrganisationId.length; i < len; i++) {
                if(existingCoursesInBatch.indexOf(coursesByOrganisationId[i].CourseId) > -1) continue;
                if($("#applicationDataContainer").data("IsApplicantEUCitizen"))
                {
                    if(!applyService.isCourseOpen(coursesByOrganisationId[i].EUOpeningDate, coursesByOrganisationId[i].EUClosingDate))
                        continue;
                }
                else
                {
                    if(!applyService.isCourseOpen(coursesByOrganisationId[i].NonEUOpeningDate, coursesByOrganisationId[i].NonEUClosingDate))
                        continue;
                }

                let name = (coursesByOrganisationId[i].CourseCode + " - " + coursesByOrganisationId[i].Name).trim();
                courseAutoComplete.push(name);
                courseNameAndIds[name] = coursesByOrganisationId[i].Id;
            }

            resolve();

            $("#courseBatchModalCourseSearch").typeahead({
                source: courseAutoComplete,
                autoSelect: false,
                items: 'all',
                afterSelect: function (item) {
                    if ($('#courseBatchModalCourseTable > tbody > tr').length >= limit) {
                        bootoast.toast({
                            message: `Maximum amount of additional ${courseStr}s already reached!`,
                            timeout: false,
                            type: "danger",
                            icon: "alert"
                        });
                    } else {
                        if (typeof courseNameAndIds[item] !== "undefined") {
                            if ($("#courseBatchModalCourseTable tr[data-courseid='" + courseNameAndIds[item] + "']").length == 0)
                                applyService.getCourseByCourseId(courseNameAndIds[item]).then(function (course) {
                                    addRowToCourseBatch(course.CourseId, course.Name, course.CourseCode);
                                    delete courseNameAndIds[item];
                                    $("#courseBatchModalCourseSearch").val("");
                                });
                        }
                    }
                }
            });
        }).fail(function(err) {
            reject(err);
        });
    })
}

function addRowToCourseBatch(id, name, code, del = true) {
    $("#courseBatchModalCourseTable > tbody").append(`
        <tr data-courseid="${id}">
            <td>${name}</td>
            <td class="text-center">${code}</td>
            <td class="text-center">${del === true ? "<a data-courseid=\"" + id + "\" id=\"courseBatchModalCourseCloseLink-" + id + "\" class=\"text-danger courseBatchModalCourseCloseLink\" href=\"#\"><i class=\"fa fa-close\" aria-hidden=\"true\"></i></a>" : ""}</td>
        </tr>
    `);

    $(".additional-course-counter-current").text($("#courseBatchModalCourseTable > tbody tr").length);
}

function deleteRowFromCourseBatch(id) {
    $('#courseBatchModalCourseTable tr[data-courseid="' + id + '"]').remove();

    $(".additional-course-counter-current").text($("#courseBatchModalCourseTable > tbody tr").length);
}

function fillBatchInformation(batch, ApplicationId) {
    if (batch.length > 1) {
        $("#batchAppTableContainer table tbody").html("");

        let apps = [];
        for (const app of batch) {
            apps.push(app.ApplicationId);
        }
        apps.sort((a, b) => a - b);
        apps.forEach(function (appId) {
            for (let ii = 0; ii < batch.length; ii++) {
                if (batch[ii].ApplicationId === appId) {
                    if (batch[ii].ApplicationId == ApplicationId) {
                        $("#batchAppTableContainer table tbody").append(`<tr><td>${ii + 1}</td><td>${batch[ii].ApplicationName}</td><td>${batch[ii].SectionName == null ? batch[ii].StatusName : batch[ii].StatusName + ' (' + batch[ii].SectionName + ')'}</td></tr>`);
                    } else {
                        $("#batchAppTableContainer table tbody").append(`<tr><td>${ii + 1}</td><td><a href="/application/${batch[ii].ApplicationId}">${batch[ii].ApplicationName}</a></td><td>${batch[ii].SectionName == null ? batch[ii].StatusName : batch[ii].StatusName + ' (' + batch[ii].SectionName + ')'}</td></tr>`);
                    }
                    break;
                }
            }
        })
        $("#batchAppTableContainer").show();
    }
}

function fillcardHolderCountry(data, select)
{
    $(select).empty();
    data.forEach( function(country){
        $(select).append(`<option value="${country.CallingCode}">${country.Name} (+${country.CallingCode})</option>`);
    });
}


function HideAddCourseForOldBatches(OrganisationId, ApplicationAcademicYear) {
    instituteService.getSettingByOrganisationSettingsKey({ OrganisationId: OrganisationId, SettingsKey: "CurrentAcademicYear" })
    .then(function (setting) {
        let CurrentAcademicYear = setting.SettingsValue;
        if (ApplicationAcademicYear != CurrentAcademicYear) {
            $("#openCourseBatchModalBtn").hide();
        }
        else $("#openCourseBatchModalBtn").show();
    });
    
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    populateApplication: populateApplication,
    prepareAdditionalCourseModal: prepareAdditionalCourseModal,
    deleteRowFromCourseBatch: deleteRowFromCourseBatch,
    fillBatchInformation: fillBatchInformation
}