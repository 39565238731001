import "../events/recommendationEvents";
const recommendationBuilder = require("../builders/recommendationBuilder");
const recommendationService = require("../services/recommendationService");
const registerService = require("../services/registerService");
const loadingHelper = require('../helpers/loading');
const urlHelper = require('../helpers/urlHelper');


loadingHelper.block();

let UUID = urlHelper.getQueryVariable("u");

if (UUID == null || UUID == "") {
    recommendationBuilder.buildNoEntryPage("The link for the reference you have followed is not valid.");
    loadingHelper.release();
}
else {
    $.when(recommendationService.GetApplication({ RecommendationUuid: UUID }), registerService.getCountries())
        .done(function (app, countries) {
            if (!$.isEmptyObject(app)) {
                recommendationBuilder.buildCountries(countries);
                recommendationBuilder.buildRecommendationPage(app);
            }
            else {
                recommendationBuilder.buildNoEntryPage("The link for the reference you have followed is not valid.");
            }
            loadingHelper.release();
        })
}




