const applicationDetailsService = require('../services/applicationDetailsService');
const applicationDetailsBuilder = require('../builders/applicationDetailsBuilder');
const applyService = require('../services/applyService');
const paymentService = require('../services/paymentService');
const fileService = require('../services/fileService');
const urlHelper = require("../helpers/urlHelper");
const pageSettingsHelper = require("../helpers/pageSettingsHelper");
const loading = require('../helpers/loading');
const logger = require('../helpers/clientLogger');
const moment = require('moment');

$("body").on('click', "#return-to-assessors-btn", function (e) {
    e.preventDefault();

    if (confirm("Are you sure you want to return this application to assessors?")) {
        sendApplication({ message: "" });
    }
})

function sendApplication(data) {
    // Get ID
    let appId = urlHelper.getUrlParam(2);

    applicationDetailsService.sendApplicationToAssessor(appId, data).then(function () {
        // alert("Application returned");
        location.reload();
    });
}


$("#applicantMessageModalConfirmBtn").on("click", function (e) {
    e.preventDefault();

    sendApplication({ message: $("#applicantMessageModal textarea").val() })

    $("#applicantMessageModal").modal("hide");
});


$("#applicantMessageModalCancelBtn").on("click", function (e) {
    e.preventDefault();
    $("#applicantMessageModal").modal("hide");
});


$("body").on('click', "#openCourseBatchModalBtn", function (e) {
    loading.block();
    applyService.GetCourseInfoByBatchId($("#applicationDataContainer").data('batchId'))
        .then(function (courses) {
            applicationDetailsBuilder.prepareAdditionalCourseModal($("#applicationDataContainer").data('organisationId'), $("#applicationDataContainer").data('workflowId'), courses)
                .then(function () {
                    $("#courseBatchModal").modal({
                        backdrop: "static",
                        keyboard: false
                    });
                }).catch(function (err) {
                    logger.error(err);
                    bootoast.toast({
                        message: "An unknown error has occurred",
                        timeout: false,
                        type: "danger",
                        icon: "alert"
                    });
                })
        }).always(function (e) {
            loading.release();
        });

})

$("#courseBatchModalAddAdditionalCoursesCancel").on('click', function (e) {
    $("#courseBatchModal").modal("hide");
})

$("#uploadDocModalCancel").on('click', function (e) {
    $("#uploadDocModal").modal("hide");
})

$("#uploadDocModalContinue").on('click', function (e) {
    loading.upload();
    let fileTitleName = null;
    if ($("#uploadDocInputFilename").val().trim().length > 0)
        fileTitleName = $("#uploadDocInputFilename").val().trim();
    let formData = new FormData();
    let files = $("#uploadDocInputFile").get(0).files;
    console.log(files);
    for (let ii = 0; ii < files.length; ii++) {
        if (fileTitleName == null) fileTitleName = files[ii].name;
        formData.append("files", files[ii], files[ii].name);
    }
    formData.append("ApplicationId", $("#applicationDataContainer").data("applicationId"));
    formData.append("Title", fileTitleName);
    fileService.uploadApplicantFile(formData).then(function (fileResponse) {
        $(".applicant-document-section").show();
        console.log("resp", fileResponse);
        var date = new Date();
        $("#applicantDocumentsContainer").append(`
            <div class="file-row">
                <a href="#" class="application-file-link" data-file-id="${fileResponse.fileId}"><span class="fa fa-file"></span>${fileResponse.title}<span class="file-uploader-span">${moment(date).format("DD/MM/YY")}</span></a>
            </div>
        `);
        $("#uploadDocModal").modal("hide");
    }).fail(function (err) {
        logger.error(err);
        bootoast.toast({
            message: "An unknown error has occurred",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    }).always(function (e) {
        loading.release();
    });
})

$("body").on('click', "#declineOfferBtn", function (e) {
    if (confirm("Are you sure you want to decline this offer? Once an offer is declined you will not be able to undo this action!")) {
        loading.block();
        applyService.decline($("#applicationDataContainer").data('applicationId'))
            .then(function (result) {
                location.reload();
            }).fail(function (err) {
                logger.error(err);
                loading.release();
                bootoast.toast({
                    message: "An unknown error has occurred",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
            })
    }
})

$("body").on('click', "#acceptOfferBtn", function (e) {
    loading.block();

    applyService.acceptOffer($("#applicationDataContainer").data('applicationId'))
        .then(function (result) {
            location.reload();
        }).fail(function (err) {
            logger.error(err);
            bootoast.toast({
                message: "An unknown error has occurred",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
        }).always(function (e) {
            loading.release();
        });
})


$("#courseBatchModalAddAdditionalCourses").on('click', function (e) {
    e.preventDefault();

    var courseArray = [];

    $('#courseBatchModalCourseTable > tbody').children().each(function () {
        if ($(this).find(".fa-close").length > 0) {
            if ($(this).data('courseid') != undefined) {
                courseArray.push($(this).data('courseid'));
            }
        }
    });

    if (courseArray.length > 0) {
        removeBtnFlag = false;

        if ($(".additional-course-counter-current").text() == $(".additional-course-counter-max").text()) {
            removeBtnFlag = true;
        }

        var data = {
            CourseIds: JSON.stringify(courseArray),
            ApplicationId: $("#applicationDataContainer").data('applicationId')
        }

        applyService.addCoursesToBatch($("#applicationDataContainer").data('batchId'), data)
            .then(function () {
                let batchId = $("#applicationDataContainer").data('batchId');
                if (typeof batchId !== "undefined") {
                    window.location.href = "/apply?b=" + batchId;
                } else {
                    throw new Error("No batch id found.");
                }
            })
    }
    else {
        let courseStr = pageSettingsHelper.getSavedCourseString();
        bootoast.toast({
            message: `No additional ${courseStr}s were selected to be added. Please select a ${courseStr} from the search dropdown menu.`,
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    }

})

$(document.body).on("click", ".courseBatchModalCourseCloseLink", function (e) {
    e.preventDefault();
    let id = $(this).data("courseid");
    applicationDetailsBuilder.deleteRowFromCourseBatch(id);
});

$("body").on('click', '#deleteApplication', function(e){
    pageSettingsHelper.setCourseText();
    e.preventDefault();
    $("#batchAppDeleteModal").modal("show");
})

$(document.body).on("click", "#batchAppDeleteModalDeleteBtn", function(e) {
    e.preventDefault();
    let batchId = $("#applicationDataContainer").data('batchId');
    let appId = $("#applicationDataContainer").data('applicationId');
    applyService.deleteAppFromBatch(batchId, appId).then(function() {
        $("#batchAppDeleteModal").modal("hide");
        
        window.location.href = applicationDeleteRedirectPath();
    });
});

function applicationDeleteRedirectPath()
{
    var options = [];
    $("#batchAppTableContainer a").each(function(i,e){
        options.push($(e).attr("href"));
    });
    if(options.length == 0) return "/dashboard";
    else return options[0];
}

$('#myAnswersModalContent').on('click', '.file-item', function () {
    var fileId = $(this).data('file-id');
    fileService.getDownloadUrl(fileId)
        .then(function (file) {
            var win = window.open(file.Url, '_blank');
            // win.focus();
        });
})

$('.application-right-hand-col').on('click', '.application-file-link', function () {
    var fileId = $(this).data('file-id');
    fileService.getDownloadUrl(fileId)
        .then(function (file) {
            var win = window.open(file.Url, '_blank');
            // win.focus();
        });
})

$("#withdrawBtnConfirm").on("click", function (e) {
    e.preventDefault();

    // Get ID
    let appId = urlHelper.getUrlParam(2);

    applyService.withdraw(appId).then(function (result) {
        console.log(result);
        // window.location.href = "/application/" + appId;
        location.reload();
    }).fail(function (err) {
        logger.error(err);
        bootoast.toast({
            message: "An error has occurred.",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    });
});

$("#withdrawModalCancelBtn").on("click", function (e) {
    e.preventDefault();
    $("#withdrawModal").modal("hide");
});

$("body").on('click', '#openPayDepositModalBtn', function(e){
    if(parseInt(localStorage.UserTypeId) == 2 && !$(".depositPaymentSection").hasClass("hide"))
    {
        $(".depositPaymentSection").addClass("hide");
        $(".agent-payment-notice").show();
        $("#payDepositModalNotifyApplicant").show();
    }
});

$("#payDepositModalConfirmBtn").on("click", function (e) {

    if(parseInt(localStorage.UserTypeId) == 2 && $(".depositPaymentSection").hasClass("hide"))
    {
        $(".depositPaymentSection").removeClass("hide");
        $(".agent-payment-notice").hide();
        $("#payDepositModalNotifyApplicant").hide();
    }
    else
    {
        e.preventDefault();
        if (document.getElementById("depositModalForm").reportValidity()) {
            if($("#cardholderMobilePhoneNumber").val().indexOf("0") == 0)
            {
                bootoast.toast({
                    message: "Leading 0 must be removed from the mobile phone number.",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
                $("#cardholderMobilePhoneNumber").focus();
            }
            else if($("#cardholderMobilePhoneNumber").val().length < 6)
            {
                bootoast.toast({
                    message: "Mobile phone number must contain at at least 6 characters.",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
                $("#cardholderMobilePhoneNumber").focus();
            }
            else {
                var cardholderData = {};
                cardholderData.ApplicationId = urlHelper.getUrlParam(2);
                cardholderData.CustomerEmail = $("#cardholderEmailAddress").val();
                cardholderData.AddressLine1 = $("#cardholderAddressLine1").val();
                cardholderData.AddressLine2 = $("#cardholderAddressLine2").val();
                cardholderData.City = $("#cardholderCity").val();
                cardholderData.PostalCode = $("#cardholderPostalCode").val();
                cardholderData.CountryCode = $("#cardholderCountryCode").find(':selected').data('isocode');
                cardholderData.MobilePhone = $("#cardholderMobilePhoneCountry").val()+"|"+$("#cardholderMobilePhoneNumber").val().trim();
            
                RealexHpp.setHppUrl(_realex_);
                paymentService.startSecurityDepositOnlineTransaction(cardholderData).then(function (response) {
                    console.log(response);
                    $("#payDepositModal").modal("hide");
                    RealexHpp.lightbox.init("payButtonId", window.location.origin + "/securityDepositPaymentSummary", response);
                    //RealexHpp.lightbox.init("payButtonId", "https://localhost:44388/SecurityDepositPayments", response);
                    $('body').addClass('loaded');
                    $('#payButtonId').trigger('click');
                }).fail(function (err) {
                    logger.error(err);
                    bootoast.toast({
                        message: "An error has occurred.",
                        timeout: false,
                        type: "danger",
                        icon: "alert"
                    });
                });
            }
        }
    }
});

$("input.number-input").on('keydown',function(e){
    var len = parseInt($(this).data("maxlength"));
    //check if it's a key that number field allows but it's not valid in this context
    if(["e",".",",","-","+"].indexOf(e.key)>-1)
    {
        e.preventDefault();
        return 0;
    }
    //if max length is defined check if current value matches max length and cancel input
    if(len != NaN)
    {
        if(e.key.match(/^[0-9]*$/) != null && this.value.length == len) {
            e.preventDefault();
            return 0;
        }
    }
});

$("#payDepositModalNotifyApplicant").on('click',function(e){
    loading.block();
    applyService.AgentRequestDepositPayment($("#applicationDataContainer").data('applicationId'))
    .then(function(){
        bootoast.toast({
            message: "Applicant has been notified via E-mail. You will be redirected to your dashboard shortly.",
            timeout: false,
            type: "success",
            icon: "alert"
        });
        setTimeout(function(){
            window.location.href = "/dashboard";
        }, 3000)

    }).fail(function(err) {
        logger.error(err);
    }).always(function(){
        loading.release();
    });
})

