const interviewBuilder = require('../builders/interviewBuilder');
const courseService = require('../services/coursesService');
const interviewService = require('../services/interviewService');
const dateHelper = require('../helpers/dateHelper');
const loadingHelper = require('../helpers/loading');
const logger = require('../helpers/clientLogger');

$('#calendar').on('click', '.calendar-day:not(.disabled)', function () {
    loadingHelper.block();
    ViewData.date = $(this).data('date');
    $("#calendar-table td").removeClass('picked');
    LoadDayPane();
});

$('#calendar-table').on('click', '.available-slots-day', function () {
    loadingHelper.block();
    ViewData.date = $(this).data('date');
    $("#calendar-table td").removeClass('picked');
    LoadDayPane();
});

//SLOT
$('#day-appointments').on('click', '.appointment:not(.booked)', function () {
    loadingHelper.block();
    let slotId = $(this).data('slot-id');
    interviewService.getSlot(slotId).then(function (slot) {
        interviewBuilder.setInterviewSlotTable(slot);
        interviewBuilder.openSlotModal();
        loadingHelper.release();
    }).fail(function (exception) {
        logger.error(exception);
    })
})

$('#bookSlotModal').on('click', '#slot-book-button', function (e) {
    e.stopImmediatePropagation();
    let slotId = $(this).data('slot-id');
    let slotData = {
        ApplicationId: ViewData.ApplicationId
    };
    interviewService.bookSlot(slotId, slotData).then(function (slot) {
        ViewData.IsBooked = true;
        //interviewBuilder.setInterviewSlotTable(slot);
        interviewBuilder.postBookingModal();
        ReloadCalendarTable();
        ReloadCalendar();
        LoadDayPane();
    }).fail(function (exception) {
        logger.error(exception);
        if (exception.responseJSON.state == 1) {
            interviewService.getSlot(slotId).then(function (slot) {
                interviewBuilder.setInterviewSlotTable(slot);
            }).fail(function (exception) {
                logger.error(exception);
            })
        }
        console.log(exception);
    });
});

$("#bookSlotModal").on('click',".applicant-dashboard-link, .modal-content", function(e){
    e.stopImmediatePropagation();
})

$('#bookSlotModal').on('click', '#cancel-booking-button, #slot-undo-booking-button', function (e) {
    e.stopImmediatePropagation();
    let slotId = $(this).data('slot-id');

    interviewService.unbookSlot(slotId).then(function (slot) {
        ViewData.IsBooked = false;
        interviewBuilder.setInterviewSlotTable(slot);
        ReloadCalendarTable();
        ReloadCalendar();
        LoadDayPane();
    }).fail(function (exception) {
        logger.error(exception);
    });
});

$("#bookSlotModal").on('click',function(e){
    closeBookedModal(e);
})

$("#bookSlotModal").on('click', "#close-modal-button, .close", function (e) {
    closeBookedModal(e);
})

function closeBookedModal(e) {
    if(ViewData.IsBooked) {
        if (confirm("You will be redirected to your application if you close this window while having a slot booked. Please confirm the navigation.")) {
            window.location.href = "/application/"+ViewData.ApplicationId;
        } else {
            e.preventDefault();
            e.stopImmediatePropagation();
            return false;
        }
    }
}

$("#calendar-month-back").on("click", function() {
    // ViewData.date = null;
    ViewData.month = ViewData.month -1;
    
    interviewBuilder.monthBack();
    
    ReloadCalendarTable();
    ReloadCalendar();
});

$("#calendar-month-next").on("click", function() {
    // ViewData.date = null;
    ViewData.month = ViewData.month +1;
    
    interviewBuilder.monthNext();
    
    ReloadCalendarTable();
    ReloadCalendar();
});

$("#calendar-year-dd").on("change", function() {
    ViewData.year = parseInt($("#calendar-year-dd").val());
    
    interviewBuilder.checkMonthBack();

    ReloadCalendarTable();
    ReloadCalendar();
}); 

$("#calendar-month-dd").on("change", function() {
    // ViewData.date = null;
    ViewData.month = parseInt($("#calendar-month-dd").val());
    
    interviewBuilder.checkMonthBack();

    ReloadCalendarTable();
    ReloadCalendar();
});

$( "#calendar-starting-time" ).on("change", function() {
    ViewData.startTime = parseInt($(this).val());
    
    //DEBUG - Set time to 2 to show calendar and avoid time zone difference Default should be 0
    if (ViewData.startTime < 2) {
        ViewData.startTime = 2;
    }

    interviewBuilder.changeStartingTime($(this));
    
    ReloadCalendar();
    ReloadCalendarTable();
    if (ViewData.date != null) {
        LoadDayPane();
    }
    //$("#day-appointments").html("<h4>Please click on a day to show more details</h4>");
});

$( "#calendar-ending-time" ).on("change", function() {
    ViewData.endingTime = parseInt($(this).val());

    interviewBuilder.changeEndingTime($(this));
    ReloadCalendarTable();
    ReloadCalendar();
    if (ViewData.date != null) {
        LoadDayPane();
    }
    //$("#day-appointments").html("<h4>Please click on a day to show more details</h4>");
}); 

$('#room-attributes-filter-collection').on('click', '.room-attribute', function(){
    ViewData.roomAttributes = '';
    $('#room-attributes-filter-collection .room-attribute').each(function(i, roomAttribute) {
        let isChecked = $(this).prop('checked');
        let roomAttributeId = $(this).data('room-attribute-id');
        if(isChecked) {
            //Selected filter room attributes
            ViewData.roomAttributes += roomAttributeId + ',';
        }
    });
    ViewData.roomAttributes = ViewData.roomAttributes.substring(0, ViewData.roomAttributes.length - 1);

    ReloadCalendarTable();
    ReloadCalendar();

    if (ViewData.date != null) {
        LoadDayPane();
    }
});

$("#calendar-remove-filters").on("click", function(){
    //DEBUG - Set time to 2 to show calendar and avoid time zone difference Default should be 0
    ViewData.startTime = 2;
    ViewData.endingTime = -1;
    interviewBuilder.removeFilters();
    ReloadCalendarTable();
    ReloadCalendar();
    if (ViewData.date != null) {
        LoadDayPane();
    }
});

function LoadDayPane() {
    if (!ViewData.date) {
        return;
    }

    interviewBuilder.highlightDay();

    let firstDay = new Date(Date.parse(ViewData.date));
    let lastDay = new Date(Date.parse(ViewData.date));

    firstDay.setHours(ViewData.startTime);

    if (ViewData.endingTime < 1 || ViewData.endingTime == 24) {
        lastDay.setHours(23);
        lastDay.setMinutes(59);
        lastDay.setSeconds(59);
    } else {
        lastDay.setHours(ViewData.endingTime);
    }

    let interviewsFormData = {
        FromStartDateTime: dateHelper.js2sql(firstDay),
        ToStartDateTime: dateHelper.js2sql(lastDay),
        RoomAttributes: ViewData.roomAttributes
    };

    courseService.getInterviewSlots(ViewData.CourseId, interviewsFormData).then(function (interviews) {
        interviewBuilder.setAppointments(interviews);
        loadingHelper.release();
    }).fail(function (exception) {
        logger.error(exception);
    })

}

function ReloadCalendar() {
    interviewBuilder.emptyCalendar();

    let firstDay = new Date(ViewData.year, ViewData.month - 1, 1);
    let lastDay = new Date(ViewData.year, ViewData.month, 0);

    firstDay.setHours(ViewData.startTime);

    if (ViewData.endingTime < 1 || ViewData.endingTime == 24) {
        lastDay.setHours(23);
        lastDay.setMinutes(59);
        lastDay.setSeconds(59);
    } else {
        lastDay.setHours(ViewData.endingTime);
    }

    let interviewsFormData = {
        FromStartDateTime: dateHelper.js2sql(firstDay),
        ToStartDateTime: dateHelper.js2sql(lastDay),
        RoomAttributes: ViewData.roomAttributes
    };

    interviewBuilder.highlightDay();
}

function ReloadCalendarTable() {
    interviewBuilder.setCalendar(ViewData.year, ViewData.month);

    let firstDay = new Date(ViewData.year, ViewData.month - 1, 1);
    let lastDay = new Date(ViewData.year, ViewData.month, 0);

    firstDay.setHours(ViewData.startTime);

    if (ViewData.endingTime < 1 || ViewData.endingTime == 24) {
        lastDay.setHours(23);
        lastDay.setMinutes(59);
        lastDay.setSeconds(59);
    } else {
        lastDay.setHours(ViewData.endingTime);
    }

    let interviewsFormData = {
        FromStartDateTime: dateHelper.js2sql(firstDay),
        ToStartDateTime: dateHelper.js2sql(lastDay),
        RoomAttributes: ViewData.roomAttributes
    };

    courseService.getInterviewSlotsCount(ViewData.CourseId, interviewsFormData).then(function (slotsCount) {
        slotsCount.forEach(function (daySlotsCount, i) {
            let dayDate = daySlotsCount.InterviewDate.substring(0, 10);
            interviewBuilder.setCalendarDay(dayDate, daySlotsCount);
        })
    }).fail(function (exception) {
        logger.error(exception);
    })
}
