function switchTab(Tab) {
    $(".navigation-button").removeClass("active");
    $(".navigation-tab").hide();
    $(Tab).show();
    $('.navigation-button[data-tab="' + Tab + '"]').addClass("active");
}

function buildSupervisionPage(app) {

    if(app.IsAnswerable)
    {
        $("#firstName").val(app.ApplicantFirstName);
        $("#lastName").val(app.ApplicantLastName);
        $("#ApplicantMail").val(app.ApplicantEmail);
        $("#ApplicantPhone").val(app.ApplicantPhone);
        $("#Course").val(app.CourseName);
        $("#supervisor-supervisionUuid").val(app.UUID);
        switchTab("#Home");
    }

    if(app.IsAnswered)
    {
        prepareFinalPage(app.IsAccepted);
    }

    if(app.IsCancelled)
    {
        buildNoEntryPage("Supervision request has been cancelled by the applicant.");
    }
}

function prepareFinalPage(Accepted) {

    $("#pagesNavCollapse > p").hide();

    if (Accepted) {
        $(".supervision-answer-status").text("This supervision request has been approved!");
        $(".supervision-answer-status").addClass("pac_green");
    }
    else {
        $(".supervision-answer-status").text("This supervision request has been rejected!");
        $(".supervision-answer-status").addClass("pac_red");
    }

    $('a[data-tab="#Final"]').parent().show();
    switchTab("#Final");
}

function buildNoEntryPage(message)
{
    $("#pagesNavCollapse > p").hide();
    $(".supervision-error-message").text(message);
    switchTab("#Error");
}

module.exports = {
    switchTab: switchTab,
    buildSupervisionPage: buildSupervisionPage,
    prepareFinalPage: prepareFinalPage,
    buildNoEntryPage: buildNoEntryPage
}