const error = require('./errorHandling');
const logger = require('../helpers/clientLogger');

function getCourses(params, user) {
    console.log(params);
    let ajax = {
        async: true,
        crossDomain: true,
        url: _pac_ + "/v2/courses.php",
        method: "POST",
        timeout: "60000",
        xhrFields: {
            withCredentials: true
        },
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: params
    };
    if (user) {
        if (user.EUCitizen) {
            ajax.url += "?eu=1";
        } else {
            ajax.url += "?eu=0";
        }
    }
    return $.ajax(ajax).then(
        function(response) {
            return response;
        }
    ).fail(
        function(err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error getting courses.");
        }
    );
}

function getFaculties(orgId) {
    return $.ajax({
        async: true,
        crossDomain: true,
        url: _pac_ + "/v2/faculties.php",
        method: "POST",
        timeout: "60000",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: {
            OrganisationId: orgId
        }
    }).then(
        function(response) {
            return response;
        }
    ).fail(
        function(err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error getting faculties.");
        }
    );
}

function getDepartments(orgId) {
    return $.ajax({
        async: true,
        crossDomain: true,
        url: _pac_ + "/v2/departments.php",
        method: "POST",
        timeout: "60000",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: {
            OrganisationId: orgId
        }
    }).then(
        function(response) {
            return response;
        }
    ).fail(
        function(err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error getting departments.");
        }
    );
}

function getApplications(username) {
    return $.ajax({
        async: true,
        crossDomain: true,
        url: _pac_ + "/v2/applications.php",
        method: "POST",
        timeout: "60000",
        xhrFields: {
            withCredentials: true
        },
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: {
            UserName: localStorage.getItem("UserName")
        }
    }).then(
        function(response) {
            return response;
        }
    ).fail(
        function(err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error getting v1 applications.");
        }
    );
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    getCourses: getCourses,
    getFaculties: getFaculties,
    getDepartments: getDepartments,
    getApplications: getApplications
}