function switchTab(Tab) {
    $(".navigation-button").removeClass("active");
    $(".navigation-tab").hide();
    $(Tab).show();
    $('.navigation-button[data-tab="' + Tab + '"]').addClass("active");
}

function buildCountries(countries) {
    countries.forEach(function (country) {
        $("#recommender-institute-country").append('<option value="' + country.Name + '">' + country.Name + '</option>')
    })
}

function buildRecommendationPage(app) {

    if(app.IsCancelled)
    {
        buildNoEntryPage("Reference request has been cancelled by the applicant.");
    }
    if(app.IsAnswerable)
    {
        $("#firstName").val(app.ApplicantFirstName);
        $("#lastName").val(app.ApplicantLastName);
        $("#Course").val(app.CourseName);
        $("#Institution").val(app.OrganisationName);
    
        $("#recommender-recommendationUuid").val(app.UUID);
        $("#recommender-email").val(app.Email);
    
        $("#recommender-firstName").val(app.FirstName);
        $("#recommender-lastName").val(app.LastName);
        $("#recommender-phone").val(app.PhoneNumber)
        $("#recommender-email").val(app.Email);
        $("#recommender-institute-name").val(app.InstitutionName)
        $("#recommender-institute-address").val(app.InstitutionAddress)
        $("#recommender-institute-locality").val(app.InstitutionLocality)
        $("#recommender-institute-country").val(app.InstitutionCountry)
        $("#recommender-position").val(app.PositionHeld)
        switchTab("#Home");
    }


    if (app.IsAnswered) {
        prepareFinalPage(app.IsRecommendationRequestAccepted);
    }
}

function prepareFinalPage(Accepted) {

    $("#pagesNavCollapse > p").hide();
    $('a[data-tab="#Final"]').parent().show();

    if (Accepted) {
        $(".recommendation-answer-status").text("Reference has been successfully submitted!");
        $(".recommendation-answer-status").addClass("pac_green");
    }
    else {
        $(".recommendation-answer-status").text("Reference has been rejected!");
        $(".recommendation-answer-status").addClass("pac_red");
    }

    switchTab("#Final");

    $("#recommender-recommendationUuid").prop("disabled", true);
    $("#recommender-firstName").prop("disabled", true);
    $("#recommender-lastName").prop("disabled", true);
    $("#recommender-phone").prop("disabled", true);
    $("#recommender-email").prop("disabled", true);
    $("#recommender-institute-name").prop("disabled", true);
    $("#recommender-institute-address").prop("disabled", true);
    $("#recommender-institute-locality").prop("disabled", true);
    $("#recommender-institute-country").prop("disabled", true);
    $("#recommender-position").prop("disabled", true);
    $("#recommendation-document").prop("disabled", true);
    $("#SubmitRecommendation").prop("disabled", true);
}

function buildNoEntryPage(message) {
    $("#pagesNavCollapse > p").hide();
    $(".recommendation-error-message").text(message);
    switchTab("#Error");
}

module.exports = {
    switchTab: switchTab,
    buildCountries: buildCountries,
    buildRecommendationPage: buildRecommendationPage,
    prepareFinalPage: prepareFinalPage,
    buildNoEntryPage: buildNoEntryPage
}