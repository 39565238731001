const logger = require('log4javascript');
const stackTrace = require('stacktrace-js');
// const { exceptions } = require('winston');

var log = logger.getLogger('v2web Client');
var ajaxAppender = new logger.AjaxAppender(_loggerUrl_);

switch (_loggerLevel_)
{
    case 'fatal':
        ajaxAppender.setThreshold(logger.Level.FATAL);
        break;
    case 'error':
        ajaxAppender.setThreshold(logger.Level.ERROR);
        break;
    case 'warn':
        ajaxAppender.setThreshold(logger.Level.WARN);
        break;
    case 'info':
        ajaxAppender.setThreshold(logger.Level.INFO);
        break;
    case 'debug':
        ajaxAppender.setThreshold(logger.Level.DEBUG);
        break;
    case 'trace':
        ajaxAppender.setThreshold(logger.Level.TRACE);
        break;
}

//ajaxAppender.setBatchSize(10); // send in batches of 10
ajaxAppender.setTimed(true);
ajaxAppender.setTimerInterval(3000); // send every 10 seconds (unit is milliseconds)
ajaxAppender.setSendAllOnUnload(); // send all remaining messages on window.beforeunload()

ajaxAppender.addHeader("Content-Type", "application/json");
var jsonLayout = new logger.JsonLayout(false, false);
ajaxAppender.setLayout(jsonLayout);

log.addAppender(ajaxAppender);
var sessionToken = localStorage.getItem("SessionToken");

function fatal(message) 
{
    log.fatal(prepareErrorMessage(message), sessionToken);
}

function error(message) 
{
    log.error(prepareErrorMessage(message), sessionToken);
}

function warn(message) 
{
    log.warn(prepareErrorMessage(message), sessionToken);
}

function info(message) 
{
    log.info(prepareErrorMessage(message), sessionToken);
}

function debug(message) 
{
    log.debug(prepareErrorMessage(message), sessionToken);
}

function trace(message) 
{
    log.trace(prepareErrorMessage(message), sessionToken);
}

window.onerror = function(message, file, line, column, error) {
    stackTrace.fromError(error)
    .then (function(stack){
        log.error(prepareErrorMessage(message), file, 'line: ' + line, 'column: ' + column, sessionToken);
    })
    .catch(function(exception){
        log.error(prepareErrorMessage(exception), sessionToken);
    });
    bootoast.toast({
        message: "An error has occurred.",
        timeout: false,
        type: "danger",
        icon: "alert"
    });
};

function prepareErrorMessage(message)
{
    var msg = message;

    if(typeof message === 'object')
    {
        msg = JSON.stringify(msg, null, 4)
    }

    msg = msg.split('\u001b').join('');
    msg = msg.split(']').join(')');
    msg = msg.split('[').join('(');
    msg = msg.split('}').join(')');
    msg = msg.split('{').join('(');
    msg = msg.split('"').join('\'');
    msg = msg.split('\\').join('/');

    return msg;
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    fatal,
    error,
    warn,
    info,
    debug,
    trace
}