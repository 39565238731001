import "../events/applyEvents";

const applyBuilder = require('../builders/applyBuilder');
const coursesService = require('../services/coursesService');
const urlHelper = require('../helpers/urlHelper');

// Jquery validation plugin default settings
$.validator.setDefaults({
    onsubmit: false,
    errorClass: "pac-form-error",
    validClass: "pac-form-valid"
});

// Jquery validation plugin custom methods
jQuery.validator.addMethod("tristate", function(value, element, param) {
    switch(value){
        case "true":
        case "false": return true;
        default: return false;
    }
}, "Please select YES or NO.");
jQuery.validator.addMethod("filefield", function(value, element, param) {
    var sib = $(element).siblings(".attribute-file-table")[0];
    var rows = $(sib).find(".file-table-row");
    if(rows.length > 0){
        return true;
    } else {
        return false;
    }
}, "A file must be uploaded.");
jQuery.validator.addMethod("minwords", function(value, element, param) {
    if (value.trim().replace(/\s\s+/g, " ").split(/\s/).length < param) {
        return false;
    } else {
        return true;
    }
}, "Text has too few words, please edit.");
jQuery.validator.addMethod("maxwords", function(value, element, param) {
    if (value.trim().replace(/\s\s+/g, " ").split(/\s/).length > param) {
        return false;
    } else {
        return true;
    }
}, "Text has too many words, please edit.");
jQuery.validator.addMethod("prioritylist", function(value, element, param) {
    try {
        let values = JSON.parse(value);
        if (values.length !== param) {
            return false;
        }
        for (let ii = 0; ii < param; ii++){
            if (values[ii] === null) {
                return false;
            }
        }
        return true;
    } catch (error) {
        return false;
    }
}, "Pleaes select all options.");
jQuery.validator.addMethod("validateppsn", function(value, element) {
    if(this.optional(element)) return true;

    return validatePPSN(value);

}, "Please enter a valid PPS number.");

// Pages sticky menu
function setStickyMenu() {
    // The -80 is to account for the height of the main nav bar, this value may need tweaking
    $('#pagesNavAffix').affix({
        offset: {
            top: Math.ceil($("#pagesNavAffix").offset().top) - 80
        }
    })
}

// First thing to do is look for a CourseID
let cid = parseInt(urlHelper.getQueryVariable("c"));
if (isNaN(cid)) {
    let appId = urlHelper.getQueryVariable("id");
    if (typeof appId !== "undefined") {
        appId = Number.parseInt(appId);
        applyBuilder.batchForm(urlHelper.getQueryVariable("b"), appId);
    } else {
        applyBuilder.batchForm(urlHelper.getQueryVariable("b"));
    }
} else {
    coursesService.getCourse(cid).then(function(course) {
        sessionStorage.setItem("courseId", cid);
        window.location.href = "/institute/" + course.OrganisationId;
        return;
    });
}

function validatePPSN(value, validationRegex = /^(\d{7})([A-Z]{1,2})$/i)
{
    var ppsnParts, numericPart, checksumA, letterA, checksumB = null, multiplyingFactor = 8, sum = 0, checksum;
    var alphaArray = ['W','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V'];
    var numArray   = [ 0,  1,  2,  3,  4,  5,  6,  7,  8,  9,  10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22 ];


    if (!validationRegex.test(value))
        return false;

    ppsnParts = validationRegex.exec(value);

    if(ppsnParts.length != 3)
        return false;

    numericPart = ppsnParts[1];

    checksumA = ppsnParts[2].charAt(0);

    if(ppsnParts[2].length > 1) 
        checksumB = ppsnParts[2].charAt(1);

    for (var i = 0; i < numericPart.length; i++) {
        sum += parseInt(numericPart[i]) * multiplyingFactor--;
    }
    if(checksumB != null)
    {
        sum += (checksumB.toUpperCase().charCodeAt(0) - 64) * 9;
    }

    checksum = sum % 23;

    letterA = alphaArray[numArray.indexOf(checksum)];

    if (checksumA != letterA) return false; //check character doesn't match

    if(checksumB != null)
    {
        if(["W","T","X","A"].indexOf(checksumB) == -1) return false; //2nd check character not allowed
    }
    return true;
}