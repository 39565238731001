const recommendationBuilder = require("../builders/recommendationBuilder");
const recommendationService = require("../services/recommendationService");
const loadingHelper = require('../helpers/loading');
const fileService = require('../services/fileService');
const logger = require('../helpers/clientLogger');

$(".navigation-button").on('click', function (e) {
    e.preventDefault();
    recommendationBuilder.switchTab($(this).data('tab'));
})

$("#SubmitRecommendation").on('click', function(e) {
    var form = $("#Recommendation-Data");
    if (!form[0].checkValidity()) {
        $('<input type="submit">').hide().appendTo(form).trigger("click").remove();
    } else {
        data = {
            RecommendationUuid: $("#recommender-recommendationUuid").val(),
            FirstName: $("#recommender-firstName").val(),
            LastName: $("#recommender-lastName").val(),
            PhoneNumber: $("#recommender-phone").val(),
            InstitutionName: $("#recommender-institute-name").val(),
            InstitutionAddress: $("#recommender-institute-address").val(),
            InstitutionLocality: $("#recommender-institute-locality").val(),
            InstitutionCountry: $("#recommender-institute-country").val(),
            PositionHeld: $("#recommender-position").val(),
            IsRecommendationRequestAccepted: true
        }

        loadingHelper.block();
        recommendationService.UpdateByRecommender(data)
            .then(function(result) {
                uploadFileToFileServer($("#recommendation-document"), data.RecommendationUuid)
            }).fail(function(err) {
                logger.error(err);
                loadingHelper.release();
                bootoast.toast({
                    message: "Error has occured while submitting your reference!",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
            })
    }
});

$("#declineRecommendation").on('click', function(e) {
    e.preventDefault();
    if (confirm("Are you sure you want to reject this reference request?")) {
        data = {
            RecommendationUuid: $("#recommender-recommendationUuid").val(),
            IsRecommendationRequestAccepted: false
        }

        loadingHelper.block();
        recommendationService.UpdateByRecommender(data)
            .then(function (result) {
                loadingHelper.release();
                recommendationBuilder.prepareFinalPage(false);
                bootoast.toast({
                    message: "Reference rejected.",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });

            }).fail(function (err) {
                logger.error(err);
                loadingHelper.release();
                bootoast.toast({
                    message: "Error has occured while submitting your reference!",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
            })
    }
});

function uploadFileToFileServer(DOMFileElement, uuid) {
    var formData = new FormData();

    var files = DOMFileElement.get(0);

    for (var i = 0; i < files.files.length; i++) {
        var file = files.files[i];
        formData.append('files', file, file.name);
    }
    if (files) {
        if (formData.get('files') != null) {
            formData.append('RecommendationUuid', uuid);
            fileService.uploadRecommendationFile(formData)
                .then(function (fileResponse) {
                    loadingHelper.release();
                    recommendationBuilder.prepareFinalPage(true);
                    bootoast.toast({
                        message: "Reference successfully uploaded!",
                        timeout: false,
                        type: "success",
                        icon: "alert"
                    });
                }).fail(function (err) {
                    logger.error(err);
                    loadingHelper.release();
                    bootoast.toast({
                        message: "Error has occured while submitting your reference!",
                        timeout: false,
                        type: "danger",
                        icon: "alert"
                    });
                });
        }
    }
}

$('#Recommendation-Data').on('click', '.file-item', function () {
    var fileId = $(this).data('file-id');
    fileService.getDownloadUrl(fileId)
        .then(function (file) {
            var win = window.open(file.Url, '_blank');
            // win.focus();
        });
})