import "../events/interviewEvents";


const applyService = require('../services/applyService');
const applicationDetailsService = require('../services/applicationDetailsService');
const courseService = require('../services/coursesService');
const applyBuilder = require('../builders/applyBuilder');
const interviewBuilder = require('../builders/interviewBuilder');
const interviewService = require('../services/interviewService');
const urlHelper = require('../helpers/urlHelper');
const dateHelper = require('../helpers/dateHelper');
const loadingHelper = require('../helpers/loading');
const logger = require('../helpers/clientLogger');
let applicationId = parseInt(urlHelper.getQueryVariable("a"));

global.ViewData = {};
ViewData.ApplicationId = null;
ViewData.CourseId = null;

// loadingHelper.block();

if (applicationId) {
    applyService.getApplication(applicationId).then(function(application) {
        if (typeof application.ApplicationId === "undefined") {
            bootoast.toast({
                message: "No valid data found",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
            throw new Error("No valid data found");
        }
        ViewData.ApplicationId = application.ApplicationId;
        ViewData.CourseId = application.CourseId;

        applyService.getInterviewSlot(application.ApplicationId).then(function(slot) {
            if (slot.SlotId) {
                ViewData.IsBooked = true;
                interviewBuilder.setInterviewSlotTable(slot);
                interviewBuilder.openSlotModal();
            }
        }).fail(function(exception) {
            logger.error(exception);
        });

        applyService.getCourseByCourseId(ViewData.CourseId).then(function (course) {
            interviewBuilder.setCourseTitle(course.CourseCode + ' ' + course.Name, course.CourseId);
            ViewData.interviewDuration = course.InterviewDuration;
            loadingHelper.release();
        }).fail(function (exception) {
            logger.error(exception);
            loadingHelper.release();
        })
    
        ViewData.date = null;
        ViewData.startTime = 0;
        ViewData.endingTime = 24;
        ViewData.wrap = true;
    
        let d = new Date();
        ViewData.year = d.getFullYear();
        ViewData.month = d.getMonth() + 1;
        ViewData.rtype = "all";
        interviewBuilder.initializeYearMonth();
        interviewBuilder.setCalendar(ViewData.year, ViewData.month);
    
        let firstDay = new Date(ViewData.year, ViewData.month - 1, 1);
        let lastDay = new Date(ViewData.year, ViewData.month, 0);
        lastDay.setHours(23);
        lastDay.setMinutes(59);
        lastDay.setSeconds(59);
    
        let interviewsFormData = {
            FromStartDateTime: dateHelper.js2sql(firstDay),
            ToStartDateTime: dateHelper.js2sql(lastDay)
        };
    
        courseService.getInterviewSlotsCount(ViewData.CourseId, interviewsFormData).then(function (slotsCount) {
            slotsCount.forEach(function (daySlotsCount, i) {
                let dayDate = daySlotsCount.InterviewDate.substring(0, 10);
                interviewBuilder.setCalendarDay(dayDate, daySlotsCount);
            })
        }).fail(function (exception) {
            logger.error(exception);
        })
    
        interviewService.getAllRoomAttributes().then(function (roomAttributes) {
            interviewBuilder.setRoomAttributesFilter(roomAttributes);
        }).fail(function (exception) {
            logger.error(exception);
        })
    
        interviewBuilder.showCalendar();
    }).fail(function (exception) {
        logger.error(exception);
    })
} else {
    interviewBuilder.interviewShutdown();
}