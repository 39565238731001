const error = require('../services/errorHandling');
const microLayer = require('../services/microLayerService');
const logger = require('../helpers/clientLogger');

function sendApplicationToAssessor(id, data) {
    return microLayer.execute(microLayer.httpMethods.POST, "applications/" + id + "/SendApplicationToAssessor", data)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving applications.")
        });
}

function getApplicantByUserId(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "users/" + id)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving applicant.")
        });
}

module.exports = {
    sendApplicationToAssessor: sendApplicationToAssessor,
    getApplicantByUserId: getApplicantByUserId
}