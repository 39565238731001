import "../events/headerEvents";
import userService from "../services/userService";

const auth = require('../services/authentication');
const headerBuilder = require('../builders/headerBuilder');

if (auth.isLoggedIn()) {
    headerBuilder.hideLoginButton();
    
    userService.me()
    .then(function(data){
        headerBuilder.togglePendingActionContainer(data.HasPendingActions)
        headerBuilder.checkUserData(data);
    })
}

// The following code was extracted from html template js, needs to run after header block is loaded
$('.body-wrapper').each(function() {
    var header_area = $('.header-wrapper');
    var main_area = header_area.children('.header');

    var logo = main_area.find('.navbar-header');
    var navigation = main_area.find('.navbar-collapse');
    var original = {
        nav_top: navigation.css('margin-top')
    };

    $(window).on("scroll", function() {
        if (main_area.hasClass('bb-fixed-header') && ($(this).scrollTop() === 0 || $(this).width() < 750)) {
            main_area.removeClass('bb-fixed-header').appendTo(header_area);
            navigation.animate({
                'margin-top': original.nav_top
            }, {
                duration: 300,
                queue: false,
                complete: function() {
                    header_area.css('height', 'auto');
                }
            });
        } else if (!main_area.hasClass('bb-fixed-header') && $(this).width() > 750 &&
            $(this).scrollTop() > header_area.offset().top + header_area.height() - parseInt($('html').css('margin-top'), 10)) {

            header_area.css('height', header_area.height());
            main_area.css({
                'opacity': '0'
            }).addClass('bb-fixed-header');
            main_area.appendTo($('body')).animate({
                'opacity': 1
            });

            navigation.css({
                'margin-top': '0px'
            });
        }
    });
});
// $(window).trigger('resize');
// $(window).trigger('scroll');
// End extracted header js

headerBuilder.instMenu();