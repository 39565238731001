const supervisionBuilder = require("../builders/supervisionBuilder");
const supervisionService = require("../services/supervisionService");
const loadingHelper = require('../helpers/loading');
const logger = require('../helpers/clientLogger');

$(".navigation-button").on('click', function (e) {
    e.preventDefault();

    supervisionBuilder.switchTab($(this).data('tab'));
})

$("#Supervision-Data .btn-success").on('click', function (e) {
    e.preventDefault();
    sendSupervisionRequestAnswer(true);
})

$("#Supervision-Data .btn-danger").on('click', function (e) {
    e.preventDefault();
    sendSupervisionRequestAnswer(false);
})

function sendSupervisionRequestAnswer(_IsAccepted) {
    loadingHelper.block();
    data = {
        SupervisionRequestUuid: $("#supervisor-supervisionUuid").val(),
        IsAccepted: _IsAccepted
    }

    supervisionService.UpdateBySupervisor(data)
        .then(function (success) {
            loadingHelper.release();
            supervisionBuilder.prepareFinalPage(_IsAccepted);
            bootoast.toast({
                message: "Supervision request answered!",
                timeout: false,
                type: "success",
                icon: "alert"
            });
        }).fail(function (err) {
            logger.error(err);
            loadingHelper.release();
            bootoast.toast({
                message: "Error has occured while answering your supervision request!",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
        })
}