const error = require('./errorHandling');
const microLayer = require('./microLayerService');
const logger = require('../helpers/clientLogger');

function me() {
	return microLayer.execute(microLayer.httpMethods.GET, "me")
        .then(function(response) { return response })
        .fail(function(err) { logger.error(err); error.handleWithToast(err, "There was an error getting extra info.") });
}

function login(data) {
	return microLayer.execute(microLayer.httpMethods.POST, "users/login", data)
        .then(function(response) { return response })
        .fail(function(err) { logger.error(err); error.handleWithToast(err, "There was an error logging in.") });
}

function logout() {
	return microLayer.execute(microLayer.httpMethods.POST, "users/logout")
        .then(function(response) { return response })
        .fail(function(err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error logging out.")
        });
}

function requestPasswordToken(data) {
	return microLayer.execute(microLayer.httpMethods.POST, "users/requestpasswordtoken", data)
		.then(function (response) { return response })
        .fail(function(err) { logger.error(err); error.handleWithToast(err, "There was an error requesting new token.") });
}

function validateToken(data) {
	return microLayer.execute(microLayer.httpMethods.PUT, "users/validate/passwordtoken", data)
		.then(function (response) { return response })
        .fail(function(err) { logger.error(err); error.handleWithToast(err, "There was an error validating token.") });
}

function tokenPasswordReset(data) {
	return microLayer.execute(microLayer.httpMethods.POST, "users/tokenresetpassword", data)
		.then(function (response) { return response })
        .fail(function(err) { logger.error(err); error.handleWithToast(err, "There was an error resetting token.") });
}

function completePasswordReset(data) {
	return microLayer.execute(microLayer.httpMethods.POST, "users/completePasswordReset", data)
		.then(function (response) { return response })
        .fail(function(err) { logger.error(err); error.handleWithToast(err, "There was an reseting the password.") });
}

function createUser(data) {
    let url;
    if(data.UserTypeId == 1) {
        url = "users/applicants";
    } else if(data.UserTypeId == 2) {
        url = "users/agents";
    } else {
        throw new Error("Invalid user type");
    }
	return microLayer.execute(microLayer.httpMethods.POST, url, data)
        .then(function (response) { return response })
        .fail(function(err) {
            logger.error(err);
            if(err.responseText.includes('You already have a PAC user account.'))
            {
                error.handleWithToast(err, "An error has occurred.<div class='small'>You already have a PAC user account. Multiple user accounts are not allowed. Please log in with an existing user account or <a href='/help'>contact PAC Support for assistance</a>.</div>");
            }
            else
            {
                error.handleWithToast(err, "An error has occurred.<div class='small'>If you have already registered you can log in by clicking the button at the top of this page or <a href='/reset'>reset your password</a>.</div>");
            }
        });
}

function changePassword(data) {
	return microLayer.execute(microLayer.httpMethods.POST, "me/updatePassword", data)
		.then(function (response) { return response })
        .fail(function(err) { logger.error(err); error.handleWithToast(err, "There was an error updating password.") });
}

function updateProfile(data) {
	return microLayer.execute(microLayer.httpMethods.PUT, "me", data)
		.then(function (response) { return response })
        .fail(function(err) { logger.error(err); error.handleWithToast(err, "There was an error updating the user profile.") });
}

function GetOrganisationAgentByUserIdOrganisationId(UserId, OrganisationId) {
	return microLayer.execute(microLayer.httpMethods.GET, "users/organisationAgents/"+UserId+"/"+OrganisationId)
		.then(function (response) {
            if(response)
                return response[0];
            else
                return null; 
        })
        .fail(function(err) { logger.error(err); error.handleWithToast(err, "There was an error getting organisation agent.") });
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    me: me,
	login: login,
	logout: logout,
	requestPasswordToken: requestPasswordToken,
	validateToken: validateToken,
    tokenPasswordReset: tokenPasswordReset,
    completePasswordReset: completePasswordReset,
    createUser: createUser,
	changePassword: changePassword,
    updateProfile: updateProfile,
    GetOrganisationAgentByUserIdOrganisationId: GetOrganisationAgentByUserIdOrganisationId
}