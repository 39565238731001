import "core-js/stable";
import "regenerator-runtime/runtime";

$(function() {
    // Store requested URL
    if (
        !window.location.href.includes("/expired") &&
        !window.location.href.includes("/register") &&
        !window.location.href.includes("/register-confirm") &&
        !window.location.href.includes("/reset") &&
        !window.location.href.includes("/completePasswordReset") &&
        !window.location.href.includes("/userconfirmemail") &&
        !window.location.href.includes("/activateagentuser") &&
        !window.location.href.includes("/applicationFeePaymentSummary") &&
        !window.location.href.includes("/securityDepositPaymentSummary")
    ) {
        sessionStorage.setItem("address", window.location.href);
    }

    require('./controllers/headerController');
    let path = window.location.pathname.replace(/^\/+|\/+$/g, '');

    // Check if path has multiple levels or not
    if (path.includes("/")) {
        // Multi level routes
        let levels = path.split("/");
        switch (levels[0].toLowerCase()) {
            case "institute":
                require('./controllers/instituteController');
                break;
            case "application":
                require('./controllers/applicationDetailsController');
                break;
        }
        switch(levels[1].toLowerCase())
        {
            case 'completepasswordreset':
                require('./controllers/completePasswordResetController');
                break;
        }
    } else {
        // Single level routes
        switch (path.toLowerCase()) {
            case 'dashboard':
                require('./controllers/dashboardController');
                break;
            case 'profile':
                require('./controllers/profileController');
                break;
            case 'apply':
                require('./controllers/applyController');
                break;
            case 'interview':
                require('./controllers/interviewController');
                break;
            case 'register':
                require('./controllers/registerController');
                break;
            case 'recommendation':
                require('./controllers/recommendationController');
                break;
            case 'supervision':
                require('./controllers/supervisionController');
                break;
            case 'summary':
                require('./controllers/summaryController');
                break;
            case 'activateagentuser':
                require('./controllers/activateAgentUserController');
                break;
        }
    }
});