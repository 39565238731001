function sql2js(sqlDate) {
    var jsDate;

    if (sqlDate == null || sqlDate == undefined) {
        jsDate = null;
    } else {
        jsDate = sqlDate.replace('T', ' ').replace('Z', '');
        jsDate = new Date(jsDate);
    }
    return jsDate;
}

function sql2jsLocaleDateString(sqlDate) {
    var jsDate;

    if (sqlDate == null || sqlDate == undefined) {
        jsDate = null;
    } else {
        jsDate = sqlDate.replace('T', ' ').replace('Z', '');
        jsDate = new Date(jsDate);

        var options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        };
        jsDate = jsDate.toLocaleDateString('en-GB', options);
    }

    return jsDate;
}

function js2sql(jsDate) {
    return new Date(jsDate.getTime() - jsDate.getTimezoneOffset() * 60000);
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    sql2js: sql2js,
    sql2jsLocaleDateString: sql2jsLocaleDateString,
    js2sql: js2sql
}