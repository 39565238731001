const auth = require('./authentication');

const httpMethods = {
    GET: "GET",
    PUT: "PUT",
    PATCH: "PATCH",
    POST: "POST",
    DELETE: "DELETE"
}

let defaultSettings = {
    "async": true,
    "crossDomain": true,
    "url": "",
    "method": "GET",
    "timeout": "60000",
    "xhrFields": {
        withCredentials: true
    },
    "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
    },
    "data": {}
}

function execute(httpMethod, route, bodyData = {}, settings = undefined) {
    if (settings == undefined) {
        settings = defaultSettings;
        settings.method = httpMethod;
        settings.url = _microservices_ + "/" + route;
        settings.data = bodyData;
    }
    return $.ajax(auth.Tokenize(settings));
}

function fileServer(httpMethod, route, bodyData = {}, options = undefined) {
    let settings = $.extend(true, {}, defaultSettings, options);
    settings.method = httpMethod;
    settings.url = _fileServer_ + "/" + route;
    settings.cache = false;
    settings.processData = false;
    settings.contentType = false;
    settings.data = bodyData;
    settings.headers = {
        "Authorization": localStorage.SessionToken == undefined ? null : localStorage.SessionToken
    };

    return $.ajax(settings).done(function(response) {
        //
    }).fail(function(err) {
        return err;
    });
}

module.exports = {
    httpMethods: httpMethods,
    execute: execute,
    fileServer: fileServer
}