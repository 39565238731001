const microLayer = require('./microLayerService');
const error = require('../services/errorHandling');
const logger = require('../helpers/clientLogger');

function getCountries(){
    return microLayer.execute(microLayer.httpMethods.GET, "countries")
        .then(function (response) { return response })
        .fail(function(err) { logger.error(err); error.handleWithToast(err, "There was an error getting countries.") });
}


/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    getCountries: getCountries,
}