const microLayer = require('./microLayerService');
const error = require('../services/errorHandling');
const logger = require('../helpers/clientLogger');

function startApplicationFeeOnlineTransaction(data){
    return microLayer.execute(microLayer.httpMethods.POST, "payments/applicationFeeOnlineTransaction", data)
        .then(function (response) { return response })
        .fail(function(err) { logger.error(err); error.handleWithToast(err, "There was an error initialising application fee online payment.") });
}

function startSecurityDepositOnlineTransaction(data){
    return microLayer.execute(microLayer.httpMethods.POST, "payments/securityDepositOnlineTransaction", data)
        .then(function (response) { return response })
        .fail(function(err) { logger.error(err); error.handleWithToast(err, "There was an error initialising security deposit online payment.") });
}

function payApplicationFeeByPaymentToken(batchId){
    let data = { BatchId: batchId };
    return microLayer.execute(microLayer.httpMethods.POST, "payments/applicationFeePaymentTokenTransaction", data)
        .then(function (response) { return response })
        .fail(function(err) { logger.error(err); error.handleWithToast(err, "There was an error paying application fee by payment token.") });
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    startApplicationFeeOnlineTransaction,
    startSecurityDepositOnlineTransaction,
    payApplicationFeeByPaymentToken
}