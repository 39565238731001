const userService = require('../services/userService');
const headerBuilder = require('../builders/headerBuilder');
const logger = require('../helpers/clientLogger');
/*****************************************************************
 *                          EVENTS
 ****************************************************************/

$(document.body).on("click", ".loginBtns", function(e) {
    e.preventDefault();
    login();
});

$(document.body).on("click", ".logoutBtn", function(e) {
    e.preventDefault();
    logout();
});

$(document.body).on("click", "#loginBtn", function(e) {
    e.preventDefault();
    // Login is tied to V1 login
    if (_v1login_) {
        window.location.href = _pac_ + "/v2/";
    } else {
        $("#loginModal").modal("show");
    }
});


/****************************************************************
 *                    SUPPORTING FUNCTIONS
 ****************************************************************/

/**
 * Logs in the current user
 */
function login() {
    let data = {
        UserName: $('#username').val().trim(),
        Password: $('#password').val().trim()
    }

    if (!data.UserName || !data.Password) {
        bootoast.toast({
            message: "Please enter valid credentials",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
        return;
    }

    userService.login(data).then(function(response) {
        localStorage.setItem('SessionToken', response.SessionToken);
        localStorage.setItem('UserTypeId', response.UserTypeId);
        localStorage.setItem('UserName', response.UserName);
        $('#loginModal').modal('hide');
        headerBuilder.hideLoginButton();

        userService.me()
        .then(function(data){
            headerBuilder.togglePendingActionContainer(data.HasPendingActions)
            headerBuilder.checkUserData(data);
        })

    });
}

/**
 * Logs out the current user
 */
function logout() {
    userService.logout().then(function(response) {
        localStorage.clear();
        headerBuilder.showLoginButton();
        // TODO: change this when v1 is gone
        if (_v1login_) {
            location.href = _pac_ + "/v2/v2logoutprocessor.php";
        } else {
            location.href = "/";
        }
    }).fail(function(err) {
        logger.error(err);
        localStorage.clear();
        headerBuilder.showLoginButton();
        // TODO: change this when v1 is gone
        if (_v1login_) {
            location.href = _pac_ + "/v2/v2logoutprocessor.php";
        } else {
            location.href = "/";
        }
    });
}