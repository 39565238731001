module.exports = {
    block: function() {
        HoldOn.open({
            theme: "custom",
            content: "<i class=\"fa fa-spinner fa-pulse fa-5x\"></i><span class=\"sr-only\">Loading...</span>",
            message: "<div style='font-size: 2em; padding-top: 0.5em;'>Loading... Please wait.</div>",
            backgroundColor: "#000",
            textColor: "#DDD"
        });
    },
    upload: function() {
        HoldOn.open({
            theme: "custom",
            content: "<i class=\"fa fa-spinner fa-pulse fa-5x\"></i><span class=\"sr-only\">Uploading file...</span>",
            message: "<div style='font-size: 2em; padding-top: 0.5em;'>Uploading file(s)... Please wait.</div>",
            backgroundColor: "#000",
            textColor: "#DDD"
        });
    },
    delete: function() {
        HoldOn.open({
            theme: "custom",
            content: "<i class=\"fa fa-spinner fa-pulse fa-5x\"></i><span class=\"sr-only\">Deleting file...</span>",
            message: "<div style='font-size: 2em; padding-top: 0.5em;'>Deleting file... Please wait.</div>",
            backgroundColor: "#000",
            textColor: "#DDD"
        });
    },
    release: function() {
        HoldOn.close();
    }
}