function Tokenize(ajax_params) {
    if (!ajax_params.headers) {
        ajax_params.headers = {};
    }
    if (!ajax_params.headers.Authorization) {
        if (typeof localStorage.SessionToken !== "undefined" && localStorage.SessionToken !== null) {
            ajax_params.headers.Authorization = localStorage.getItem("SessionToken");
        }
    }
    return ajax_params;
};

function removeSessionToken() {
    localStorage.removeItem("SessionToken");
}

function isLoggedIn() {
    let seshToken = localStorage.getItem("SessionToken");
    if (IsNullOrUndefined(seshToken)) {
        return false;
    }
    if (seshToken.length !== 36) {
        return false;
    }
    return true;
}

function IsNullOrUndefined(x) {
    if (x === undefined) {
        return true;
    }
    if (x === null) {
        return true;
    }
    return false;
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    Tokenize: Tokenize,
    isLoggedIn: isLoggedIn,
    removeSessionToken: removeSessionToken
}