const microLayer = require('./microLayerService');
const error = require('../services/errorHandling');
const logger = require('../helpers/clientLogger');
const { LoggingEvent } = require('log4javascript');

function usesAgentApproval() {
    return microLayer.execute(microLayer.httpMethods.GET, "organisationSettings/byKey/UsesAgentApproval").then(function(response) {
        return response
    }).fail(function(err) {
        logger.error(err);
        error.handleWithToast(err, "There was an error getting UsesAgentApproval setting.")
    });
}

function getAgentApprovalStatuses(data) {
    return microLayer.execute(microLayer.httpMethods.GET, "me/organisationApprovalStatuses", data).then(function(response) {
        return response
    }).fail(function(err) {
        logger.error(err);
        error.handleWithToast(err, "There was an error getting approval statuses.")
    });
}

module.exports = {
    usesAgentApproval: usesAgentApproval,
    getAgentApprovalStatuses: getAgentApprovalStatuses
}