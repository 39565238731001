let userService = require("../services/userService");
const loading = require('../helpers/loading');
const logger = require('../helpers/clientLogger');

$("#submitChangePasswordFormBtn").on("click", function (e) {
    e.preventDefault();

    let state = checkPasswordIntegrity($("#pass").val(), $("#passwordconfirm").val());

    switch (state) {
        case 0:
            let data = {
                OldPassword: $("#originalPass").val(),
                NewPassword: $("#pass").val()
            }

            loading.block();
            userService.changePassword(data)
            .then(function(user){
                $("#originalPass").val("");
                $("#pass").val("");
                $("#passwordconfirm").val("");
                loading.release();
                bootoast.toast({
                    message: "Password changed.",
                    timeout: false,
                    type: "success",
                    icon: "alert"
                });
            })
            .fail(function(err){
                logger.error(err);
                loading.release();
            })
            break;
        case 1:
            bootoast.toast({
                message: "Passwords don't match.",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
            break;
        case 2:
            bootoast.toast({
                message: "New password is too short. (it needs to be 8 characters minimum",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
            break;
    }
});

function checkPasswordIntegrity(pass, rpass) {
    if (pass != rpass) return 1;
    if (pass.length < 8) return 2;

    return 0;
}

$("#submitUpdateProfileFormBtn").on("click", function (e) {
    e.preventDefault();
    loading.block();

    data = {
        DisplayName: $("#name").val() + " " + $("#surname").val(),
        FirstName: $("#name").val(),
        LastName: $("#surname").val(),
        AddressLine1: $("#address1").val(),
        AddressLine2: $("#address2").val(),
        City: $("#city").val(),
        PostalCode: $("#postalcode").val(),
        CountryId: $("#countryid").val(),
        PhoneNumber: $("#phonenumber").val(),
        DateOfBirth: $("#dateofbirth").val(),
        EUCitizen: $("#EUCitizen").prop('checked')
    }

    if ($("#formContainer").data('isAgent')) {
        data.DisplayName = $("#agentName").val();
        data.AgentName = $("#agentName").val();
        data.CompanyName = $("#agentCompany").val();
    }

    let validationOptions = {
        ignore: false,
        rules: {
            firstname: {
                required: {
                    depends: function(element) {
                        return localStorage.getItem("UserTypeId") === '1' ? true : false;
                    }
                }
            },
            lastname: {
                required: {
                    depends: function(element) {
                        return localStorage.getItem("UserTypeId") === '1' ? true : false;
                    }
                }
            },
            address1: {
                required: true,
                maxlength: 50
            },
            address2: {
                required: false,
                maxlength: 50
            },
            city: {
                required: true,
                maxlength: 40
            },
            postalcode: {
                required: true,
                maxlength: 16
            },
            countryid: {
                required: true
            },
            phonenumber: {
                required: true
            },
            dateofbirth: {
                required: {
                    depends: function(element) {
                        return localStorage.getItem("UserTypeId") === '1' ? true : false;
                    }
                }
            },
            euCitizen: {
                tristate: {
                    depends: function(element) {
                        return $("#isAgent").prop("checked") ? false : true;
                    }
                }
            },
            agentName: {
                required: {
                    depends: function(element) {
                        return localStorage.getItem("UserTypeId") === '2' ? true : false;
                    }
                }
            },
            agentCompany: {
                required: {
                    depends: function(element) {
                        return localStorage.getItem("UserTypeId") === '2' ? true : false;
                    }
                }
            },
        },
        highlight: function(element, errorClass, validClass) {
            $(element).addClass(errorClass).removeClass(validClass);
            // Check if element is radio and apply error class to parent div
            if ($(element).attr("type") === "radio") {
                $(element).parent().addClass(errorClass).removeClass(validClass);
            }
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass(errorClass).addClass(validClass);
            // Check if element is radio
            if ($(element).attr("type") === "radio") {
                $(element).parent().removeClass(errorClass).addClass(validClass);
            }
        },
        errorPlacement: function(error, element) {
            if ($(element).attr("type") === "radio") {
                console.log("errorPlacement arguments", arguments);
                error.appendTo(element.parent().parent());
            } else {
                error.appendTo(element.parent());
            }
        }
    };
    console.log("validationOptions",validationOptions);
    if (!$("#updateProfileForm").validate(validationOptions).form()) {
        // If form does NOT validate, return out of this function
        loading.release();
        return;
    }

    userService.updateProfile(data)
	.then(function(){
               
                bootoast.toast({
                    message: "Your profile has been successfully updated.",
                    timeout: false,
                    type: "success",
                    icon: "alert"
                });
            })
	
	.always(function(err){
        loading.release();
    });
})