const urlHelper = require('../helpers/urlHelper');
const loading = require("../helpers/loading");
const logger = require('../helpers/clientLogger');

$("#activateAgentUserComplete").on("click", function() {
    if (document.getElementById("activateAgentUserForm").reportValidity()) {
        if ($("#password1").val() !== $("#password2").val()) {
            bootoast.toast({
                message: "Passwords do not match!",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
            return;
        }
        loading.block();
        $.ajax({
            async: true,
            crossDomain: true,
            url: _microservices_ + "/users/ActivateAccount",
            method: "PUT",
            data: { 
                EmailValidationToken: urlHelper.getQueryVariable("t"),
                NewPassword: $("#password1").val()
            },
            timeout: "60000",
        }).done(function(data, textStatus, jqXHR){
            $("#result").addClass("alert alert-success");
            $("#result").text("Process complete. You can now login.");
            $("#activateAgentUserForm").remove();
        }).fail(function(jqXHR, textStatus, errorThrown){
            logger.error(errorThrown);
            bootoast.toast({
                message: "An error has occured while completing the process",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
        }).always(function() {
            loading.release();
        });
    }
});