const error = require('../services/errorHandling');
const microLayer = require('../services/microLayerService');
const logger = require('../helpers/clientLogger');
const moment = require('moment');

function getUserInfo() {
    return microLayer.execute(microLayer.httpMethods.GET, "me")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving user info.")
        });
}

function getUserApplications() {
    return microLayer.execute(microLayer.httpMethods.GET, "me/applicationsExt")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving applications.")
        });
}

function getPagesByTemplateVersionId(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "application/template/versions/" + id + "/pages")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving pages (tempid).")
        });
}

function getActionLog(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + id + "/actions")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving actions.")
        });
}

function getAttributeValuesExt(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + id + "/attributeValuesExt")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving application attribute values.")
        });
}

function getAttributeValuesBatch(batchId) {
    return microLayer.execute(microLayer.httpMethods.GET, "applications/batches/" + batchId + "/attributeValues")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            error.handleWithToast(err, "There was an error retrieving application attribute values.")
        });
}

function getAttributeValuesBatchFirstApp(batchId, firstAppId) {
    return microLayer.execute(microLayer.httpMethods.GET, "applications/batches/" + batchId + "/firstApplication/" + firstAppId + "/attributeValues")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            error.handleWithToast(err, "There was an error retrieving application attribute values.")
        });
}

function pdfPreview(id) {
    return microLayer.execute(null, null, null, {
        async: true,
        crossDomain: true,
        url: _microservices_ + "/applications/" + id + "/pdfPreview",
        method: "GET",
        timeout: "60000",
        xhrFields: {
            withCredentials: true,
            responseType: "blob"
        },
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: {}
    }).then(function (response) {
        return response
    }).fail(function (err) {
        logger.error(err);
        error.handleWithToast(err, "There was an error retrieving application preview PDF.")
    });
}

function getUnsubmittedApplicationByCourseId(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "courses/" + id + "/unsubmittedApplication")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving unsubmitted application.")
        });
}

function getCoursesByOrganisationIdWorkflowId(orgid, wfid) {
    ///public/courses/organisation/:orgid/workflow/:wfid
    return microLayer.execute(microLayer.httpMethods.GET, "public/courses/organisation/" + orgid + "/workflow/" + wfid)
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving courses (byorg).")
        });
}

function getApplicationsPerBatchLimitByCourseId(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "courses/" + id + "/applicationPerBatchLimit")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving applications/batch.")
        });
}


function getCourseByCourseId(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "courses/" + id)
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving course (id).")
        });
}

function getVenueByVenueId(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "courseVenues/" + id + "/ByVenueId")
    .then(function (response) {
        return response
    })
    .fail(function (err) {
        logger.error(err);
        error.handleWithToast(err, "There was an error retrieving course (id).")
    });
}

function getCourseVenuesByCourseId(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "courseVenues/" + id + "/byCourseId")
    .then(function (response) {
        return response
    })
    .fail(function (err) {
        logger.error(err);
        error.handleWithToast(err, "There was an error retrieving course (id).")
    });
}

function getAvailableBatchesByCourseId(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "courses/" + id + "/availableBatches")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error getting available batches.")
        });
}

function createBatch(data) {
    return microLayer.execute(microLayer.httpMethods.POST, "application/batches", data)
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            let msg;
            try {
                msg = err.responseJSON.Exception.originalError.info.message.split("ErrorMessage");
                msg = msg[1];
            } catch (error) {
                msg = ": There was an error creating batch";
            }
            msg += "<hr style='margin-top: 6px; margin-bottom: 6px;'>Don't forget to check your <a href='/dashboard'>Account</a> page, it will list any applications that have already been created."
            error.handleWithToast(err, "Error" + msg)
        });
}

function insertApplication(data) {
    return microLayer.execute(microLayer.httpMethods.POST, "applications", data)
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error inserting application.")
        });
}

function getBatchByUserIdApplicationId(userId, appId) {
    return microLayer.execute(microLayer.httpMethods.GET, "application/batches/" + userId + "/" + appId)
    .then(function (response) {
        return response
    })
    .fail(function (err) {
        logger.error(err);
        error.handleWithToast(err, "There was an error retrieving batch (uidappid).")
    });
}

function getUnsubmittedApplicationsInBatch(batchId)
{
    return microLayer.execute(microLayer.httpMethods.GET, "application/batches/" + batchId + "/unsubmittedApplications")
    .then(function (response) {
        return response
    })
    .fail(function (err) {
        logger.error(err);
        error.handleWithToast(err, "There was an error retrieving unsubmitted apps in batch.")
    });
}

function getBatchByApplicationId(appId) {
    return microLayer.execute(microLayer.httpMethods.GET, "application/batches/" + appId + "/GetByApplicationId")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving batch (appid).")
        });
}

function checkIfDepositIsPaid(appId) {
    return microLayer.execute(microLayer.httpMethods.GET, "application/batches/" + appId + "/CheckIfDepositIsPaid")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving batch (uidappid).")
        });
}

function getVersionAttributesByApplicationIdExt(ApplicationId)
{
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + ApplicationId + "/getVersionAttributes")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving version attributes by ApplicationId (ext).")
        });
}

function getVersionAttributeByAttributeInstanceId(applicationId, attributeInstanceId)
{
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + applicationId + "/getVersionAttribute/" + attributeInstanceId)
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving version attribute by AttributeInstanceId.")
        });
}

function getApplicationHtml(ApplicationId)
{
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + ApplicationId + "/applicationHtml")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving attributes html.")
        });
}

function getPages(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + id + "/pages")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving pages (id).")
        });
}

function saveAttributeValues(data) {
    return microLayer.execute(microLayer.httpMethods.POST, "applications/attributeValues", data)
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error saving attribute values.")
        });
}

function saveBatchAttributeValues(batchId, data) {
    return microLayer.execute(microLayer.httpMethods.POST, "applications/batches/" + batchId + "/saveAttributeValues", data)
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error saving attribute values.")
        });
}

function getApplication(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + id)
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving application (id).")
        });
}

function getVersionAttributes(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "application/template/versions/" + id + "/attributes")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving version attributes.")
        });
}

function getAttributeValues(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + id + "/attributeValues")
        .then(function (response) {
            return response
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving attribute values.")
        });
}

function assignApplicationToBatch(id, data) {
    return microLayer.execute(microLayer.httpMethods.PUT, "application/batches/" + id, data)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error assigning application to batch.")
        });        
}

function withdraw(id) {
    return microLayer.execute(microLayer.httpMethods.PUT, "applications/" + id + "/withdraw")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error withdrawing the application.")
        });         
}

function decline(id)
{
    return microLayer.execute(microLayer.httpMethods.PUT, "applications/" + id + "/decline")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error declining the offer.")
        });
}

function getCurrentStepByApplicationId(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + id + "/CurrentStep")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error getting current workflow step.")
        });        
}

function getStepWorkflowById(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "workflows/" + id)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error getting workflow step.")
        });        
}

function submitBatch(batchId) {
    return microLayer.execute(microLayer.httpMethods.POST, "applications/batches/" + batchId.toString() + "/submit")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error submitting the applications batch.")
        });           
}


function getIncompleteConditionalOfferCheckMessages(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + id + "/incompleteConditionalOfferCheckMessages")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving applications.")
        });
}

function getInterviewSlot(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + id + "/interviewSlot")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error getting the interview slot.")
        });        
}

function getUserByUserName(username) {
    return microLayer.execute(microLayer.httpMethods.PUT, "users/getByUserName", { UserName: username })
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error getting the user.")
        });        
}

function getUserByUserId(UserId) {
    return microLayer.execute(microLayer.httpMethods.GET, "users/"+UserId)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error getting the user.")
        });        
}


function createApplicantForAgentApplication(data) {
    return microLayer.execute(microLayer.httpMethods.POST, "users/createApplicantforAgentApplication", data)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            var message = "There was an error creating the applicant record.";
            if(err.responseJSON && err.responseJSON.Error)
            {
                message =err.responseJSON.Error?err.responseJSON.Error:message;
            }
            error.handleWithToast(err, message)
        });         
}

function GetAutofillData(data) {
    return microLayer.execute(microLayer.httpMethods.POST, "generators/ApplicationForm/Autofill", data)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error getting the autofill data.")
        });         
}

function getBatchByBatchId(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "application/batches/" + id)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);            
            error.handleWithToast(err, "There was an error retrieving courses from batch.")
        });
}

function GetCourseInfoByBatchId(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "courses/batch/" + id)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);            
            error.handleWithToast(err, "There was an error retrieving courses from batch.")
        });
}

function addCoursesToBatch(id, data) {
    return microLayer.execute(microLayer.httpMethods.POST, "application/batches/" + id + "/addAdditionalCourses", data)
        .then(function (response) {
            // If successful, return the supplied bacthId back to caller
            return id;
        })
        .fail(function (err) {
            logger.error(err);
            let msg;
            try {
                msg = err.responseJSON.Exception.originalError.info.message.split("ErrorMessage");
                msg = msg[1];
            } catch (error) {
                msg = ": There was an error adding courses to batch";
            }
            msg += "<hr style='margin-top: 6px; margin-bottom: 6px;'>Don't forget to check your <a href='/dashboard'>Account</a> page, it will list any applications that have already been created."
            error.handleWithToast(err, "Error" + msg)
        });
}

function updateTemplateVersionId(id) {
    return microLayer.execute(microLayer.httpMethods.PUT, "applications/" + id + "/updateTemplateVersionId")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error updating template version id.")
        });
}

function submitSecondaryApplication(id) {
    return microLayer.execute(microLayer.httpMethods.PUT, "applications/" + id + "/SubmitSecondaryApplication")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error submitting secondary application.")
        });
}

function acceptOffer(id) {
    return microLayer.execute(microLayer.httpMethods.PUT, "applications/" + id + "/AcceptOffer")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error accepting secondary application.")
        });
}

function getAssessorsDocuments(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + id + "/assessorsDocuments")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving .")
        });
}

function requestRecommendation(data) {
    return microLayer.execute(microLayer.httpMethods.POST, "recommendations", data)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error sending reference request.")
        });
}

function requestSupervision(data) {
    return microLayer.execute(microLayer.httpMethods.POST, "supervisionRequests", data)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error sending supervision request.")
        });
}

function getDepartmentSupervisors(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "departments/" + id + "/supervisors")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving supervisors for department.")
        });
}

function getRecommendationRequests(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + id + "/recommendationRequests")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving supervisors for department.")
        });
}

function cancelRecommendationRequest(id, data) {
    return microLayer.execute(microLayer.httpMethods.PATCH, "recommendations/" + id, data)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error cancelling the reference request.")
        });
}

function cancelSupervisionRequest(id, data)
{
    return microLayer.execute(microLayer.httpMethods.PATCH, "supervisionRequests/" + id, data)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error cancelling the supervision request.")
        });
}

function resendRecommendationEmail(id, data)
{
    return microLayer.execute(microLayer.httpMethods.PATCH, "recommendations/" + id, data)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error re-sending the reference request.")
        });
}

function resendSupervisionEmail(id, data) {
    return microLayer.execute(microLayer.httpMethods.PATCH, "supervisionRequests/" + id, data)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error re-sending the supervision request.")
        });
}

function getSupervisionRequests(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + id + "/supervisionRequests")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error retrieving supervisors for department.")
        });
}
function canSubmitApplication(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + id + "/CanSubmit")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            error.handleWithToast(err, "There was an error checking whether app can be submitted.")
        });
}

function getAllApplicationStatus()
{
    return microLayer.execute(microLayer.httpMethods.GET, "public/workflow/statuses")
    .then(function (response) {
        return response;
    })
    .fail(function (err) {
        logger.error(err);
        error.handleWithToast(err, "There was an error retrieving statuses.")
    });  
}

function getApplicantsDocuments(appId)
{
    return microLayer.execute(microLayer.httpMethods.GET, "applications/" + appId + "/applicantsDocuments")
    .then(function (response) {
        return response;
    })
    .fail(function (err) {
        logger.error(err);
        error.handleWithToast(err, "There was an error retrieving applicants documents.")
    });  
}

function deleteAppFromBatch(batchId, appId)
{
    return microLayer.execute(microLayer.httpMethods.DELETE, "applications/batches/" + batchId + "/applications/" + appId)
    .then(function (response) {
        return response;
    })
    .fail(function (err) {
        logger.error(err);
        error.handleWithToast(err, "There was an error deleting app (" + batchId + "->" + appId + ").")
    });  
}

function getBatchPaymentTokens(batchId)
{
    return microLayer.execute(microLayer.httpMethods.GET, `application/batches/${batchId}/paymentTokens`)
    .then(function (response) {
        return response;
    })
    .fail(function (err) {
        logger.error(err);
        error.handleWithToast(err, "There was an error retrieving payment tokens.")
    });  
}

function AgentRequestApplicationFeePayment(ApplicationId)
{
    return microLayer.execute(microLayer.httpMethods.PUT, `applications/${ApplicationId}/requestApplicantToPayApplicationFee`)
    .then(function (response) {
        return response;
    })
    .fail(function (err) {
        logger.error(err);
        error.handleWithToast(err, "There was an error requesting applicant to pay application fee.")
    });  
}

function AgentRequestDepositPayment(ApplicationId)
{
    return microLayer.execute(microLayer.httpMethods.PUT, `applications/${ApplicationId}/requestApplicantToPayDeposit`)
    .then(function (response) {
        return response;
    })
    .fail(function (err) {
        logger.error(err);
        error.handleWithToast(err, "There was an error requesting applicant to pay deposit.")
    });  
}

function isCourseOpen(openingDate, closingDate)
{
    let now = moment();
    openingDate = moment(openingDate ? openingDate.replace("Z", "") : null);
    closingDate = moment(closingDate ? closingDate.replace("Z", "") : null);

    // If an opening date is not defined or it is in the past, then the course is currently open
    if ((openingDate.isValid() && openingDate < now) || !openingDate.isValid()) {
        if (closingDate.isValid() && closingDate <= now) {
            return false;
        }
        return true;
    } 
    return false;
}

function setVenue(ApplicationId, data)
{
    return microLayer.execute(microLayer.httpMethods.PUT, `applications/${ApplicationId}/setVenue`, data)
    .then(function (response) {
        return response;
    })
    .fail(function (err) {
        logger.error(err);
    });  
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    getUserInfo: getUserInfo,
    getUserByUserName: getUserByUserName,
    getUserByUserId: getUserByUserId,
    createApplicantForAgentApplication: createApplicantForAgentApplication,
    getUserApplications: getUserApplications,
    getPagesByTemplateVersionId: getPagesByTemplateVersionId,
    getActionLog: getActionLog,
    getAttributeValuesExt: getAttributeValuesExt,
    getAttributeValuesBatch: getAttributeValuesBatch,
    getAttributeValuesBatchFirstApp: getAttributeValuesBatchFirstApp,
    pdfPreview: pdfPreview,
    getUnsubmittedApplicationByCourseId: getUnsubmittedApplicationByCourseId,
    getCoursesByOrganisationIdWorkflowId: getCoursesByOrganisationIdWorkflowId,
    getApplicationsPerBatchLimitByCourseId: getApplicationsPerBatchLimitByCourseId,
    getCourseByCourseId: getCourseByCourseId,
    getVenueByVenueId: getVenueByVenueId,
    getCourseVenuesByCourseId: getCourseVenuesByCourseId,
    getAvailableBatchesByCourseId: getAvailableBatchesByCourseId,
    createBatch: createBatch,
    insertApplication: insertApplication,
    getBatchByUserIdApplicationId: getBatchByUserIdApplicationId,
    getUnsubmittedApplicationsInBatch: getUnsubmittedApplicationsInBatch,
    getBatchByApplicationId: getBatchByApplicationId,
    checkIfDepositIsPaid: checkIfDepositIsPaid,
    getVersionAttributesByApplicationIdExt: getVersionAttributesByApplicationIdExt,
    getVersionAttributeByAttributeInstanceId: getVersionAttributeByAttributeInstanceId,
    getApplicationHtml: getApplicationHtml,
    getPages: getPages,
    saveAttributeValues: saveAttributeValues,
    saveBatchAttributeValues: saveBatchAttributeValues,
    getApplication: getApplication,
    getVersionAttributes: getVersionAttributes,
    getAttributeValues: getAttributeValues,
    assignApplicationToBatch: assignApplicationToBatch,
    withdraw: withdraw,
    decline: decline,
    getCurrentStepByApplicationId: getCurrentStepByApplicationId,
    getStepWorkflowById: getStepWorkflowById,
    submitBatch: submitBatch,
    getIncompleteConditionalOfferCheckMessages: getIncompleteConditionalOfferCheckMessages,
    getInterviewSlot: getInterviewSlot,
    GetAutofillData: GetAutofillData,
    GetCourseInfoByBatchId: GetCourseInfoByBatchId,
    getBatchByBatchId: getBatchByBatchId,
    addCoursesToBatch: addCoursesToBatch,
    updateTemplateVersionId: updateTemplateVersionId,
    submitSecondaryApplication: submitSecondaryApplication,
    acceptOffer: acceptOffer,
    getAssessorsDocuments: getAssessorsDocuments,
    requestRecommendation: requestRecommendation,
    requestSupervision: requestSupervision,
    getDepartmentSupervisors: getDepartmentSupervisors,
    getAllApplicationStatus: getAllApplicationStatus,
    getRecommendationRequests: getRecommendationRequests,
    cancelRecommendationRequest: cancelRecommendationRequest,
    cancelSupervisionRequest: cancelSupervisionRequest,
    resendRecommendationEmail: resendRecommendationEmail,
    resendSupervisionEmail: resendSupervisionEmail,
    getSupervisionRequests: getSupervisionRequests,
    canSubmitApplication: canSubmitApplication,
    getApplicantsDocuments: getApplicantsDocuments,
    deleteAppFromBatch: deleteAppFromBatch,
    getBatchPaymentTokens,
    AgentRequestApplicationFeePayment,
    AgentRequestDepositPayment,
    isCourseOpen,
    setVenue
}