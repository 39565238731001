const applicationDetailsService = require('../services/applicationDetailsService');
const applyService = require('../services/applyService');
const dateHelper = require('../helpers/dateHelper');

module.exports = {
    showCalendar: showCalendar,
    interviewShutdown: interviewShutdown,
    setCourseTitle: setCourseTitle,
    initializeYearMonth: initializeYearMonth,
    monthBack: monthBack,
    monthNext: monthNext,
    checkMonthBack: checkMonthBack,
    changeStartingTime: changeStartingTime,
    changeEndingTime: changeEndingTime,
    setCalendar: setCalendar,
    setCalendarDay: setCalendarDay,
    setAppointments: setAppointments,
    setRoomAttributesFilter: setRoomAttributesFilter,
    openRoomFilterModal: openRoomFilterModal,
    emptyCalendar: emptyCalendar,
    removeFilters: removeFilters,
    toggleWrapping: toggleWrapping,
    highlightDay: highlightDay,
    setInterviewSlotTable: setInterviewSlotTable,
    clearInterviewSlotTable: clearInterviewSlotTable,
    postBookingModal: postBookingModal,
    openSlotModal: openSlotModal
}

function showCalendar() {
    $('.calendar-table-row').show();
    $("#slot-book-button").show();
}

function interviewShutdown() {
    $('#flexview').remove();
    $('.calendar-table-row').remove();
    $("#slot-book-button").remove();
    $("#day-appointments p").html("Direct access to interviews is not allowed. Please use your Application interface to access interviews.");
}

function setCourseTitle(title) {
    $(".course-title").text(title);
}

function initializeYearMonth() {
    for (i = 2018; i < ViewData.year; i++) {
        $("#calendar-year-dd").append('<option value="' + i + '">' + i + '</option>');
    }
    for (i = ViewData.year; i < ViewData.year + 30; i++) {
        $("#calendar-year-dd").append('<option value="' + i + '">' + i + '</option>');
    }
    $("#calendar-year-dd").val(ViewData.year);
    $("#calendar-month-dd").val(ViewData.month);
    checkMonthBack();
}

function monthBack() {
    if (ViewData.year == 2018 && ViewData.month == 1) {
        $("#calendar-month-back").addClass("disabled");
    }

    if (ViewData.month == 0) {
        ViewData.year = ViewData.year - 1;
        ViewData.month = 12;
        if (ViewData.year == 2018) {
            $("#calendar-year-back").addClass("disabled");
        }
    }

    $("#calendar-year-dd").val(ViewData.year);
    $("#calendar-month-dd").val(ViewData.month);
}

function monthNext() {
    if ($("#calendar-month-back").hasClass("disabled")) {
        $("#calendar-month-back").removeClass("disabled")
    }

    if (ViewData.month == 13) {
        ViewData.year = ViewData.year + 1;
        ViewData.month = 1;
        if ($("#calendar-year-back").hasClass("disabled")) {
            $("#calendar-year-back").removeClass("disabled")
        }
    }

    $("#calendar-year-dd").val(ViewData.year);
    $("#calendar-month-dd").val(ViewData.month);
    // $("#day-appointments").html("<h4>Please click on a day to show more details</h4>");
}

function checkMonthBack() {
    if (ViewData.year == 2018 && ViewData.month == 1) {
        $("#calendar-month-back").addClass("disabled");
    } else {
        $("#calendar-month-back").removeClass("disabled")
    }
}

function changeStartingTime(select) {
    let endtime = parseInt($("#calendar-ending-time").val());
    if (ViewData.startTime > 0) {
        if (!$(select).hasClass("active-filter")) {
            $(select).addClass("active-filter");
        }
    } else {
        $(select).removeClass("active-filter");
    }
    let moved = false;
    if (endtime < 1 || endtime > 23) {
        moved = true;
    }
    $("#calendar-ending-time option").each(function (opt) {
        if (parseInt($("#calendar-ending-time").val()) > ViewData.startTime) {
            moved = true;
        }
        if (parseInt($(this).val()) <= ViewData.startTime && $(this).val() != -1) {
            $(this).prop('disabled', true);
        } else {
            $(this).prop('disabled', false);
            if ($(this).val() != -1) {
                if (!moved) {
                    $(this).prop('selected', true);
                    ViewData.endingTime = parseInt($(this).val());
                    moved = true;
                }
            }
        }
    });
}

function changeEndingTime(select) {
    if (ViewData.endingTime != 0 && ViewData.endingTime != 24) {
        $(select).addClass("active-filter");
    } else {
        $(select).removeClass("active-filter");
    }
}

function setCalendar(year, month) {
    month = month - 1;
    let firstDay = new Date(year, month, 1);
    let lastDay = new Date(year, month + 1, 0);

    let days = [];

    for (i = 1; i <= lastDay.getDate(); i++) {
        let day = new Date(year, month, i);
        days.push([day, getWeekNumber(day)]);
    }

    $("#calendar-table tbody").html('');

    let firstDayIndex = firstDay.getDay() - 1;
    let dayIndex = 0;
    if (firstDayIndex < 0) {
        firstDayIndex = 6;
    }

    let weeksToAdd = Math.ceil((firstDayIndex + days.length) / 7);
    for (i = 0; i < weeksToAdd; i++) {
        let weekRow = $('<tr class="calendar-week"><td data-date=""></td><td data-date=""></td><td data-date=""></td><td data-date=""></td><td data-date=""></td><td data-date=""></td><td data-date=""></td></tr>');
        $("#calendar-table tbody").append(weekRow);
    }

    $("#calendar-table td").each(function (i, dayCell) {
        if (i >= firstDayIndex && i < days.length + firstDayIndex) {
            $(this).html(days[dayIndex][0].getDate());
            $(this).addClass('calendar-day-cell');
            $(this).attr('data-date', formatDate(days[dayIndex][0]));
            dayIndex++;
        }
    });
}

function setCalendarDay(date, slotsCount) {
    let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
    $('td[data-date="' + date + '"]').addClass('available-slots-day');
    if (slotsCount.AvailableSlotsCount <= 0) {
        $('td[data-date="' + date + '"]').addClass('booked');
    }
}

function setAppointments(result) {
    let selectedDate = new Date(ViewData.date);
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    let humanReadableSelectedDate = selectedDate.toLocaleDateString(undefined, options);
    let Appointments = $('<div id="day-appointments-container"></div>');
    //Appointments.append('<h4 id="appointments-header">'+humanReadableSelectedDate+'</h4>')
    $('.appointments-date').text(humanReadableSelectedDate);
    $("#appointments-date").show();

    if (result && result.length > 0) {
        let dold = null;
        let AppointHour;
        let AppointCont;

        let uappoints = {};

        let flags = [], urooms = {}, l = result.length, i;
        for (i = 0; i < l; i++) {
            if (flags["Room " + result[i].RoomCode]) {
                continue;
            }
            flags["Room " + result[i].RoomCode] = true;
            urooms["Room " + result[i].RoomCode] = null;
        }
        let rooms = {};
        Object.keys(urooms).sort().forEach(function (key) {
            rooms[key] = urooms[key];
        });

        let tempd, tempt, temptend, tempdend;
        flags = [];
        for (i = 0; i < l; i++) {
            tempd = dateHelper.sql2js(result[i].StartDateTime);
            tempdend = dateHelper.sql2js(result[i].EndDateTime);
            tempt = formatTime(tempd);
            temptend = formatTime(tempdend);
            if (!flags[tempt + "-" + temptend]) {
                flags[tempt + "-" + temptend] = true;
                uappoints[tempt + "-" + temptend] = JSON.parse(JSON.stringify(rooms));
            }

            uappoints[tempt + "-" + temptend]["Room " + result[i].RoomCode] = result[i];
        }

        let appoints = {};
        Object.keys(uappoints).sort().forEach(function (key) {
            appoints[key] = uappoints[key];
        });

        for (i = 0; i < Object.keys(appoints).length; i++) {
            let time = Object.keys(appoints)[i];
            let o = appoints[time];
            AppointHour = $('<div class="appointments"></div>');
            AppointHour.append('<div class="appointments-timer">' + time + '</div>');
            AppointCont = $('<div class="appointments-container"></div>');

            for (j = 0; j < Object.keys(appoints[time]).length; j++) {
                let room = Object.keys(appoints[time])[j];
                if (appoints[time][room] == null) {
                    AppointCont.append('<div class="appointment-padding"><p>Room: ' + room + '</p><p>No info</p><p>No slots</p></div>');
                }
                else {
                    let availabilityClass = 'available';
                    let availabilityText = 'Available';
                    if (appoints[time][room].ApplicationId) {
                        availabilityClass = 'booked';
                        availabilityText = 'Booked';
                    } else if (!appoints[time][room].IsBookingEnabled) {
                        availabilityClass = 'booking-expired';
                        availabilityText = 'Booking expired';
                    }
                    var RoomAttributes;
                    if(appoints[time][room].RoomAttributes != null || appoints[time][room].RoomAttributes == "") 
                        RoomAttributes  = appoints[time][room].RoomAttributes;
                    else 
                        RoomAttributes = '';
                    AppointCont.append('<div class="appointment ' + availabilityClass + '" data-slot-id="' + appoints[time][room].SlotId + '"  ><p>' + appoints[time][room].RoomName + '</p><p class="room-attributes" title="' + RoomAttributes + '">' + RoomAttributes + '</p><p>' + availabilityText + '</p></div>');
                }
            }
            if (i + 1 == Object.keys(appoints).length) {
                AppointHour.append(AppointCont);
                Appointments.append(AppointHour);
                break;
            }
            if (time != Object.keys(appoints)[i + 1]) {
                AppointHour.append(AppointCont);
                Appointments.append(AppointHour);
            }

        }
        $("#day-appointments").html(Appointments);
    } else {
        $("#day-appointments").html('<p>No interview slots found. Please check your filter settings.</p>');
    }
}

function setRoomAttributesFilter(roomAttributes) {
    $('#room-attributes-filter-collection').html('');
    roomAttributes.forEach(function (roomAttribute, i) {
        let roomAttributesRow = $('<li class="collection-item"><div class="switch" style="display:inline-block;white-space:nowrap;"><label><input class="room-attribute" data-room-attribute-id="' + roomAttribute.RoomAttributeId + '" type="checkbox"> <span class="lever"></span>' + roomAttribute.Name + '</label></div></li>');
        $('#room-attributes-filter-collection').append(roomAttributesRow);
    })
}

function openRoomFilterModal() {
    $('#room-attributes-filter-modal').modal('open');
}

function emptyCalendar() {
    $(".calendar-day:not(disabled) .appt-day").remove();
}

function removeFilters() {
    ViewData.roomAttributes = '';
    $('#room-attributes-filter-collection .room-attribute').each(function (i, roomAttribute) {
        $(this).prop('checked', false);
    });
    $('#room-attributes-filter').removeClass('green');
    $('#room-attributes-filter').addClass('blue');
    $("#calendar-starting-time").val("0");
    $("#calendar-starting-time").removeClass("active-filter");
    $("#calendar-ending-time").removeClass("active-filter");
    $("#calendar-ending-time option").each(function (opt) {
        $(this).prop('disabled', false);
    });
    $("#calendar-ending-time").val("-1");
}

function toggleWrapping() {
    $("#day-appointments").toggleClass("nowrap");
    if (ViewData.wrap) {
        ViewData.wrap = false;
        $("#calendar-wrap").html('Wrap<i class="material-icons right">check_box_outline_blank</i>');
    } else {
        ViewData.wrap = true;
        $("#calendar-wrap").html('Wrap<i class="material-icons right">check_box</i>');
    }
}

function highlightDay() {
    $(".calendar-day.picked").removeClass("picked");
    $('[data-date="' + ViewData.date + '"]').addClass("picked");
}

function setInterviewSlotTable(slot) {
    let startDateTime = dateHelper.sql2js(slot.StartDateTime);
    let endDateTime = dateHelper.sql2js(slot.EndDateTime);
    let bookingEnabledUntil = dateHelper.sql2js(slot.BookingUntil);
    let bookingCancellationEnabledUntil = dateHelper.sql2js(slot.CancelBookingUntil);
    let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    let timeOptions = { hour: 'numeric', minute: 'numeric' };
    let humanReadableStartDateTime = startDateTime.toLocaleDateString('en-IE', options);
    let humanReadableEndDateTime = endDateTime.toLocaleTimeString('en-IE', timeOptions);
    let displayMessageStartDateTime = startDateTime.toString().substring(0,21);
    let displayMessageEndDateTime = endDateTime.toString().substring(16,21);
    let humanReadableBookingEnabledUntil = bookingEnabledUntil.toLocaleTimeString('en-IE', options);
    let humanReadableBookingCancellationEnabledUntil = bookingCancellationEnabledUntil.toLocaleDateString('en-IE', options);

    $(".after-booking-buttons").hide();
    $(".just-booked-notification").hide();

    if (slot.ApplicationId) {
        $('.applicant-info').show();
        if(slot.ApplicantFirstName && slot.ApplicantLastName)
        {
            $('#slot-applicant-name').html(slot.ApplicantFirstName + ' ' + slot.ApplicantLastName);
            $('#slot-details-pane .applicant-info').show();
        }
        else
        {
            $('#slot-applicant-name').html("");
            $('#slot-details-pane .applicant-info').hide();
        }
        $("#slot-book-button").hide();
        $(".already-booked-notification").show();
        $("#slot-remove-button").hide();
        if (slot.IsCancelBookingEnabled) {
            $('#cancel-booking-button').show();
        } else {
            $('#cancel-booking-button').hide();
        }
    } else {
        $("#slot-remove-button").show();
        $('.applicant-info').hide();
        if (slot.IsBookingEnabled) {
            $("#slot-book-button").show();
        } else {
            $("#slot-book-button").hide();
        }
        $(".already-booked-notification").hide();
        $("#slot-book-button").attr("data-slot-id", slot.SlotId);
        $("#slot-book-button").data("slot-id", slot.SlotId);
        $("#slot-undo-booking-button").attr("data-slot-id", slot.SlotId);
        $("#slot-undo-booking-button").data("slot-id", slot.SlotId);
        $("#slot-remove-button").attr("data-slot-id", slot.SlotId);
        $("#slot-remove-button").data("slot-id", slot.SlotId);
        $('#cancel-booking-button').hide();
    }
    $("#cancel-booking-button").data("slot-id", slot.SlotId);
    $('#slot-room-code').html(slot.RoomCode);
    $('#slot-room-name').html(slot.RoomName);
    $('#slot-room-description').html(slot.RoomDescription);
    $('#slot-room-attributes').html(slot.RoomAttributes?slot.RoomAttributes:"");

    $('#slot-booking-enabled-until').html(humanReadableBookingEnabledUntil);
    $('#slot-booking-cancellation-enabled-until').html(humanReadableBookingCancellationEnabledUntil);

    $('#slot-interview-date').html(displayMessageStartDateTime + ' - ' + displayMessageEndDateTime);
    $('#slot-interview-duration').html(slot.InterviewDuration + ' minutes');

}

function clearInterviewSlotTable() {
    $('#applicant-name-td').html('');
    $('#room-code-td').html('');
    $('#room-name-td').html('');
    $('#room-attributes-td').html('');
    $('#room-description-td').html('');
    $('#interview-date-td').html('');
    $('#interview-duration-td').html('');
}

function postBookingModal() {
    applyService.getApplication(ViewData.ApplicationId).then(function(application) {
        switch (application.CurrentActorId) {
            case 1: // Assessor
                $("#slot-remove-button").hide();
                $("#slot-book-button").hide();
                $(".just-booked-notification").slideDown(200);
                $(".after-booking-buttons").slideDown(200);
                $(".applicant-dashboard-link").attr("href", "/application/" + ViewData.ApplicationId);
                break;
            case 2: // Applicant
                applicationDetailsService.sendApplicationToAssessor(ViewData.ApplicationId, {message: "Application returned by applicant after booking interview"}).then(function () {
                    $("#slot-remove-button").hide();
                    $("#slot-book-button").hide();
                    $(".just-booked-notification").slideDown(200);
                    $(".after-booking-buttons").slideDown(200);
                    $(".applicant-dashboard-link").attr("href", "/application/" + ViewData.ApplicationId);
                });
                break;
            default:
                throw new Error("Invalid actor");
        }
    });
}

function openSlotModal() {
    $("body").append('<a id="modalButton" class="btn btn-primary btn-block actionButton" href="#bookSlotModal" role="button" data-toggle="modal"></a>');
    $("#modalButton").trigger("click");
    $("#modalButton").remove();
}


function formatDate(datef) {
    return datef.getFullYear() + '-' + ('0' + (datef.getMonth() + 1)).slice(-2) + '-' + ('0' + datef.getDate()).slice(-2);
}
function formatTime(datef, addh = 0) {
    return ('0' + (datef.getHours() + addh)).slice(-2) + ":" + ('0' + (datef.getMinutes())).slice(-2);
}
function getWeekNumber(date) {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    let yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    let weekNo = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);
    return weekNo;
}