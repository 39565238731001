const moment = require('moment');
const urlHelper = require('../helpers/urlHelper');

/****************************************************************
 *                          EXPORTED FUNCTIONS
 ****************************************************************/

/**
 * Attaches the realex response to the web page
 */
function buildTable(params, transaction, batch, app, org) {
    console.log("params",params);
    console.log("transaction",transaction);
    console.log("batch",batch);
    $("#summaryTable").html(`
        <div class="panel panel-default">
            <div class="panel-body">
                Dear ${batch[0].DisplayName},<br>
                These are the details of your transaction.<br><br>
                <table class="table table-bordered table-striped">
                    <tr><th>Receipt number</th><td>...</td></tr>
                    <tr><th>Payment received by</th><td> Postgraduate Applications Centre CLG., 5 Mary Street, Galway, Ireland</td></tr>
                    <tr><th>Amount</th><td>${transaction.Amount}</td></tr>
                    <tr><th>Payment received from</th><td>${batch[0].DisplayName}</td></tr>
                    <tr><th>Application number</th><td>${app.ApplicationNumber}</td></tr>
                    <tr><th>Transaction number</th><td>${transaction.GatewayOrderId}</td></tr>
                    <tr><th>Date of payment</th><td>${ moment(transaction.StartDateTime ? transaction.StartDateTime.replace("Z", "") : null).format("DD/MM/YYYY HH:mm") }</td></tr>
                    <tr><th>Institution applied to</th><td>${org.Name}</td></tr>
                    <tr><th>Programme(s) applied for</th><td>${ batch.map(function(b){return b.ApplicationName}).join("<br>") }</td></tr>
                </table>
                Regards,<br>
                PAC.<br>
                <i>Postgraduate Applications Centre CLG. - Registered in Ireland Number 291366 - Registered Address : 5 Mary Street, Galway, Ireland</i>
            </div>
        </div>
    `);
}

function generateAppLink(applicationId) {
    $(".summary-application-link").html('<a href="/application/'+applicationId+'" class="btn btn-primary btn-lg">Go to the application</a>')
}

function generateGenericLink(transaction) {
    $(".summary-application-link").html('<a href="/application/'+transaction.ApplicationId+'" class="btn btn-primary btn-lg">Go to the application</a>')
}

function generateFallbackLink(batchId = null) {
    // $(".summary-application-link").html('<a href="/dashboard" class="btn btn-secondary btn-sm">Go to your applications</a>')
    if(batchId != null)
    {
        $(".error-application-link").html('<a href="apply?b='+batchId+'" class="btn btn-primary btn-lg">Return to application form</a>')
        $(".error-application-link").removeClass("hide");
    }   
}

function showErrorBox(msg, batchId = null) {
    $("#errorBox").text(msg);
    $("#errorBox").show();
    generateFallbackLink(batchId);
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    buildTable: buildTable,
    generateAppLink: generateAppLink,
    generateGenericLink: generateGenericLink,
    generateFallbackLink: generateFallbackLink,
    showErrorBox: showErrorBox
}