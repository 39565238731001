const moment = require('moment');
const applyService = require('../services/applyService');
const validActionTypes = require("../helpers/validActionTypes");

function getIdsForHiddenUnsubmittedApplications(applications)
{
    let unsubmittedAppIds = [];
    let batchids = [];
    let hideAppIds = [];

    for(i=0;i<applications.length;i++)
    {
        if(!batchids[applications[i].BatchId]) batchids[applications[i].BatchId] = []; //create batch array for specific batch

        if(applications[i].StatusId == 17) //if unsubmitted
        {
            unsubmittedAppIds.push(applications[i].ApplicationId); //push it to the unsubmitted array
        }
        batchids[applications[i].BatchId].push(applications[i].ApplicationId) //push appId to it's batch
    }

    batchids.forEach(function(e,i){
        var counter = 0; 
        e.forEach(function(appid){
            if(unsubmittedAppIds.indexOf(appid)>-1)
                counter++; //count all unsubmitted apps in batch
        })
        if(counter == e.length) //if all are unsubmitted in the batch
        {
            e.sort((a, b) => a - b) //sort by appId
            for(i=1;i<e.length;i++) //skip first as we want to display the first app created (lowest appId)
            {
                hideAppIds.push(e[i]); // push to array for hidden apps
            }
        }
    })

    return hideAppIds; //return appIds of apps that should be hidden
}

/****************************************************************
 *                          EXPORTED FUNCTIONS
 ****************************************************************/

/**
 * Attaches all the V1 applications to the page DOM
 * 
 * @param {array} applications - the applications returned from the API
 */
function populateV1Applications(applications) {
    $("#v1appsContainer").removeClass("hidden");
    applications.forEach(function(app) {
        $("#v1applications").append(`
            <div class="list-group-item">
                <h4>
                    <strong>(${app.CourseCode}) ${app.Name}</strong>
                    <small>
                        ${app.InstituteName} - ${app.CourseType}
                    </small>
                </h4>
                <strong>Application Number:</strong> ${app.Appno}
                <br>
                <strong>Academic Year:</strong> ${app.AcademicYear}
            </div>
        `);
    });
}

/**
 * Attaches all the applications to the page DOM
 * 
 * @param {array} applications - the applications returned from the API
 * @param {object} filter - an object of key-value pairs used for filtering the applications
 */
function populateApplications(applications, filter = {}) {
    // TODO: Remove logs
    console.log("applications",applications);
    console.log("filter",filter);

    let hideAppIds = getIdsForHiddenUnsubmittedApplications(applications); //get apps that should be hidden on dashboard

    applications.sort(function(a, b) {
        if (a.ActionLogTimestamp === null || a.ActionLogTimestamp.length == 0) return 1;
        if (b.ActionLogTimestamp === null || b.ActionLogTimestamp.length == 0) return -1;
        return (new Date(a.ActionLogTimestamp) > new Date(b.ActionLogTimestamp) ? -1 : (new Date(a.ActionLogTimestamp) < new Date(b.ActionLogTimestamp) ? 1 : 0));
    });

    let html = "";
    let filterKeys = Object.keys(filter);
    let filteredCount = 0;
    let hiddenCount = 0;

    applications.forEach(application => {
        if (hideAppIds.indexOf(application.ApplicationId) > -1) {
            hiddenCount++;
            return; //skip apps that should be hidden
        }

        // Filter options
        for (let index = 0; index < filterKeys.length; index++) {
            const key = filterKeys[index];
            switch (key) {
                case "Name":
                    if (filter[key] && filter[key].length > 0) {
                        if (
                            !application.FirstName.includes(filter[key]) &&
                            !application.LastName.includes(filter[key]) &&
                            !application.UserName.includes(filter[key])
                        ) {
                            return;
                        }
                    }
                    break;
                case "OrganisationId":
                    if (filter[key] && parseInt(filter[key]) > 0) {
                        if (application.OrganisationId !== parseInt(filter[key])) {
                            return;
                        }
                    }
                    break;
                case "Status":
                    if (filter[key] && parseInt(filter[key]) > 0) {
                        if (application.StatusId !== parseInt(filter[key])) {
                            return;
                        }
                    }
                    break;
                case "AcademicYear":
                    if (filter[key] && parseInt(filter[key]) > 0) {
                        if (application.AcademicYear !== parseInt(filter[key])) {
                            return;
                        }
                    }
                    break;
                case "ApplicationNumber":
                    if (filter[key] && filter[key].length > 0) {
                        if (application.ApplicationNumber === null || !application.ApplicationNumber.includes(filter[key])) {
                            return;
                        }
                    }
                    break;
                case "createFrom":
                    if (filter[key] && filter[key].length > 0) {
                        if (!moment(application.CreationDate).isBetween(filter[key], filter["createTo"])) {
                            return;
                        }
                    }
                    break;
                case "actionFrom":
                    if (filter[key] && filter[key].length > 0) {
                        if (!moment(application.ActionLogTimestamp).isBetween(filter[key], filter["actionTo"])) {
                            return;
                        }
                    }
                    break;
                case "createTo":
                    if (filter[key] && filter[key].length > 0) {
                        // Only do this if the matching 'from' field is empty, else use range above
                        if (filter["createFrom"] && filter["createFrom"].length === 0) {
                            if (!moment(application.CreationDate).isBefore(filter[key])) {
                                return;
                            }
                        }
                    }
                    break;
                case "actionTo":
                    if (filter[key] && filter[key].length > 0) {
                        // Only do this if the matching 'from' field is empty, else use range above
                        if (filter["actionFrom"] && filter["actionFrom"].length === 0) {
                            if (!moment(application.ActionLogTimestamp).isBefore(filter[key])) {
                                return;
                            }
                        }
                    }
                    break;
            }
        }
        let lastActionDate = "";
        let agentAppBlock = "";

        if (localStorage.getItem("UserTypeId") === "2") {
            agentAppBlock = `
                <div class="col-xs-12 col-sm-6 col-lg-4">
                    <div class="row">
                        <div class="col-xs-6"><div class="th">Applicant</div></div>
                        <div class="col-xs-6"><div class="td">${application.FirstName} ${application.LastName}</div></div>
                    </div>
                </div>
            `;
        }

        if (application.ActionLogTimestamp && application.ActionLogTimestamp.length > 0) {
            lastActionDate = moment(application.ActionLogTimestamp ? application.ActionLogTimestamp.replace("Z", "") : null).format("DD/MM/YYYY - HH:mm");
        }

        let actionRequiredMessage = "";
        if (application.CurrentActorId == 2 && validActionTypes.includes(application.ActionTypeId)) {
            actionRequiredMessage = `
                <div class="application-notice small">
                    Action required. Please open the application for more information.
                </div>
            `;
        }

        let applicationDeleteButton = "";
        if (application.StatusId === 17) {
            applicationDeleteButton = `<a class="btn btn-block btn-danger applicationDeleteButton" href="#" data-ApplicationId="${application.ApplicationId}" data-BatchId="${application.BatchId}">Delete</a>`;
        }

        html += `
            <div class="pac-dash-panel ${application.AgentUserId && localStorage.getItem("UserTypeId") === "1" ? "agent-application" : ""}">
                <div class="panel panel-default">
                    <div class="panel-heading" id="app${application.ApplicationId}">
                        <div class="panel-title">
                            <div class="row">
                                <div class="col-xs-12 col-sm-2 col-md-2 bg-warning text-center pac-dash-status">
                                    <div class="h4"><strong>${application.StatusName}</strong></div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6">
                                    <h4>
                                        <a class="appBtn" id="appBtn${application.ApplicationId}" href="/application/${application.ApplicationId}">
                                            ${application.Name}
                                        </a>
                                        <br>
                                        <strong class="small">${application.InstituteName}</strong>
                                    </h4>
                                </div>
                                <div class="col-xs-12 col-sm-2">
                                    ${applicationDeleteButton}
                                </div>
                                <div class="col-xs-12 col-sm-2">
                                    <a class="btn btn-block btn-default" href="/application/${application.ApplicationId}">View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    ${actionRequiredMessage}
                    <div class="pac-dash-item-table small">
                        <div class="row">
                            ${agentAppBlock}
                            <div class="col-xs-12 col-sm-6 col-lg-4">
                                <div class="row">
                                    <div class="col-xs-6"><div class="th">Application Number</div></div>
                                    <div class="col-xs-6"><div class="td">${application.ApplicationNumber == null ? "--" : application.ApplicationNumber}</div></div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-lg-4">
                                <div class="row">
                                    <div class="col-xs-6"><div class="th">Academic Year</div></div>
                                    <div class="col-xs-6"><div class="td">${application.AcademicYear == null ? "--" : application.AcademicYear}</div></div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-lg-4">
                                <div class="row">
                                    <div class="col-xs-6"><div class="th">Starting Date</div></div>
                                    <div class="col-xs-6"><div class="td">${application.CourseStartDate == null ? "--" : application.CourseStartDate}</div></div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-lg-4">
                                <div class="row">
                                    <div class="col-xs-6"><div class="th">Created</div></div>
                                    <div class="col-xs-6"><div class="td">${ moment(application.CreationDate ? application.CreationDate.replace("Z", "") : null).format("DD/MM/YYYY - HH:mm")}</div></div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-lg-4">
                                <div class="row">
                                    <div class="col-xs-6"><div class="th">Last Action</div></div>
                                    <div class="col-xs-6"><div class="td">${lastActionDate == null ? "--" : lastActionDate}</div></div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-lg-4">
                                <div class="row">
                                    <div class="col-xs-6"><div class="th">Batch</div></div>
                                    <div class="col-xs-6"><div class="td">${application.BatchId}</div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
        filteredCount++;
    });
    $("#applications").html(html);
    $("#filteredCountTxt").html(`
        <div>
            <div class="label label-default">
                ${filteredCount}
            </div>
            of
            <div class="label label-default">
                ${applications.length - hiddenCount}
            </div>
        </div>
    `);
}

function getFilters() {
    return {
        Name: $("#appnameFilterBox").val(),
        OrganisationId: $("#orgFilterSelect").val(),
        Status: $("#statusFilterSelect").val(),
        AcademicYear: $("#yearFilterSelect").val(),
        ApplicationNumber: $("#appnoFilterBox").val(),
        createFrom: $("#createFrom").val(),
        createTo: $("#createTo").val(),
        actionFrom: $("#actionFrom").val(),
        actionTo: $("#actionTo").val()
    };
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    populateApplications: populateApplications,
    populateV1Applications: populateV1Applications,
    getFilters: getFilters
}