

function toggleAgentApplicantDetails() {
    if ($("#isAgent").prop("checked")) {
        $("#agentApplicantDetails").show();
        $("#euBlock").hide();
        $("#firstNameBlock").hide();
        $("#lastNameBlock").hide();
        $("#dobBlock").hide();
    } else {
        $("#agentApplicantDetails").hide();
        $("#euBlock").show();
        $("#firstNameBlock").show();
        $("#lastNameBlock").show();
        $("#dobBlock").show();
    }
}

function populateCountriesSelect(countries){
    let select = $("#countryid");
    countries.forEach(function(country) {
        $(select).append(`<option value="${country.CountryId}">${country.Name}</option>`)
    });
}

module.exports = {
    toggleAgentApplicantDetails: toggleAgentApplicantDetails,
    populateCountriesSelect: populateCountriesSelect
}