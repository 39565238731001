const userService = require('../services/userService');
const registerBuilder = require('../builders/registerBuilder');
const loading = require("../helpers/loading");
const logger = require('../helpers/clientLogger');

/*****************************************************************
 *                          EVENTS
 ****************************************************************/

$("#submitRegistrationFormBtn").on("click", function (e) {
    e.preventDefault();
    loading.block();

    let userData = {
        UserTypeId: 1,
        UserName: $("#email").val().trim(),
        Password: $("#pass").val().trim(),
        FirstName: $("#firstname").val().trim(),
        LastName: $("#lastname").val().trim(),
        DisplayName: $("#firstname").val().trim() + " " + $("#lastname").val().trim(),
        AddressLine1: $("#address1").val().trim(),
        AddressLine2: $("#address2").val().trim(),
        City: $("#city").val().trim(),
        PostalCode: $("#postalcode").val().trim(),
        CountryId: $("#countryid").val(),
        PhoneNumber: $("#phonenumber").val().trim(),
        DateOfBirth: $("#dateofbirth").val().trim(),
        EUCitizen: $("input:radio[name ='euCitizen']:checked").val() === "na" ? null : $("input:radio[name ='euCitizen']:checked").val(),
        Enabled: 0
    }

    if ($("#isAgent").prop("checked")) {
        userData.UserTypeId = 2;
        userData.AgentName = $("#agentName").val();
        userData.CompanyName = $("#agentCompany").val();
        userData.DisplayName = $("#agentName").val().trim() + " " + $("#agentCompany").val().trim(),
        userData.FirstName = $("#agentName").val();
        userData.LastName = $("#agentCompany").val();
        userData.EUCitizen = false;
    }

    let validationOptions = {
        ignore: false,
        rules: {
            firstname: {
                required: {
                    depends: function(element) {
                        return $("#isAgent").prop("checked") ? false : true;
                    }
                }
            },
            lastname: {
                required: {
                    depends: function(element) {
                        return $("#isAgent").prop("checked") ? false : true;
                    }
                }
            },
            address1: {
                required: true,
                maxlength: 50
            },
            address2: {
                required: false,
                maxlength: 50
            },
            city: {
                required: true,
                maxlength: 40
            },
            postalcode: {
                required: true,
                maxlength: 16
            },
            countryid: {
                required: true
            },
            phonenumber: {
                required: true
            },
            dateofbirth: {
                required: {
                    depends: function(element) {
                        return $("#isAgent").prop("checked") ? false : true;
                    }
                }
            },
            email: {
                required: true,
                email: true
            },
            emailconfirm: {
                required: true,
                email: true,
                equalTo: "#email"
            },
            pass: {
                required: true
            },
            passwordconfirm: {
                required: true,
                equalTo: "#pass"
            },
            isAgent: {
                required: false
            },
            euCitizen: {
                tristate: {
                    depends: function(element) {
                        return $("#isAgent").prop("checked") ? false : true;
                    }
                }
            },
            agentName: {
                required: {
                    depends: function(element) {
                        return $("#isAgent").prop("checked");
                    }
                }
            },
            agentCompany: {
                required: {
                    depends: function(element) {
                        return $("#isAgent").prop("checked");
                    }
                }
            },
            privacy: {
                required: true
            },
            gdpr: {
                required: true
            }
        },
        highlight: function(element, errorClass, validClass) {
            $(element).addClass(errorClass).removeClass(validClass);
            // Check if element is radio and apply error class to parent div
            if ($(element).attr("type") === "radio") {
                $(element).parent().addClass(errorClass).removeClass(validClass);
            }
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass(errorClass).addClass(validClass);
            // Check if element is radio
            if ($(element).attr("type") === "radio") {
                $(element).parent().removeClass(errorClass).addClass(validClass);
            }
        },
        errorPlacement: function(error, element) {
            if ($(element).attr("type") === "radio") {
                console.log("errorPlacement arguments", arguments);
                error.appendTo(element.parent().parent());
            } else {
                error.appendTo(element.parent());
            }
        }
    };
    console.log("validationOptions",validationOptions);
    if (!$("#registrationForm").validate(validationOptions).form()) {
        // If form does NOT validate, return out of this function
        loading.release();
        return;
    }

    userService.createUser(userData).then(function(response) {
        window.location.href = "/register-confirm";
    }).always(function() {
        loading.release();
    });
});

$("#isAgent").on("change", function () {
    registerBuilder.toggleAgentApplicantDetails();
});
