import "../events/instituteEvents";

const instituteBuilder = require('../builders/instituteBuilder');
const instituteService = require('../services/instituteService');
const coursesService = require('../services/coursesService');
const userService = require('../services/userService');
const urlHelper = require("../helpers/urlHelper");
const loading = require("../helpers/loading");
const pageSettingsHelper = require("../helpers/pageSettingsHelper");

$('[data-toggle="tooltip"]').tooltip();

instituteBuilder.displayMeta();
instituteBuilder.buildAdvancedSearchBox();

let param = Number.parseInt(urlHelper.getUrlParam(2));
if (pageSettingsHelper.isPMEOrganisation(param) || pageSettingsHelper.isParentInstitute(param)) {
    $("#findacourse").remove();
}
if (pageSettingsHelper.isParentInstitute(param)) {
    $("#chooseInstitute").show();
    instituteBuilder.buildSubInstituteBox(param);
}
else {
    $("#chooseInstitute").remove();
}

$('#infoModal').on('show.bs.modal', function(e) {
    let btn = $(e.relatedTarget);
    let info = btn.data('info');
    let title = btn.data('title');
    let modal = $(this);
    modal.find('#info-title').text(title);
    modal.find('#info-body').html(info);
});

$('#noticeModal').on('show.bs.modal', function(e) {
    let btn = $(e.relatedTarget);
    let notice = btn.data('notice');
    let title = btn.data('title');
    let modal = $(this);
    modal.find('#notice-title').text(title);
    modal.find('#notice-body').html(notice);
});

// Check for a CourseId, if there is one then we came here via a hidden course link
let courseId = parseInt(sessionStorage.getItem("courseId"));
if (!isNaN(courseId)) {
    coursesService.getCourse(courseId).then(function(course) {
        let studyModes = {};
        instituteService.getAllStudyModes().then(function(modes) {
            modes.forEach(function(mode) {
                if (mode.OrganisationId === course.OrganisationId) {
                    studyModes[mode.Id] = mode.Name;
                }
            });
            // Check if a user is logged in (to get eu status)
            if (localStorage.getItem("SessionToken") === null) {
                instituteBuilder.populateSearchResults([course], null, studyModes);
                loading.release();
            } else {
                userService.me().then(function(me) {
                    instituteBuilder.populateSearchResults([course], me, studyModes);
                }).fail(function(err) {
                    logger.error(err);
                    bootoast.toast({
                        message: "An unknown error has occurred",
                        timeout: false,
                        type: "danger",
                        icon: "alert"
                    });
                }).always(function(e){
                    loading.release();
                });
            }
        }).fail(function(err) {
            logger.error(err);
            loading.release();
            bootoast.toast({
                message: "An unknown error has occurred",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
        });
    }).fail(function(err) {
        logger.error(err);
        loading.release();
        bootoast.toast({
            message: "An unknown error has occurred",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    });
}