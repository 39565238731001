const applyBuilder = require("../builders/applyBuilder");
const applyService = require('../services/applyService');
const userService = require('../services/userService');
const paymentService = require('../services/paymentService');
const fileService = require('../services/fileService');
const fileContainerService = require('../services/fileContainerService');
const applicationDetailsService = require("../services/applicationDetailsService");
const registerService = require("../services/registerService");
const urlHelper = require('../helpers/urlHelper');
const pageSettingsHelper = require("../helpers/pageSettingsHelper");
const loading = require("../helpers/loading");
const logger = require('../helpers/clientLogger');

/****************************************************************
 *                          EVENTS
 ****************************************************************/
 let courseStr = localStorage.getItem("courseStr");

$("#applicationFormSaveProgressBtn").on("click", function (e) {
    e.preventDefault();
    loading.block();
    saveProgress().catch(function (err) {
        logger.error(err);
        bootoast.toast({
            message: "An unknown error has occurred",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    }).finally(function(e){
        loading.release();
    });
});

$(document).on("click", ".applicationReturnFormBtn", function (e) {
    e.preventDefault();
    let formIndex = $(this).data("formindex");
    let appId = Number.parseInt($(`#applicationForm${formIndex}`).data("ApplicationId"));
    if (isNaN(appId)) {
        throw new Error("Invalid app ID");
    }
    loading.block();
    saveProgress().then(function(response) {
        if (window.confirm("Please note:\nThis will only return the invalidated questions from this specific course.\nIt will NOT submit any unsubmitted applications.")) {
            applicationDetailsService.sendApplicationToAssessor(appId, { message: "Application returned by applicant" }).then(function () {
                location.href = "/application/" + appId;
            }).catch(function (err) {
                logger.error(err);
            }).always(function(e){
                loading.release();
            });
        } else {
            loading.release();
        }
    }).catch(function (err) {
        logger.error(err);
        loading.release();
    });
});

$(document.body).on("click", ".pac-app-tab-close-btn", function(e) {
    e.preventDefault();
    if ($("form.apply_from .formContainer").hasClass("dirty")) {
        $("#batchDirtyModal").data("action", "delete");
        $("#batchDirtyModalAction").text("Delete");
        $("#batchDirtyModalBody").html(`
            <p>
                To delete a course from the application, <strong>current changes to the application form(s) must be saved</strong>.
            </p>
            <p>
                Do you want to <strong>save the current changes</strong> to the application form(s) and <strong>add a new course(s) to the application</strong>?
            </p>
        `);
        $("#batchAppDeleteModalDeleteBtn").data("app-id", $(this).data("app-id"));
        $("#batchDirtyModal").modal("show");
    } else {
        $("#batchAppDeleteModalDeleteBtn").data("app-id", $(this).data("app-id"));
        $("#batchAppDeleteModal").modal("show");
    }
});

$(document.body).on("click", "#batchAppDeleteModalDeleteBtn", function(e) {
    e.preventDefault();
    let batchId = urlHelper.getQueryVariable("b");
    let appId = $(this).data("app-id");
    applyService.deleteAppFromBatch(batchId, appId).then(function() {
        $("#batchAppDeleteModal").modal("hide");
        applyBuilder.batchForm(batchId);
    });
});

$(document.body).on('click', "#applicationFormSubmitBtn_bottom", function(e){
    $("#applicationFormSubmitBtn").click();
})

$(document.body).on("click", "#applicationFormSubmitBtn", function (e) {
    e.preventDefault();
    loading.block();

    // Check if submit button is for batch or invalidated attributes
    let formIndex = $("#applicationFormSubmitBtn").data("formindex");
    if (typeof formIndex !== "undefined") { // Invalidated attributes
        let appId = Number.parseInt($(`#applicationForm${formIndex}`).data("ApplicationId"));
        if (isNaN(appId)) {
            throw new Error("Invalid app ID");
        }
        saveProgress().then(function(response) {
            isFormValid().then(function() {
                if (window.confirm("Please note:\nThis will only return the invalidated questions from this specific course.\nIt will NOT submit any unsubmitted applications.")) {
                    applicationDetailsService.sendApplicationToAssessor(appId, { message: "Application returned by applicant" }).then(function() {
                        location.href = "/application/" + appId;
                    }).catch(function(err) {
                        logger.error(err);
                        loading.release();
                    });
                } else {
                    loading.release();
                }
            });
        }).catch(function (err) {
            logger.error(err);
            loading.release();
        });
    } else { // Submit Batch
        saveProgress().then(function(originals) {
            isFormValid().then(function() {
                let formChecks = [];
                $("form.apply_from").each(function(index, element) {
                    if(originals[index].StepStatusId == 17) //Don't check submitted applications
                    {
                        formChecks.push(applyService.canSubmitApplication(originals[index].ApplicationId));
                    }                
                });
                $.when(...formChecks).done(function(...checksArray) {
                    // CanSubmit checks for all apps - gets flipped for this functionality
                    // Filter checks array to only include failed checks - if all are true then the filtered array is empty and the whole batch can submit
                    checksArray = checksArray.filter(function(check) { if (check.CanSubmit === 1) return false; else return true;});
                    if (checksArray.length === 0) {
                        // Check if batch is already submitted and submit additional apps/course as secondary
                        if (originals[0].BatchStatusId >= 40) {
                            let secondaryApps = [];
                            for (let app of originals) {
                                if (app.StepStatusId === 17) {
                                    secondaryApps.push(applyService.submitSecondaryApplication(app.ApplicationId));
                                }
                            }
                            // $("form.apply_from").each(function(index, element) {
                            //     secondaryApps.push(applyService.submitSecondaryApplication($(element).data("ApplicationId")));
                            // });
                            $.when(...secondaryApps).done(function(...apps) {
                                window.location.href = "/dashboard";
                            }).fail(function(err) {
                                logger.error(err);
                                loading.release();
                                bootoast.toast({
                                    message: "An unknown error has occurred",
                                    timeout: false,
                                    type: "danger",
                                    icon: "alert"
                                });
                            });
                        } else {
                            //Check if applicant has payment token(s)
                            let batchId = urlHelper.getQueryVariable("b");
                            applyService.getBatchPaymentTokens(batchId)
                            .then(function(paymentTokens){
                                if(paymentTokens && paymentTokens.length > 0)
                                {
                                    loading.release();
                                    $("#paymentTokenModal").modal("show");
                                }
                                else
                                {
                                    // Check if application is by an agent
                                    if (Number.parseInt(localStorage.UserTypeId) === 2) {
                                        if (parseInt($(".application-fee-amount")[0].textContent) === 0 || $(".application-fee-amount")[0].textContent === '') {
                                            loading.release();
                                            $("#submitApplicationModalContinue").trigger("click");
                                        } else {
                                            // Check if agent is approved
                                            userService.me().then(function (agent) { 
                                                userService.GetOrganisationAgentByUserIdOrganisationId(agent.UserId, $("#applicationForm0").data("Organisation"))
                                                .then(function (agent) {

                                                    if(agent.AgentStatusId != 90)
                                                    {
                                                        if (!agent.IsApplicationFeeRequired) {
                                                            window.location.href = window.location.origin + '/summary?batchId=' + urlHelper.getQueryVariable("b") + "&applicationId=" + $("#applicationForm0").data("ApplicationId");
                                                            return;
                                                        } else {
                                                            loading.release();
                                                            if($(".application-fee-amount")[0].textContent == '' || parseInt($(".application-fee-amount")[0].textContent) === 0)
                                                            {
                                                                $("#agent-submit-application-title").html('Submit Application');
                                                                $(".agentPaymentSection").addClass("show");
                                                                $("#agentApplicationModalFree").show();
                                                                $("#agentApplicationModalPayment").hide();
                                                            }
                                                            else
                                                            {
                                                                $("#agent-submit-application-title").html('Application Fee Payment');
                                                                $("#agentApplicationModalFree").hide();
                                                                $("#agentApplicationModalPayment").show();
                                                                $(".agentPaymentSection").addClass("hide");
                                                                $(".agent-payment-notice").show();
                                                                $("#agentApplicationModalRequestPayment").show();
                                                            }
                                                            $("#agentApplicationModal").modal("show");
                                                        } 
                                                    }
                                                    else
                                                    {
                                                        bootoast.toast({
                                                            message: "Application can not be submitted: Agent is rejected.",
                                                            timeout: false,
                                                            type: "danger",
                                                            icon: "alert"
                                                        });
                                                    }
                                                }).catch(function(exception){
                                                    logger.error(exception);
                                                    loading.release();
                                                });
                                            });
                                        }
                                    } else {
                                        if($("#applicationForm0").data("AgentUserId"))
                                        {
                                            // Check if agent is approved
                                            userService.GetOrganisationAgentByUserIdOrganisationId($("#applicationForm0").data("AgentUserId"), $("#applicationForm0").data("Organisation"))
                                            .then(function (agent) {

                                                if(agent.AgentStatusId != 90)
                                                {
                                                    if (!agent.IsApplicationFeeRequired) {
                                                        window.location.href = window.location.origin + '/summary?batchId=' + urlHelper.getQueryVariable("b") + '&applicationId=' + $('#applicationForm0').data('ApplicationId');
                                                        return;
                                                    } else {
                                                        loading.release();
                                                        if($(".application-fee-amount")[0].textContent == '' || parseInt($(".application-fee-amount")[0].textContent) === 0)
                                                        {
                                                            $("#submitApplicationModalContinue").trigger("click");
                                                        } else {
                                                            $("#submitApplicationModal").modal("show");
                                                        }
                                                    } 
                                                }
                                                else
                                                {
                                                    bootoast.toast({
                                                        message: "Application can not be submitted: Agent is rejected.",
                                                        timeout: false,
                                                        type: "danger",
                                                        icon: "alert"
                                                    });
                                                }
                                            }).catch(function(exception){
                                                logger.error(exception);
                                                loading.release();
                                            });
                                        }
                                        else
                                        {
                                            loading.release();
                                            if (parseInt($(".application-fee-amount")[0].textContent) === 0) {
                                                $("#submitApplicationModalContinue").trigger("click");
                                            } else {
                                                $("#submitApplicationModal").modal("show");
                                            }
                                        }
                                    }
                                }
                            })
                            .catch(function(exception){
                                logger.error(exception);
                                loading.release();
                            });
                        }
                    }
                    else {
                        loading.release();

                        var errors = '';
                        checksArray.forEach(function(application)
                        {
                            errors += '<br/>';
                            errors += application.ApplicationName + '<br />';
                            errors += '<ul>';

                            if(application.CourseHasVenues)
                            {
                                if(!application.IsVenueSelected)
                                {
                                    errors += '<li>Venue is not selected. Please select a venue.</li>';
                                }
                                else if(!application.IsVenueEnabled)
                                {
                                    errors += '<li>Selected venue is disabled.</li>';
                                }
                                else if(application.IsCourseVenueApplicationLimitReached)
                                {
                                    errors += '<li>Course venue limit reached.</li>';
                                }
                            }

                            if(application.IsUnapprovedAgent)
                            {
                                errors += '<li>Agent is not approved.</li>';
                            }

                            if(application.IsCourseApplicationLimitReached)
                            {
                                errors += '<li>Application limit reached.</li>';
                            }
                            else
                            {
                                if(!application.IsCourseOpen)
                                {
                                    errors += '<li>The course is closed.</li>';
                                }
                                if(!application.IsCourseEnabled)
                                {
                                    errors += '<li>The course is disabled.</li>';
                                }
                                if(application.IsCourseDeleted)
                                {
                                    errors += '<li>The course was deleted.</li>';
                                }
                                if(!application.IsRecommendationRequirementFulfilled)
                                {
                                    errors += '<li>The reference condition was not met.</li>';
                                }
                                if(!application.IsSupervisionRequirementFulfilled)
                                {
                                    errors += '<li>The supervision condition was not met.</li>';
                                }
                            }
                            errors += '</ul>';
                        })

                        bootoast.toast({
                            message: "Application cannot be submitted:" + errors,
                            timeout: false,
                            type: "danger",
                            icon: "alert"
                        });
                    }
                });
            }).fail(function (err) {
                logger.error(err);
                loading.release();
            });
        }).catch(function (err) {
            logger.error(err);
            loading.release();
        });
    }
});

$(document.body).on("click", "#submitApplicationModalPDF", function(e) {
    $("form.apply_from").each(function(index, element) {
        let appId = $('#applicationForm' + index).data('ApplicationId');
        applyService.getApplicationHtml(appId).then(function (html) {
            let formData = new FormData();
            formData.append('ApplicationHtml', html);
            fileService.getApplicantPdfExport(appId, formData).then(function (pdf) {
                let filename = "application.pdf";
                let blob = new Blob([pdf], { type: 'application/pdf' });
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var elem = window.document.createElement('a');
                    elem.href = window.URL.createObjectURL(blob);
                    elem.download = filename;
                    document.body.appendChild(elem);
                    elem.click();
                    document.body.removeChild(elem);
                }
            }).fail(function (err) {
                logger.error(err);
            });
        });
    });
});

$(document.body).on("click", "#submitApplicationModalContinue", function (e) {
    e.preventDefault();

    var checkCardholderForm;
    var noApplicationFeeCheck = false;
    //don't check form validity if form is not present (no app fee)
    if($('#paymentInfo').hasClass("hide")) {
        checkCardholderForm = true;
        var noApplicationFeeCheck = true;
    }
    else {
        checkCardholderForm = document.getElementById("applicationFeeCardholderForm").reportValidity();
    }

    if (checkCardholderForm) {

        if($("#cardholderMobilePhoneNumber").val().indexOf("0") == 0 && !noApplicationFeeCheck)
        {
            bootoast.toast({
                message: "Leading 0 must be removed from the mobile phone number.",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
            $("#cardholderMobilePhoneNumber").focus();
        }
        else if($("#cardholderMobilePhoneNumber").val().length < 6 && !noApplicationFeeCheck)
        {
            bootoast.toast({
                message: "Mobile phone number must contain at at least 6 characters.",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
            $("#cardholderMobilePhoneNumber").focus();
        }
        else 
        {
            loading.block();
            let batchStatus = $('#applicationForm0').data("BatchStatusId");
            // let CourseId = $('#applicationForm0').data("CourseId");
            // let TemplateVersionId = $('#applicationForm0').data("TemplateVersionId");
            let applicationFeeAmount = parseFloat($('.application-fee-amount').eq(0).text());
            let courseChecks = [];
            $("form.apply_from").each(function(index, element) {
                courseChecks.push(applyService.getCourseByCourseId($(element).data("CourseId")));
            });
            let appIdsThatNeedUpdating = [];
            $.when(...courseChecks).done(function(...courses) {
                for (let ii = 0; ii < courses.length; ii++) {
                    if (!applyBuilder.checkIfTemplateVersionMatches(courses[ii], { TemplateVersionId: $("form.apply_from").eq(ii).data("TemplateVersionId") })) {
                        appIdsThatNeedUpdating.push($("#applicationForm" + ii).data("ApplicationId"));
                    }
                }
                if (appIdsThatNeedUpdating.length > 0) {
                    loading.release();
                    $("body").data("appIdsThatNeedUpdating", JSON.stringify(appIdsThatNeedUpdating));
                    $("#submitApplicationModal").modal("hide");
                    $("#courseMismatchModal").modal("show");
                } else {
                    if (batchStatus < 40) {
                        $("#submitApplicationModal").modal("hide");
                        if (applicationFeeAmount > 0) {
                            RealexHpp.setHppUrl(_realex_);
                            var cardholderData = {};
                            cardholderData.ApplicationId = $("#applicationForm0").data("ApplicationId");
                            cardholderData.CustomerEmail = $("#cardholderEmailAddress").val();
                            cardholderData.AddressLine1 = $("#cardholderAddressLine1").val();
                            cardholderData.AddressLine2 = $("#cardholderAddressLine2").val();
                            cardholderData.City = $("#cardholderCity").val();
                            cardholderData.PostalCode = $("#cardholderPostalCode").val();
                            cardholderData.CountryCode = $("#cardholderCountryCode").find(':selected').data('isocode');
                            cardholderData.MobilePhone = $("#cardholderMobilePhoneCountry").val()+"|"+$("#cardholderMobilePhoneNumber").val().trim();

                            paymentService.startApplicationFeeOnlineTransaction(cardholderData).then(function(response) {
                                //console.log(response);
                                RealexHpp.lightbox.init("payButtonId", window.location.origin + "/applicationFeePaymentSummary", response);
                                //RealexHpp.lightbox.init("payButtonId", "https://localhost:44388/ApplicationFeePayments", response);
                                $('body').addClass('loaded');
                                $('#payButtonId').trigger('click');
                                loading.release();
                            });
                        } else {
                            window.location.href = window.location.origin + '/summary?batchId=' + urlHelper.getQueryVariable("b") + '&applicationId=' + $('#applicationForm0').data('ApplicationId');
                        }
                    } else {
                        window.location.href = window.location.origin + '/summary?batchId=' + urlHelper.getQueryVariable("b") + '&applicationId=' + $('#applicationForm0').data('ApplicationId');
                    }
                }
            }).fail(function(err) {
                logger.error(err);
                loading.release();
                bootoast.toast({
                    message: "An unknown error has occurred",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
            });
        }
    }
});

$(document.body).on("click", "#agentApplicationModalPay", function (e) {
    e.preventDefault();

    if($(".agentPaymentSection").hasClass("hide"))
    {
        $(".agentPaymentSection").removeClass("hide");
        $(".agent-payment-notice").hide();
        $("#agentApplicationModalRequestPayment").hide();
    }
    else
    {
        if (document.getElementById("agentApplicationFeeCardholderForm").reportValidity()) 
        {
            if($("#agentCardholderMobilePhoneNumber").val().indexOf("0") == 0)
            {
                bootoast.toast({
                    message: "Leading 0 must be removed from the mobile phone number.",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
                $("#agentCardholderMobilePhoneNumber").focus();
            }
            else if($("#agentCardholderMobilePhoneNumber").val().length < 6)
            {
                bootoast.toast({
                    message: "Mobile phone number must contain at at least 6 characters.",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
                $("#agentCardholderMobilePhoneNumber").focus();
            }
            else {
                RealexHpp.setHppUrl(_realex_);
                var cardholderData = {};
                cardholderData.ApplicationId = $("#applicationForm0").data("ApplicationId");
                cardholderData.CustomerEmail = $("#agentCardholderEmailAddress").val();
                cardholderData.AddressLine1 = $("#agentCardholderAddressLine1").val();
                cardholderData.AddressLine2 = $("#agentCardholderAddressLine2").val();
                cardholderData.City = $("#agentCardholderCity").val();
                cardholderData.PostalCode = $("#agentCardholderPostalCode").val();
                cardholderData.CountryCode = $("#agentCardholderCountryCode").find(':selected').data('isocode');
                cardholderData.MobilePhone = $("#agentCardholderMobilePhoneCountry").val()+"|"+$("#agentCardholderMobilePhoneNumber").val().trim();
        
                paymentService.startApplicationFeeOnlineTransaction(cardholderData).then(function (response) {
                    RealexHpp.lightbox.init("payButtonId", window.location.origin + "/applicationFeePaymentSummary", response);
                    $('body').addClass('loaded');
                    $('#payButtonId').trigger('click');
                    $("#agentApplicationModal").modal("hide");
                    loading.release();
                });
            }
        }
        else
        {
            loading.release();
        }  
    }      
});

$("input.number-input").on('keydown',function(e){
    var len = parseInt($(this).data("maxlength"));
    //check if it's a key that number field allows but it's not valid in this context
    if(["e",".",",","-","+"].indexOf(e.key)>-1)
    {
        e.preventDefault();
        return 0;
    }
    //if max length is defined check if current value matches max length and cancel input
    if(len != NaN)
    {
        if(e.key.match(/^[0-9]*$/) != null && this.value.length == len) {
            e.preventDefault();
            return 0;
        }
    }
});

$(document.body).on('click', '#agentApplicationModalRequestPayment', function(e){
    loading.block();
    var ActiveAppId = $("#applicationForm"+$("#applicationCourseTabs li.active").index()).data("application-id");
    if(!ActiveAppId) ActiveAppId = $("#applicationForm0").data("ApplicationId");
    applyService.AgentRequestApplicationFeePayment(ActiveAppId)
    .then(function(){
        bootoast.toast({
            message: "Applicant has been notified via E-mail. You will be redirected to your dashboard shortly.",
            timeout: false,
            type: "success",
            icon: "alert"
        });
        setTimeout(function(){
            window.location.href = "/dashboard";
        }, 3000)
    }).fail(function(err) {
        logger.error(err);
    }).always(function(){
        loading.release();
    });
})

$(document.body).on("click", "#paymentTokenModalPay", function (e) {
    e.preventDefault();
    loading.block();
    let batchId = urlHelper.getQueryVariable("b");
    paymentService.payApplicationFeeByPaymentToken(batchId)
    .then(function (response) {
        //console.log('pay by paymentToken response', response);

        if(!response || !response.Payment || !response.Payment.PaymentId || !response.Payment.PaymentId > 0)
        {
            $('#paymentTokenPaymentResult').html("There was an error using the payment token to pay the application fee. Application(s) were not submitted.");
            $('#paymentTokenPaymentResultModalOK').hide();
            $('#paymentTokenPaymentResultModalError').show();
        }
        else
        {
            $('#paymentTokenPaymentResultModalOK').show();
            $('#paymentTokenPaymentResultModalError').hide();
        }
        loading.release();
        $("#paymentTokenModal").modal("hide");
        $("#paymentTokenPaymentResultModal").modal("show");
    }).fail(function(err) {
        logger.error(err);
        loading.release();
        bootoast.toast({
            message: "An unknown error has occurred",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    });
});

$(document.body).on("click", "#paymentTokenPaymentResultModalOK", function (e) {
    e.preventDefault();
    window.location.href = window.location.origin + '/application/' + $("#applicationForm0").data("ApplicationId");

});

$(document.body).on("click", ".applyNextBtn, .applyPrevBtn", function (e) {
    e.preventDefault();
    let tabid = e.target.dataset.tabid;
    let formid = e.target.dataset.formid;
    $("#app" + formid + "-tabLink" + tabid).trigger("click");
});

// $(document.body).on("click", "#agentApplicationModalRequest", function (e) {
//     e.preventDefault();
//     window.location.href = window.location.origin + '/summary?batchId=' + $('#applicationForm').data('batchId');
// });

$(document).on("change", ".priority-list-select", function (e) {
    var formIndex = $(this).data("formindex");
    var applicationId = $(`#applicationForm${formIndex}`).data('ApplicationId');
    var atrtibuteInstanceId = $(this).parents(".form-group").data('attribute-instance-id');
    applyService.getVersionAttributeByAttributeInstanceId(applicationId, atrtibuteInstanceId)
        .then(function (attribute) {
            applyBuilder.setPriorityList(attribute, formIndex);
            isFormDirty();
        })
});

$(document).on("change", ".apply_from .form-control", function (e) {
    let str = $(this).closest(".apply_from").attr("id");
    applyBuilder.conditionCheckAllApps();
});

$(document).on("change", ".apply_from input[type=date]", function (e) {
    let str = $(this).closest(".apply_from").attr("id");
    applyBuilder.conditionCheckAllApps();
});

$(document).on('change', '.apply_from input[type=checkbox]', function (e) {
    let str = $(this).closest(".apply_from").attr("id");
    $(this).val($(this).prop("checked"));
    applyBuilder.conditionCheckAllApps();
});

$(document).on("input", ".apply_from input[type=text]", function (e) {
    let str = $(this).closest(".apply_from").attr("id");
    applyBuilder.conditionCheckAllApps();
});

$(document).on("input", ".apply_from textarea", function (e) {
    let str = $(this).closest(".apply_from").attr("id");
    applyBuilder.conditionCheckAllApps();
});

$(document).on('click', '.apply_from .file-item', function () {
    var fileId = $(this).data('file-id');
    fileService.getDownloadUrl(fileId)
        .then(function (file) {
            var win = window.open(file.Url, '_blank');
            // win.focus();
        });
})

$('#applicationCourseTabsContent').on('change', 'input[type=file]', function (e) {
    loading.upload();
    var fileAttributeContainer = $(this);
    var fieldName = $(this).parents(".form-group").attr('id').substring("div-".length);
    var appId = $(this).parents('form').data('ApplicationId');
    var userId = $(this).parents('form').data('UserId');
    var attributeInstanceId = $(this).parents(".form-group").data('attribute-instance-id');
    var formData = new FormData();
    var fileAttributeParent = $(this).parents('form').find('#div-' + fieldName);
    var replaceExisting = !fileAttributeParent.hasClass('multidocs');
    var isEdit = $(this).data("edit");

    var files = fileAttributeContainer.get(0);

    for (var i = 0; i < files.files.length; i++) {
        var file = files.files[i];
        formData.append('files', file, file.name);
    }
    if (files) {
        if (formData.get('files') != null) {
            var containerId = fileAttributeParent.data('container-id');
            formData.append('ApplicationId', appId == undefined ? 0 : appId);
            formData.append('AttributeInstanceId', attributeInstanceId == undefined ? 0 : attributeInstanceId);
            formData.append('ContainerId', containerId == undefined ? 0 : containerId);
            formData.append('IsReplace', replaceExisting == undefined ? false : replaceExisting);
            formData.append('IsEdit', isEdit == undefined ? false : isEdit);
            fileService.uploadContainerFiles(formData)
            .then(function (filesInContainer) {
                if (filesInContainer.length > 0) {
                    fileContainerService.getByApplicationIdAttributeInstanceId(appId, attributeInstanceId)
                    .then(function (container) {
                        fileAttributeParent.data('container-id', container.ContainerId);

                        $(fileAttributeContainer).val("");
                        //TODO: Do we need ContainerId in applicationDetailsBuilder.renderFileList()?
                        applyBuilder.renderFileList(container.ApplicationId, container.ContainerId, container.AttributeInstanceId, filesInContainer);
                        loading.release();
                    })
                    .fail(function (exception) {
                        logger.error(exception);
                        bootoast.toast({
                            message: "An error has occurred getting file container.",
                            timeout: false,
                            type: "danger",
                            icon: "alert"
                        });
                        loading.release();
                    })
                }
            })
            .fail(function (exception) {
                loading.release();
            })
        }
    }
});

$('#applicationCourseTabsContent').on('click', '.delete-file', function () {
    loading.delete();
    if (confirm("Are you sure you want to delete this file?")) {
        var fileId = $(this).parent().data('file-id');
        var applicationId = $(this).parents('form').data('ApplicationId');
        var attributeInstanceId = $(this).parents(".form-group").data('attribute-instance-id');
        fileService.deleteFile(fileId)
            .then(function (deletedFile) {
                fileService.getByApplicationIdAttributeInstanceId(applicationId, attributeInstanceId)
                    .then(function (filesInContainer) {
                        fileContainerService.getByApplicationIdAttributeInstanceId(applicationId, attributeInstanceId)
                            .then(function (container) {
                                applyBuilder.renderFileList(container.ApplicationId, container.ContainerId, container.AttributeInstanceId, filesInContainer);
                                loading.release();
                            })
                    })
            });
    }
})

$(document.body).on("click", ".collapseHelpBtn", function (e) {
    e.preventDefault();
    $(".collapseHelp").collapse("hide");
});

$(document.body).on("click", ".courseBatchModalCourseCloseLink", function (e) {
    e.preventDefault();
    let id = $(this).data("courseid");
    applyBuilder.deleteRowFromCourseBatch(id);
});

$("#courseBatchModalAddAdditionalCoursesCancel").on('click', function (e) {
    $("#courseBatchModal").modal("hide");
})

$(document.body).on('click', "#batchDirtyModalContinue", function(e) {
    e.preventDefault();
    loading.block();
    saveProgress().then(function(originals) {
        let action = $("#batchDirtyModal").data("action");
        if (typeof action !== "undefined") {
            switch (action) {
                case "add":
                    $("#batchDirtyModal").modal("hide");
                    $("#courseBatchModalAddAdditionalCourses").show();
                    $("#courseBatchModalAddAdditionalCoursesCancel").show();
                    applyService.GetCourseInfoByBatchId(urlHelper.getQueryVariable("b"))
                        .then(function (courses) {
                            applyBuilder.prepareAdditionalCourseModal($("#applicationForm0").data('OrganisationId'), $("#applicationForm0").data('WorkflowId'), courses).then(function () {
                                $("#courseBatchModal").modal({
                                    backdrop: "static",
                                    keyboard: false
                                });
                            }).catch(function(err) {
                                logger.error(err);
                                bootoast.toast({
                                    message: "An unknown error has occurred",
                                    timeout: false,
                                    type: "danger",
                                    icon: "alert"
                                });
                            }).finally(function(e){
                                loading.release();
                            })
                        })
                    break;
                case "delete":
                    loading.release();
                    $("#batchDirtyModal").modal("hide");
                    $("#batchAppDeleteModal").modal("show");
                    break;
                default: throw new Error("No valid action foound.");
            }
        } else {
            throw new Error("No action foound.");
        }
    });
})

$(document.body).on('click', "#openCourseBatchModalBtn", function(e) {
    e.preventDefault();
    loading.block();
    if ($("form.apply_from .formContainer").hasClass("dirty")) {
        loading.release();
        $("#batchDirtyModal").data("action", "add");
        $("#batchDirtyModalAction").text("Add");
        $("#batchDirtyModalBody").html(`
            <p>
                To add a ${courseStr} to the application, <strong>current changes to the application form(s) must be saved</strong>.
            </p>
            <p>
                Do you want to <strong>save the current changes</strong> to the application form(s) and <strong>add a new ${courseStr}(s) to the application</strong>?
            </p>
        `);
        $("#batchDirtyModal").modal("show");
    } else {
        $("#courseBatchModalAddAdditionalCourses").show();
        $("#courseBatchModalAddAdditionalCoursesCancel").show();
        applyService.GetCourseInfoByBatchId(urlHelper.getQueryVariable("b"))
            .then(function (courses) {
                applyBuilder.prepareAdditionalCourseModal($("#applicationForm0").data('OrganisationId'), $("#applicationForm0").data('WorkflowId'), courses).then(function () {
                    $("#courseBatchModal").modal({
                        backdrop: "static",
                        keyboard: false
                    });
                })
            }).fail(function(err) {
                logger.error(err);
                bootoast.toast({
                    message: "An unknown error has occurred",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
            }).always(function(e){
                loading.release();
            })
    }
})

$("#courseBatchModalAddAdditionalCourses").on('click', function (e) {
    e.preventDefault();
    var courseArray = [];
    let currentTabCount = $("#applicationCourseTabs li").length - 1; // Minus 1 here for the 'Add Course' tab

    $('#courseBatchModalCourseTable > tbody').children().each(function () {
        if ($(this).find(".fa-close").length > 0) {
            if ($(this).data('courseid') != undefined) {
                courseArray.push($(this).data('courseid'));
            }
        }
    });

    if(courseArray.length > 0 )
    {
        loading.block();
        var data = {
            CourseIds: JSON.stringify(courseArray),
            // ApplicationId: $("#applicationForm").data('ApplicationId')
        }
    
        applyService.addCoursesToBatch(urlHelper.getQueryVariable("b"), data)
        .then(function () {
            $("#courseBatchModal").modal("hide");
            bootoast.toast({
                message: "Applications have been added.",
                timeout: false,
                type: "success"
            });
            applyBuilder.batchForm(urlHelper.getQueryVariable("b"), null, currentTabCount);
        }).fail(function(err) {
            logger.error(err);
            bootoast.toast({
                message: "An unknown error has occurred",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
        }).always(function(e){
            loading.release();
        })
    }
    else
    {
        let courseStr = pageSettingsHelper.getSavedCourseString();
        bootoast.toast({           
            message: `No additional ${courseStr}s were selected to be added. Please select a ${courseStr} from the search dropdown menu.`,
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    }
    
})

$('body').on('click', '.form-document-link', function () {
    var fileId = $(this).data('file-id');
    fileService.getDownloadUrl(fileId)
        .then(function (file) {
            var win = window.open(file.Url, '_blank');
            // win.focus();
        });
})

$("#courseMismatchModalUpdate").on("click", function(e) {
    let appIdsThatNeedUpdating = JSON.parse($("body").data("appIdsThatNeedUpdating"));
    let updates = [];
    for (let ii = 0; ii < appIdsThatNeedUpdating.length; ii++){
        updates.push(applyService.updateTemplateVersionId(appIdsThatNeedUpdating[ii]));
    }
    $.when(...updates).done(function(...results) {
        location.reload(true);
    });
});

$(document).on("click", ".openRecommendationModalBtn", function (e) {
    loading.block();
    let formIndex = $(this).data("form-index");
    applyService.getRecommendationRequests($(`#applicationForm${formIndex}`).data("ApplicationId"))
    .then(function (reccomendations) {
        applyBuilder.fillRecommendations(reccomendations);
        $("#recommendationModalRequest").data("form-index", formIndex);
        $("#recommendationModal").modal({
            backdrop: "static",
            keyboard: false
        });

    }).fail(function (err) {
        logger.error(err);
        bootoast.toast({
            message: "An error has occurred, apply service unable to create batch.",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    }).always(function(e){
        loading.release();
    });
})

$(document).on("click", ".openVenueModalBtn:not(.all-venues-full)", function (e) {
    loading.block();
    let formIndex = $(this).data("form-index");
    applyService.getCourseVenuesByCourseId($(`#applicationForm${formIndex}`).data("CourseId"))
    .then(function (venues) {
        applyBuilder.prepareVenueModal(venues, $(`#applicationForm${formIndex}`).data("VenueId"));
        $("#venueModalConfirm").data("form-index", formIndex);
        $("#venueModal").modal({
            backdrop: "static",
            keyboard: false
        });

    }).fail(function (err) {
        logger.error(err);
        bootoast.toast({
            message: "An error has occurred, unable to load venues.",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    }).always(function(e){
        loading.release();
    });
})

$("#recommendationModal").on('click', '.recommendation-resend-btn', function (e) {
    e.preventDefault();

    let checkboxes = $("#recommendation-inputs").find("input[type='checkbox']:checked");

    if (checkboxes.length == 0) {
        bootoast.toast({
            message: "No reference requests are selected.",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    }
    else {
        if (confirm("Are you sure you want to re-send E-mails for the selected reference request(s)?")) {
            data = {
                SentDate: new Date().toISOString()
            }

            loading.block();

            let promises = [];

            $(checkboxes).each(function (i, e) {
                promises.push(applyService.resendRecommendationEmail($(e).data("id"), data))
            })

            $.when(promises)
            .done(function () {
                bootoast.toast({
                    message: "E-mails sent.",
                    timeout: false,
                    type: "success"
                });
            }).fail(function (err) {
                logger.error(err);
                bootoast.toast({
                    message: "An error has occurred.",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
            }).always(function(e){
                loading.release();
            });
        }
    }
})

$("#recommendationModal").on('click', '.recommendation-cancel-btn', function (e) {
    e.preventDefault();

    let checkboxes = $("#recommendation-inputs").find("input[type='checkbox']:checked");

    let formIndex = $(".openRecommendationModalBtn").data("form-index");

    if (checkboxes.length == 0) {
        bootoast.toast({
            message: "No reference requests are selected.",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    }
    else {
        if (confirm("Are you sure you want to cancel the selected reference request(s)?")) {
            data = {
                CancellationDate: new Date().toISOString()
            }

            loading.block();

            let promises = [];

            $(checkboxes).each(function (i, e) {
                promises.push(applyService.cancelRecommendationRequest($(e).data("id"), data))
            })

            $.when(promises)
            .done(function () {
                applyService.getRecommendationRequests($(`#applicationForm${formIndex}`).data("ApplicationId"))
                .then(function (reccomendations) {
                    applyBuilder.fillRecommendations(reccomendations)
                    loading.release();
                }).fail(function (err) {
                    logger.error(err);
                    bootoast.toast({
                        message: "An error has occurred.",
                        timeout: false,
                        type: "danger",
                        icon: "alert"
                    });
                }).always(function(e){
                    loading.release();
                });
            }).fail(function (err) {
                logger.error(err);
                loading.release();
                bootoast.toast({
                    message: "An error has occurred.",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
            })
        }
    }

})


$(document).on("click", ".openSupervisorModalBtn", function (e) {
    loading.block();
    let formIndex = $(this).eq(0).data("form-index");
    $.when(applyService.getSupervisionRequests($(`#applicationForm${formIndex}`).data("ApplicationId")), applyService.getDepartmentSupervisors($(".supervisor-amount").data("departmentId")))
    .done(function (reqs, sups) {
        applyBuilder.fillSupervisors(reqs, sups)
        $("#supervisionModalRequest").data("form-index", formIndex);
        $("#supervisionModal").modal({
            backdrop: "static",
            keyboard: false
        });
        loading.release();
    }).fail(function (err) {
        logger.error(err);
        loading.release();
        bootoast.toast({
            message: "An error has occurred.",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    })
})

$("#supervisionModal").on('click', '.supervision-resend-btn', function (e) {
    e.preventDefault();

    let checkboxes = $("#supervision-inputs").find("input[type='checkbox']:checked");

    if (checkboxes.length == 0) {
        bootoast.toast({
            message: "No supervision requests are selected.",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    }
    else {
        if (confirm("Are you sure you want to re-send E-mails for the selected supervision request(s)?")) {
            data = {
                SentDate: new Date().toISOString()
            }

            loading.block();

            let promises = [];

            $(checkboxes).each(function (i, e) {
                promises.push(applyService.resendSupervisionEmail($(e).data("id"), data))
            })

            $.when(promises)
            .done(function () {
                bootoast.toast({
                    message: "E-mails sent.",
                    timeout: false,
                    type: "success"
                });
                loading.release();
            }).fail(function (err) {
                logger.error(err);
                loading.release();
                bootoast.toast({
                    message: "An error has occurred.",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
            })
        }
    }
})

$("#supervisionModal").on('click', '.supervision-cancel-btn', function (e) {
    e.preventDefault();

    let checkboxes = $("#supervision-inputs").find("input[type='checkbox']:checked");

    let formIndex = $(".openSupervisorModalBtn").data("form-index");

    if (checkboxes.length == 0) {
        bootoast.toast({
            message: "No supervision requests are selected.",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    }
    else {
        if (confirm("Are you sure you want to cancel the selected supervision request(s)?")) {
            data = {
                CancellationDate: new Date().toISOString()
            }

            loading.block();

            let promises = [];

            $(checkboxes).each(function (i, e) {
                promises.push(applyService.cancelSupervisionRequest($(e).data("id"), data))
            })

            $.when(promises)
            .done(function () {
                $.when(applyService.getSupervisionRequests($(`#applicationForm${formIndex}`).data("ApplicationId")), applyService.getDepartmentSupervisors($(".supervisor-amount").data("departmentId")))
                .done(function (reqs, sups) {
                    applyBuilder.fillSupervisors(reqs, sups)
                }).fail(function (err) {
                    logger.error(err);
                    bootoast.toast({
                        message: "An error has occurred.",
                        timeout: false,
                        type: "danger",
                        icon: "alert"
                    });
                }).always(function(e){
                    loading.release();
                })
            }).fail(function (err) {
                logger.error(err);
                loading.release();
                bootoast.toast({
                    message: "An error has occurred.",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
            })
        }
    }
})

$(document).on("click", "#recommendationModalRequest", function (e) {
    e.preventDefault();
    var form = $("#recommendation-inputs");
    let formIndex = $(this).data("form-index");
    if (!form[0].checkValidity()) {
        $('<input type="submit">').hide().appendTo(form).trigger("click").remove();
    }
    else {
        if (areEmailsUnique(form.find("input[type='email']:not(.recommendation-expired):not(.recommendation-cancelled)"))) {
            var promises = [];

            form.find("input[type='email']:not(:disabled)").each(function (i, e) {
                var data = {
                    ApplicationId: $(`#applicationForm${formIndex}`).data("ApplicationId"),
                    Email: $(e).val()
                }
                promises.push(applyService.requestRecommendation(data));
            })

            $.when(promises)
                .done(function (result) {
                    $("#recommendationModal").modal("hide");
                    bootoast.toast({
                        message: "References sent successfully.",
                        timeout: false,
                        type: "success"
                    });
                }).fail(function (err) {
                    logger.error(err);
                    bootoast.toast({
                        message: "Error has occured while sending references.",
                        timeout: false,
                        type: "danger"
                    });
                })
        }
        else {
            bootoast.toast({
                message: "You need to provide references from different referees. Please use different E-mail addresses.",
                timeout: false,
                type: "danger"
            });
        }

    }
});

$("#supervisionModalRequest").on('click', function (e) {
    e.preventDefault();
    var form = $("#supervision-inputs");
    let formIndex = $(this).data("form-index");
    if (!checkSelectFormValidity(form)) {
        bootoast.toast({
            message: "Please select supervisors in all dropdown menus.",
            timeout: false,
            type: "danger"
        });
    }
    else {
        if (areSupervisorsUnique(form.find("select:not(.supervision-expired):not(.supervision-cancelled)"))) {
            var promises = [];

            form.find("select:not(:disabled)").each(function (i, e) {
                var data = {
                    ApplicationId: $(`#applicationForm${formIndex}`).data("ApplicationId"),
                    SupervisorId: $(e).val()
                }
                promises.push(applyService.requestSupervision(data));
            })

            $.when(promises)
                .done(function (result) {
                    $("#supervisionModal").modal("hide");
                    bootoast.toast({
                        message: "Supervision requests sent successfully.",
                        timeout: false,
                        type: "success"
                    });
                }).fail(function (err) {
                    logger.error(err);
                    bootoast.toast({
                        message: "Error has occured while sending supervision request.",
                        timeout: false,
                        type: "danger"
                    });
                })
        }
        else {
            bootoast.toast({
                message: "You need to select different supervisors. Please select other supervisor from the dropdown menu.",
                timeout: false,
                type: "danger"
            });
        }

    }
});


$(document).on("click", "#venueModalConfirm", function (e) {
    e.preventDefault();
    let formIndex = $(this).data("form-index");

    if($("#venue-dropdown").val() == null )
    {
        bootoast.toast({
            message: "Please choose a venue from the dropdown.",
            timeout: false,
            type: "danger"
        });
        return;
    }
    else
    {
        var data = {
            VenueId : $("#venue-dropdown").val()
        }
        var venueName = $(`#venue-dropdown option[value="${$("#venue-dropdown").val()}"]`).data("venue-name");
    
        applyService.setVenue($(`#applicationForm${formIndex}`).data("ApplicationId"), data)
        .done(function (result) {
            $("#venueModal").modal("hide");
            $(`#applicationForm${formIndex} .selectedVenue`).text(`Venue: ${venueName}`);
            $(`#openVenueModalBtn${formIndex}`).html(`<i class="fa fa-fw fa-lg fa-building"></i> &nbsp; Change venue`);
            $(`#openVenueModalBtn${formIndex}`).removeClass(`btn-warning`);
            $(`#openVenueModalBtn${formIndex}`).addClass(`btn-success`);
            $(`#applicationForm${formIndex}`).data("VenueId", data.VenueId);
            bootoast.toast({
                message: "Venue set successfully.",
                timeout: false,
                type: "success"
            });
        }).fail(function (err) {
            logger.error(err);

            if(err.responseText.indexOf("already full") > -1)
            {
                bootoast.toast({
                    message: "Venue is already full and can not take new applications.",
                    timeout: false,
                    type: "danger"
                });
            }
            else if(err.responseText.indexOf("inactive venue") > -1)
            {
                bootoast.toast({
                    message: "Selected venue is not available.",
                    timeout: false,
                    type: "danger"
                });
            }
            else
            {
                bootoast.toast({
                    message: "An error has occured while setting the venue.",
                    timeout: false,
                    type: "danger"
                });
            }
        })
    }
});


$("#recommendationModalCancel").on('click', function (e) {
    $("#recommendationModal").modal("hide");
});

$("#venueModalCancel").on('click', function (e) {
    $("#venueModal").modal("hide");
});

$("#supervisionModalCancel").on('click', function (e) {
    $("#supervisionModal").modal("hide");
});

$("body").on("keyup", ".textCounterTextArea", function (e) {
    e.preventDefault();
    $("#charCounter-" + $(this).attr("id")).text($(this).val().length);
    $("#wordCounter-" + $(this).attr("id")).text($(this).val().trim().replace(/\s\s+/g, " ").split(/\s/).length);
});

// Form change event handler
$(document).on("change", "form.apply_from", function(e) {
    e.preventDefault();
    isFormDirty();
});

/**
 * On tab change (shown); tabs are the pages of the form
 */
$(document.body).on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
    try {
        document.getElementById("invalidMessage").scrollIntoView({
            behaviour: "smooth",
            block: "center",
            inline: "center"
        });
    } catch (error) {
        try {
            document.getElementById("invalidMessage").scrollIntoView();
        } catch (error) {
        }
    }
})

/****************************************************************
 *                     SUPPORTING FUNCTIONS
 ****************************************************************/
/**
 * isFormDirty
 * marks or clears dirty attributes (and adds a window listener to prevent location change if dirty)
 * and returns dirty status
 */
function isFormDirty() {
    let isAppDirty = false;
    $("form.apply_from").each((formIndex, element) => {
        let theForm = $(element).serializeArray();
        let theState = JSON.parse($(element).data("formState"));
        if (JSON.stringify(theForm) === $(element).data("formState")) {
            $(element).find(".dirtyAttribute").removeClass("dirtyAttribute");
            $("#formContainer" + formIndex).removeClass("dirty");
            $("#tabSup" + formIndex).addClass("hidden");
        } else {
            isAppDirty = true;
            $("#formContainer" + formIndex).addClass("dirty");
            $("#tabSup" + formIndex).removeClass("hidden");
            // Check if any elements in form are uninitialized tristates
            $(element).find(".form-control.pac-3state input[type=radio][value!=na]:checked").each(function(indx, tristateItem) {
                if (typeof theState.find(el => el.value === $(tristateItem).attr("name")) === "undefined") {
                    $(tristateItem).closest(".form-attribute").addClass("dirtyAttribute");
                }
            });
            // Highlight which attributes have unsaved changes
            $.each(theForm, function(index, elem) {
                for (ii = 0; ii < theState.length; ii++) {
                    if (elem.name === theState[ii].name) {
                        if (elem.value !== theState[ii].value) {
                            $("#div-" + formIndex + "-" + elem.name).addClass("dirtyAttribute");
                        } else {
                            $("#div-" + formIndex + "-" + elem.name).removeClass("dirtyAttribute");
                        }
                        break;
                    }
                }
            });
        }
    });
    if (isAppDirty) {
        window.addEventListener("beforeunload", dirtyListener);
    } else {
        window.removeEventListener("beforeunload", dirtyListener);
    }
    return isAppDirty;
}

function dirtyListener(e) {
    // Cancel the event
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = '';
}

function isFormValid() {
    let deffered = $.Deferred();
    // Check if apps have been re-ordered
    let batchId = Number.parseInt(urlHelper.getQueryVariable("b"));
    let applicationId = Number.parseInt(urlHelper.getQueryVariable("id"));
    let hasApplicationBeenReordered = null;
    let action = null;
    if (Number.isInteger(applicationId)) {
        hasApplicationBeenReordered = true;
    } else {
        hasApplicationBeenReordered = false;
    }
    if (hasApplicationBeenReordered) {
        action = applyService.getAttributeValuesBatchFirstApp(batchId, applicationId);
    } else {
        action = applyService.getAttributeValuesBatch(batchId);
    }
    action.then(function(attributes) {
        let invalidFormIndices = [];
        let validationRules;
        let isFormValid;
        $(attributes).each(function(index, attributeGroup) {
            // First clear any previous validation
            $("#applicationForm" + index).validate().destroy();
            if (hasApplicationBeenReordered) {
                if (index === 0) {
                    validationRules = buildValidationRulesFromAttributes(attributeGroup, index);
                    // Perform form validation (jquery validation plugin)
                    isFormValid = $("#applicationForm" + index).validate(validationRules).form();
                    if (isFormValid) {
                        $("#tabSupErrors" + index).addClass("hidden");
                    } else {
                        $("#tabSupErrors" + index).removeClass("hidden");
                        invalidFormIndices.push(index);
                    }
                } else {
                    if (attributes[0].StepStatusId === 17) { // 17 = Unsubmitted
                        if (attributeGroup.StepStatusId === 17) {
                            validationRules = buildValidationRulesFromAttributes(attributeGroup, index);
                            // Perform form validation (jquery validation plugin)
                            isFormValid = $("#applicationForm" + index).validate(validationRules).form();
                            if (isFormValid) {
                                $("#tabSupErrors" + index).addClass("hidden");
                            } else {
                                $("#tabSupErrors" + index).removeClass("hidden");
                                invalidFormIndices.push(index);
                            }
                        }
                    }
                }
            } else {
                validationRules = buildValidationRulesFromAttributes(attributeGroup, index);
                // Perform form validation (jquery validation plugin)
                isFormValid = $("#applicationForm" + index).validate(validationRules).form();
                if (isFormValid) {
                    $("#tabSupErrors" + index).addClass("hidden");
                } else {
                    $("#tabSupErrors" + index).removeClass("hidden");
                    invalidFormIndices.push(index);
                }
            }
            
        });
        if (invalidFormIndices.length === 0) {
            deffered.resolve();
        } else {
            $(".modal").modal("hide");
            // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
            // try {
            //     document.getElementById("applicationCourseTabsContent").scrollIntoView({
            //         behaviour: "smooth",
            //         block: "center",
            //         inline: "center"
            //     });
            // } catch (error) {
            //     try {
            //         document.getElementById("applicationCourseTabsContent").scrollIntoView();
            //     } catch (error) {
            //     }
            // }
            let tabListStr = "";
            for (index of invalidFormIndices) {
                tabListStr += "<div class=\"\">" + $("#tab-" + index + "-CourseCode").text() + "<div>";
            }
            bootoast.toast({
                message: `<div class="">There are errors in your application. Please check the following ${courseStr}s:<div>${tabListStr}`,
                type: "danger",
                icon: "alert",
                timeout: false
            });
            deffered.reject();
        }
    });
    return deffered;
}

function checkSelectFormValidity(form) {
    let valid = true;

    $(form).find("select").each(function (i, e) {
        if ($(e).val() == null) valid = false;
    })

    return valid;
}

function areEmailsUnique(inputs) {
    let unique = true;
    let uniques = {}

    inputs.each(function (i, e) {
        if (uniques[$(e).val()] == null) {
            uniques[$(e).val()] = 1;
        }
        else {
            unique = false;
        }
    })

    return unique;
}

function areSupervisorsUnique(selects) {
    let unique = true;
    let uniques = {}

    selects.each(function (i, e) {
        if (uniques[$(e).val()] == null) {
            uniques[$(e).val()] = 1;
        }
        else {
            unique = false;
        }
    })

    return unique;
}

/**
 * Inserts an application
 * 
 * @param {number} batchId - the ID of the batch
 * @param {number} CourseId - the ID of the course
 */
function createApplicationFromBatch(batchId, CourseId, UserId = null) {
    var dfd = $.Deferred();
    if (CourseId == undefined || CourseId == null) {

    } else {
        var dfd = $.Deferred();
        applyService.getCourseByCourseId(CourseId).then(function (course) {

            // TODO: change hard coding of '1'
            var data = {
                BatchId: batchId,
                CourseId: course.CourseId,
                ApplicantUserId: UserId,
                ApplicationTypeId: 1
            }

            applyService.insertApplication(data).then(function (application) {
                var data = {
                    CourseId: application.CourseId,
                    ApplicationId: application.ApplicationId
                }
                applyService.assignApplicationToBatch(batchId, data).then(function (result) {
                    dfd.resolve(result);
                })
            })
        })
        return dfd.promise();
    }
}

/**
 * Saves the attribute answers for an application
 * 
 * @param {number} appId - the ID of the application
 * @param {number} data - the answer data
 */
function insertAttributeValues(appId, data) {
    var dfd = $.Deferred();
    if (appId == undefined || appId == null) {
        dfd.resolve('empty');
    } else {
        var data = {
            ApplicationId: appId,
            Attributes_JSON: data
        };
        applyService.saveAttributeValues(data).then(function (response) {
            dfd.resolve(response);
        }).fail(function (error) {
            logger.error(error);
            dfd.reject("Attribute save failure");
        });
    }
    return dfd;
}

/**
 * Converts the result of jquery function serialise() into something more usable
 * (the jquery function serialises a form)
 * 
 * @param {object} serializedForm - the object returned when calling the jquery function serialise
 */
function convertSerializedForm(serializedForm) {
    let form = {};
    for (const attribute of serializedForm) {
        form[attribute.name] = attribute.value === "on" ? "true" : attribute.value;
    }

    //Remove priority list select (dropdown) elements, because we use a hidden input element per priority list attribute
    // var priorityListSelects = $('select.priority-list-select');
    // priorityListSelects.each(function (i, e) {
    //     delete form[$(e).attr("id")];
    // });
    return form;
}

/**
 * Converts empty dates to nulls to avoid inserting UNIX 0 timestamp when no date is provided.
 * 
 * 
 * @param {object} serializedForm - the object returned when calling the jquery function serialise
 */
function convertEmptyDatesToNulls(serializedForm) {
    $('.optional-attribute input[type="date"]').each(function (i, item) {
        var id = $(item).attr("name");
        for (i = 0; i < serializedForm.length; i++) {
            if (serializedForm[i].name == id)
                if (serializedForm[i].value == "")
                    serializedForm[i].value = null;
        }
    });

    return serializedForm;
}

/**
 * Goes through all the checkboxes in the form and adds any false values found to form
 *  - these are normally excluded from the form serialisation
 * 
 * @param {object} form - the object returned from 'convertSerializedForm'
 */
function getCheckboxFalseValues(form) {
    $(form).find("input:checkbox").each(function (ii, elem) {
        form[$(elem).attr("name")] = $(elem).prop("checked");
    });
    return form;
}

/**
 *   For attributes that have a Hide Condition check if they are currently hidden (due to latest applicant selection)
 * 
 * @param {number} - AttributeInstanceId
 */
 function isHiddenByConditionMet(AttributeInstanceId) {
    
    var containerDiv = $("div").find(`[data-attribute-instance-id='${AttributeInstanceId}']`);
    if (containerDiv.hasClass("hidden-attribute")) {
        return true;
    }
    return false; 
}




/**
 * Saves the current state of the form (or form tab) for all batches associated with current application
 */
function saveCurrentTab() {
    return new Promise(function (resolve, reject) {
        let BatchId = $('#applicationForm').data('batchId');
        let form = $("#applicationForm").serializeArray();
        form = convertEmptyDatesToNulls(form);
        form = convertSerializedForm(form);
        form = getCheckboxFalseValues(form);
        form = JSON.stringify(form);
        applyService.getUnsubmittedApplicationsInBatch(BatchId).then(function (apps) {
            if (typeof apps !== 'undefined' && apps.length > 0) {
                var promises = [];

                apps.forEach(function (app) {
                    promises.push(insertAttributeValues(app.ApplicationId, form));
                })
                $.when(...promises).done(function() {
                    // Update form state (dirtiness)
                    $("form.apply_from").each(function(index, element) {
                        $(element).data("formState", JSON.stringify($(element).serializeArray()));
                    });
                    bootoast.toast({
                        message: "Progress has been saved.",
                        type: "success"
                    });
                    resolve("Batch save success");
                }).fail(function (err) {
                    logger.error(err);
                    reject("Batch save error");
                });
            } else {
            }
        });
    });
}

function saveProgress() {
    return new Promise(function(resolve, reject) {
        let batchId = urlHelper.getQueryVariable("b");
        let applicationId = urlHelper.getQueryVariable("id");
        $.when(
            (applicationId ? applyService.getAttributeValuesBatchFirstApp(batchId, applicationId) : applyService.getAttributeValuesBatch(batchId))
        ).done(function(originals) {
            let fullApplication = [];
            $("form.apply_from").each(function(index, value) {
                let attributes = originals[index].Attributes;
                let answers = [];
                let form = $(value).serializeArray();
                form = convertEmptyDatesToNulls(form);
                form = convertSerializedForm(form);
                form = getCheckboxFalseValues(form);
                for (element in form) {
                    let attribute = attributes.find(attr => attr.ColumnName === element);
                    if (attribute === undefined) {
                        reject("Attribute not found");
                    }
                    if (form[element] != null && form[element] != '' && attribute.HideCondition != null) {
                        if (isHiddenByConditionMet(attribute.AttributeInstanceId)) {
                                form[element] = null;       //nullify redundant answers still there  
                            }
                    }
                    answers.push({
                        Id: attribute.Id,
                        ColumnName: attribute.ColumnName,
                        Value: form[element],
                    })
                }
                // form = JSON.stringify(form);
                fullApplication.push({
                    BatchId: originals[index].BatchId,
                    ApplicationId: originals[index].ApplicationId,
                    Attributes: answers
                });
            });
            let data = {
                Attributes_JSON: JSON.stringify(fullApplication)
            };
            applyService.saveBatchAttributeValues(batchId, data).then(function() {
                // Update form state (dirtiness)
                $("form.apply_from").each(function(index, element) {
                    $(element).data("formState", JSON.stringify($(element).serializeArray()));
                });
                isFormDirty();
                bootoast.toast({
                    message: "Progress has been saved.",
                    type: "success"
                });
                resolve(originals);
            }).always(function() {
                loading.release();
            });
        });
    });
}


$("#autofill-button").on('click', function (e) {
    e.preventDefault();
    if (confirm("This will fill the application form with random data.\n\n*NOTE*\nThis is for testing purposes only.")) {
        loading.block();
        let batchId = urlHelper.getQueryVariable("b");
        let appIds = [];
        $("form.apply_from").each(function(index, value) {
            appIds.push(applyService.GetAutofillData({ id: $(this).data("ApplicationId") }));
        });
        $.when(...appIds).done(function(...apps) {
            applyBuilder.batchForm(batchId);
            bootoast.toast({
                message: "Application(s) have been randomized.",
                timeout: false,
                type: "success"
            });
        }).fail(function(err) {
            logger.error(err);
            loading.release();
            bootoast.toast({
                message: "An error occured while randomizing the application(s).",
                timeout: false,
                type: "danger"
            });
        });
    }
})

// $("#validateFormBtn").on('click', function (e) {
//     e.preventDefault();
//     // Create form validation object
//     let templateId = $("#applicationForm").data("TemplateVersionId");
//     let appId = $("#applicationForm").data("ApplicationId");
//     isFormValid(templateId, appId).then(function() {
//         bootoast.toast({
//             message: "Your form has passed validation.",
//             type: "success"
//         });
//     });
// });


// Function to easily validate a form from browser dev tools console
window.vv = function () {
    isFormValid().then(function () {
        bootoast.toast({
            message: "Your form has passed validation.",
            type: "success"
        });
    });
}


function buildValidationRulesFromAttributes(attributes, index) {
    let rules = {};
    let msg = {};
    var ppsnfields = ["pps_number", "ppsn_number", "ppsn"];
    
    attributes.Attributes.forEach(function(attribute) {
        // First check if the attribute is hidden
        if ($("#div-" + attribute.ColumnName).hasClass("hidden-attribute")) {
            return;
        }
        // Default attribute to not be required
        rules[attribute.ColumnName] = {
            required: attribute.Mandatory
        };
        // Error messages
        let defaultMsg = attribute.DisplayName + " (" + attribute.PageName + ")";
        msg[attribute.ColumnName] = {
            required: defaultMsg + "<small>Required</small>"
        }
        // Attribute types
        switch (attribute.AttributeTypeId) {
            case 1: // Int
                msg[attribute.ColumnName].digits = defaultMsg + "<small>Must be numbers only (Integer)</small>";
                rules[attribute.ColumnName].digits = true;
                break;
            case 2: // Decimal
                msg[attribute.ColumnName].number = defaultMsg + "<small>Must be numbers only (Decimal)</small>";
                rules[attribute.ColumnName].number = true;
                break;
            case 3:
                if(ppsnfields.indexOf(attribute.ColumnName) > -1)
                {
                    rules[attribute.ColumnName].required = false;
                    msg[attribute.ColumnName].validateppsn = defaultMsg + "<small>Must be a valid PPS Number.</small>";
                    rules[attribute.ColumnName].validateppsn = true;
                }
                break;
            case 6: // Date
                msg[attribute.ColumnName].dateISO = defaultMsg + "<small>Must be a valid date ( YYYY-MM-DD )</small>";
                rules[attribute.ColumnName].dateISO = true;
                break;
            case 16:
                msg[attribute.ColumnName].tristate = defaultMsg + "<small>Must be <u>yes</u> or <u>no</u></small>";
                rules[attribute.ColumnName].tristate = attribute.Mandatory;
                break;
            case 10: // File
            case 14:
            case 15:
                // Disable standard 'required' method for this element
                if (attribute.Mandatory) {
                    rules[attribute.ColumnName].required = false;
                    msg[attribute.ColumnName].filefield = defaultMsg + "<small>Must upload file(s)</small>";
                    rules[attribute.ColumnName].filefield = attribute.Mandatory;
                }
                break;
            case 18: // Priority List
                // Disable standard 'required' method for this element
                if (attribute.Mandatory) {
                    rules[attribute.ColumnName].required = false;
                    rules[attribute.ColumnName].prioritylist = attribute.NumberOfPriorityListItems;
                    msg[attribute.ColumnName].prioritylist = defaultMsg + "<small>Must select all options</small>";
                }
                break;
            case 19:
                let constraints = JSON.parse(attribute.Constraint);
                rules[attribute.ColumnName].minlength = constraints.min_chars ? constraints.min_chars : 0,
                    rules[attribute.ColumnName].maxlength = constraints.max_chars ? constraints.max_chars : 4000,
                    rules[attribute.ColumnName].minwords = constraints.min_words ? constraints.min_words : 0,
                    rules[attribute.ColumnName].maxwords = constraints.max_words ? constraints.max_words : 2000
                msg[attribute.ColumnName].minlength = jQuery.validator.format(defaultMsg + "<small>Must have greater than <u>{0}</u> characters</small>");
                msg[attribute.ColumnName].maxlength = jQuery.validator.format(defaultMsg + "<small>Must have less than <u>{0}</u> characters</small>");
                msg[attribute.ColumnName].minwords = jQuery.validator.format(defaultMsg + "<small>Must have greater than <u>{0}</u> words</small>");
                msg[attribute.ColumnName].maxwords = jQuery.validator.format(defaultMsg + "<small>Must have less than <u>{0}</u> words</small>");
                break;
        }

        

        if (attribute.ValidationPattern && attribute.ValidationPattern !== null) {
            rules[attribute.ColumnName].pattern = attribute.ValidationPattern
            msg[attribute.ColumnName].pattern = defaultMsg + "<small>Invalid or missing characters</small>";
        }
        if (attribute.HideCondition && attribute.HideCondition !== null) {
            let json = JSON.parse(attribute.HideCondition);
            let attributeShowing = true;

            let condBuilder = {};
            json.conditions.forEach(function (cond) {
                if (condBuilder.hasOwnProperty(cond.Key))
                    condBuilder[cond.Key] += ", " + String(cond.Value);
                else {
                    condBuilder[cond.Key] = {};
                    condBuilder[cond.Key] = String(cond.Value);
                }
            });

            let responses = [];

            Object.keys(condBuilder).forEach(function (key) {

                var fieldValue = null;
                var numberOfApps = $("#applicationCourseTabs li[role='presentation']").length-1;

                for(i = 0;i< numberOfApps;i++)
                {
                    fieldValue = applyBuilder.getFieldValue(i, key);
                    if(fieldValue != null) break;
                }
                if (fieldValue != null)
                {
                    // Check if current attribute matches the value of defined condition
                    if (condBuilder[key].indexOf(fieldValue) > -1) {
                        responses.push(true);
                    } else {
                        responses.push(false);
                    }
                }
                else {
                    //Attribute not found in any app, show by default
                    responses.push(json.showConditions?true:false);
                }


            });
            
            if (responses.length == 0) {
                attributeShowing = true;
            } else {
                if (json.showConditions) {
                    if (responses.indexOf(false) < 0) {
                        attributeShowing = true;
                    } else {
                        attributeShowing = false;
                    }
                } else {
                    if (responses.indexOf(true) < 0) {
                        attributeShowing = true;
                    } else {
                        attributeShowing = false;
                    }
                }
            }

            if(attributeShowing)
            {
                if (attribute.Mandatory) {
                    rules[attribute.ColumnName].required = true;
                } else {
                    rules[attribute.ColumnName].required = false;
                }
            }
            else
            {
                rules[attribute.ColumnName] = {};
            }


            // Specific further instructions for special attribute types
            switch (attribute.AttributeTypeId) {
                case 16:
                    // If it's a tristate, toggle that off if the attribute is not shown
                    if (rules[attribute.ColumnName].required === false) {
                        rules[attribute.ColumnName].tristate = false;
                    }
                    break;
                case 10: // File
                case 14:
                case 15:
                    // File types can never be 'required' because the input field is never used
                    if (rules[attribute.ColumnName].required === true) {
                        rules[attribute.ColumnName].required = false;
                    }
                    break;
            }
        }
    });
    //console.log("rules",rules);
    return {
        // debug: true,
        errorLabelContainer: "#invalidMessage" + index,
        wrapper: "li",
        onfocusout: false,
        onkeyup: false,
        onclick: false,
        focusInvalid: false,
        focusCleanup: false,
        ignore: false,
        rules: rules,
        messages: msg,
        highlight: function (element, errorClass, validClass) {
            $(element).addClass(errorClass).removeClass(validClass);
            // Check if element is radio and apply error class to parent div (.form-control)
            if ($(element).attr("type") === "radio") {
                $(element).parent().addClass(errorClass).removeClass(validClass);
            }
            // Check if element is a 'priority list' and apply error to parent div
            if ($(element).hasClass("priority-list")) {
                $(element).parent().addClass(errorClass).removeClass(validClass);
            }
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass(errorClass).addClass(validClass);
            // Check if element is radio
            if ($(element).attr("type") === "radio") {
                $(element).parent().removeClass(errorClass).addClass(validClass);
            }
            // Check if element is a 'priority list'
            if ($(element).hasClass("priority-list")) {
                $(element).parent().removeClass(errorClass).addClass(validClass);
            }
        },
        showErrors: function (errorMap, errorList) {
            $("#invalidMessage" + index).empty();
            $("#invalidMessage" + index).empty();
            $("#invalidMessage" + index).append(`
                <div class="h4"><u>This form contains errors</u></div>
                <div>Errors are listed below (with the page) and they have also been highlighted red in the form.</div>
                <div class="">Please correct them and try again</div>
                <hr>
            `);
            $("#invalidMessage" + index).addClass("alert alert-warning");
            this.defaultShowErrors();
        },
        errorElement: "section"
    };
}