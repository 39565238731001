import "../events/dashboardEvents";

const applyService = require('../services/applyService');
const coursesService = require('../services/coursesService');
const instituteService = require('../services/instituteService');
const v1Service = require('../services/v1Service');
const dashboardBuilder = require('../builders/dashboardBuilder');
const loadingHelper = require('../helpers/loading');
const logger = require('../helpers/clientLogger');

// Store all the applications here, makes the filter react quickly without have to hit endpoint every time
window.allApps = [];

$("#filtersBox").hide();
$('[data-toggle="tooltip"]').tooltip();
if (localStorage.getItem("UserTypeId") !== "2") {
    $("#agentFilters").hide();
}
loadingHelper.block();

instituteService.getUniversities().then(function(universities) {
    universities.forEach(function(university) {
        if (university.Version > 0) {
            $("#orgFilterSelect").append(`<option value="${university.OrganisationId}">${university.Name}</option>`);
        }
    });
});

applyService.getAllApplicationStatus().then(function(statuses) {
    statuses.forEach(function(status) {
        $("#statusFilterSelect").append(`<option value="${status.StatusId}">${status.Name}</option>`);
    });
});

applyService.getUserApplications().then(function(applications) {

    //select most recent academic year by default for agents
    if(localStorage["UserTypeId"] == "2")
    {
        $('#yearFilterSelect option:eq(1)').attr('selected', 'selected');
    }

    window.allApps = applications;
    dashboardBuilder.populateApplications(applications, dashboardBuilder.getFilters());
    let totalAppliedFilters = 0;
    if ($("#orgFilterSelect").val() !== "0") {
        totalAppliedFilters++;
    }
    if ($("#statusFilterSelect").val() !== "0") {
        totalAppliedFilters++;
    }
    if ($("#yearFilterSelect").val() !== "0") {
        totalAppliedFilters++;
    }
    if ($("#appnoFilterBox").val().length > 0) {
        totalAppliedFilters++;
    }
    if ($("#createFrom").val().length > 0 || $("#createTo").val().length > 0) {
        totalAppliedFilters++;
    }
    if ($("#actionFrom").val().length > 0 || $("#actionTo").val().length > 0) {
        totalAppliedFilters++;
    }
    if (totalAppliedFilters > 0) {
        $("#filterCount").text(totalAppliedFilters);
    } else {
        $("#filterCount").text("");
    }
    v1Service.getApplications(localStorage.getItem("UserName")).then(function(v1Apps) {
        if (v1Apps.length > 0) {
            dashboardBuilder.populateV1Applications(v1Apps);
        } else {
            $("#v1applications").remove();
        }
        loadingHelper.release();
    }).fail(function(err) {
        logger.error(err);
        loadingHelper.release();
    });
});