import "../events/applicationDetailsEvents";

const applyService = require('../services/applyService');
const fileService = require('../services/fileService');
const coursesService = require('../services/coursesService');
const instituteService = require('../services/instituteService');
const registerService = require('../services/registerService');
const applicationDetailsBuilder = require('../builders/applicationDetailsBuilder');
const urlHelper = require("../helpers/urlHelper");
const loadingHelper = require('../helpers/loading');
const logger = require('../helpers/clientLogger');

var currentApplicationId = urlHelper.getUrlParam(2);


// Get the application
loadingHelper.block();

// Get application
applyService.getApplication(currentApplicationId).then(function (application) {

    // Set the application name
    $(".appName").text(application.Name);
    $("#withdrawModalAppNameSpan").text(application.Name);
    // Set the Application ID
    $(".appId").text(application.ApplicationNumber ? application.ApplicationNumber : "");

    $.when(
        //get the batch
        applyService.getBatchByUserIdApplicationId(application.UserId, application.ApplicationId), 
        //get the course
        coursesService.getCourse(application.CourseId),
        registerService.getCountries())
    .done(function(batch, course, countries){
        $.when(
            // Get the pages
            applyService.getPagesByTemplateVersionId(application.TemplateVersionId), 
            //Get incomplete condition messages
            applyService.getIncompleteConditionalOfferCheckMessages(application.ApplicationId), 
            //get deposit payment status
            applyService.checkIfDepositIsPaid(application.ApplicationId),
            // Get the attributes
            applyService.getVersionAttributesByApplicationIdExt(application.ApplicationId),
            // Get the values
            applyService.getAttributeValues(application.ApplicationId),
            // Get the extended values for validity check
            applyService.getAttributeValuesExt(application.ApplicationId),
            // Get the action logs
            applyService.getActionLog(application.ApplicationId),
            // Get the course type
            coursesService.getCourseTypeById(course.CourseTypeId),
            // Get the university
            instituteService.getOrganisationById(course.OrganisationId),
            // Get setting for applicants
            instituteService.getSettingByOrganisationSettingsKey({ OrganisationId: application.OrganisationId, SettingsKey: "AllowApplicantToAttachMessageWhenSendingApplicationToAssessor" }),
            // Get Current step data
            applyService.getCurrentStepByApplicationId(application.ApplicationId),
            // Get assessors documents
            applyService.getAssessorsDocuments(application.ApplicationId),
            // Get application documents (attribute)
            fileService.getByApplicationId(application.ApplicationId),
            // Get applicants documents (generic)
            applyService.getApplicantsDocuments(application.ApplicationId),
            applyService.getApplicationsPerBatchLimitByCourseId(application.CourseId),
            applyService.getUserByUserId(application.UserId),
            application.VenueId?applyService.getVenueByVenueId(application.VenueId):null
            )
        .done(function(pages, messages, depositPayment, attributes, values, extValues, logs, courseType, organisation, allowApplicantMessageKey, step, assessorDocuments, applicationDocuments, applicantsDocuments, appsPerBatchLimit, applicantInfo, venue){            
            console.log('applicantsDocuments', applicantsDocuments);
            applicationDetailsBuilder.populateApplication(
                application,
                batch,
                pages,
                attributes,
                values,
                countries,
                logs,
                step,
                course,
                courseType,
                organisation,
                allowApplicantMessageKey,
                messages,
                depositPayment,
                assessorDocuments,
                applicationDocuments,
                applicantsDocuments,
                appsPerBatchLimit,
                extValues.filter(x => x.ValidityStatus === 0).length > 0, //check if there are invalid attributes.
                applicantInfo,
                venue
            );
            loadingHelper.release();
        }).fail(function (err) { logger.error(err); });
    }).fail(function (err) { logger.error(err); });
});

if(parseInt(localStorage.UserTypeId) == 1)
{
    $(".agent-notify-applicant-message").hide();
    $(".agent-payment-notice").hide();
    $(".depositPaymentSection").removeClass("hide");
    $(".pay-deposit-modal-actions > div").removeClass("col-xs-4");
    $(".pay-deposit-modal-actions > div").addClass("col-xs-6");
    $(".pay-deposit-modal-actions > div").first().remove();
}
else
{
    $(".agent-notify-applicant-message").show();
    $(".agent-payment-notice").show();
    $(".depositPaymentSection").addClass("hide");
}