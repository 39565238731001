import "../events/profileEvents";

const profileBuilder = require("../builders/profileBuilder");
const userService = require("../services/userService");
const registerService = require("../services/registerService");
const profileService = require("../services/profileService");
const instituteService = require('../services/instituteService');
const loading = require('../helpers/loading');

$.validator.setDefaults({
    // debug: true,
    onsubmit: false,
    errorClass: "pac-form-error",
    validClass: "pac-form-valid"
});

loading.block();

$.when(
    userService.me(),
    registerService.getCountries(),
    profileService.getAgentApprovalStatuses()
).done(function(me, countries, agentApprovalStatuses) {
    profileBuilder.buildCountries(countries);
    profileBuilder.buildProfile(me);
    profileBuilder.buildAgentApproval(agentApprovalStatuses);
    loading.release();
})