const error = require('../services/errorHandling');
const microLayer = require('../services/microLayerService');
const logger = require('../helpers/clientLogger');

function getCourses() {
	return microLayer.execute(microLayer.httpMethods.GET, "public/courses")
		.then(function (response) { return response })
        .fail(function(err) { logger.error(err); error.handleWithToast(err, "There was an error retrieving courses.") });
}

function searchCourses(searchQuery) {
	return microLayer.execute(microLayer.httpMethods.POST, "public/courses/search", searchQuery)
		.then(function (response) { return response })
        .fail(function(err) { logger.error(err); error.handleWithToast(err, "There was an error retrieving courses.") });
}

function getCourseTypes() {
	return microLayer.execute(microLayer.httpMethods.GET, "courseTypes")
		.then(function (response) { return response })
		.fail(function (err) { logger.error(err); error.handleWithToast(err, "There was an error retrieving course types.") });
}

function getCourseTypeById(id) {
	return microLayer.execute(microLayer.httpMethods.GET, "courseTypes/" + id)
		.then(function (response) { return response })
		.fail(function (err) { logger.error(err); error.handleWithToast(err, "There was an error retrieving course types.") });
}

function getStudyModes() {
	return microLayer.execute(microLayer.httpMethods.GET, "studyModes")
		.then(function (response) { return response })
		.fail(function (err) { logger.error(err); error.handleWithToast(err, "There was an error retrieving study modes.") });
}

function getFaculties() {
	return microLayer.execute(microLayer.httpMethods.GET, "faculties")
		.then(function (response) { return response })
		.fail(function (err) { logger.error(err); error.handleWithToast(err, "There was an error retrieving faculties.") });
}

function getDepartments() {
	return microLayer.execute(microLayer.httpMethods.GET, "departments")
		.then(function (response) { return response })
		.fail(function (err) { logger.error(err); error.handleWithToast(err, "There was an error retrieving departments.") });
}

function getCourse(courseId) {
	return microLayer.execute(microLayer.httpMethods.GET, "courses/" + courseId)
		.then(function (response) { return response })
		.fail(function (err) { logger.error(err); error.handleWithToast(err, "There was an error retrieving courses.") });
}

function getInterviewSlots(id, interviewsFormData) {
	return microLayer.execute(microLayer.httpMethods.PUT, "courses/" + id + "/interviewSlots", interviewsFormData)
		.then(function (response) { return response })
		.fail(function (err) { logger.error(err); error.handleWithToast(err, "There was an error getting interview slots.") });
}

function getInterviewSlotsCount(id, interviewsFormData) {
	return microLayer.execute(microLayer.httpMethods.PUT, "courses/" + id + "/interviewSlotsCount", interviewsFormData)
    .then(function (response) { return response })
		.fail(function (err) { logger.error(err); error.handleWithToast(err, "There was an error getting interview slots count.") });
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
	getCourses: getCourses,
    getCourseTypes: getCourseTypes,
    getCourseTypeById: getCourseTypeById,
    searchCourses: searchCourses,
	getStudyModes: getStudyModes,
	getFaculties: getFaculties,
	getDepartments: getDepartments,
	getCourse: getCourse,
	getInterviewSlots: getInterviewSlots,
	getInterviewSlotsCount: getInterviewSlotsCount
}