const microLayer = require('../services/microLayerService');

module.exports = {
  getByContainerId: getByContainerId,
  getByApplicationIdAttributeInstanceId: getByApplicationIdAttributeInstanceId
}

function getByContainerId(ContainerId) {
	return microLayer.execute(microLayer.httpMethods.GET, "fileContainers/"+ContainerId)
    .then(function (response) {
        return response;
    })
}

function getByApplicationIdAttributeInstanceId(ApplicationId, AttributeInstanceId) {
	return microLayer.execute(microLayer.httpMethods.GET, "fileContainers/"+ApplicationId+"/"+AttributeInstanceId+"/ByApplicationIdAttributeInstanceId")
    .then(function (response) {
        return response;
    })
}