const moment = require('moment');
const urlHelper = require("../helpers/urlHelper");
const pageSettingsHelper = require("../helpers/pageSettingsHelper");
const loading = require("../helpers/loading");
const instituteService = require('../services/instituteService');
const applyService = require('../services/applyService');
const v1Service = require('../services/v1Service');
const logger = require('../helpers/clientLogger');

/****************************************************************
 *                          EXPORTED FUNCTIONS
 ****************************************************************/

/**
 * Adds the name of the institute to the web page
 * 
 * @param {string} name - the name of the institute
 */
function name(name) {
    $('.instituteName').text("" + name);
}

/**
 * Adds the blurb of the institute to the web page
 * 
 * @param {string} text - the blurb of the institute
 */
function information(text) {
    if (typeof text === "undefined" || text === null) {
        text = "Please search for the course you wish to apply to.";
    }
    $('#blurbContent').html("" + text); 
    pageSettings();  
    checkPanelDropdowns();
}

function checkPanelDropdowns()
{
    $("#blurbContent").find(".cke-panel-header").each(function(i,e){
        var nextElem = $(e).next();
        $(e).addClass("cke-header-collapsed");
        if($(nextElem).hasClass("cke-panel-body"))
        {
            $(e).addClass("cke-panel-loaded");
            $(nextElem).addClass("cke-panel-loaded collapse");
        }
    })

    $(".cke-panel-body:not(.cke-panel-loaded").removeClass("cke-panel-body");
}

/**
 * Converts html into attribute friendly format
 * 
 * @param {string} text - non-escaped raw html text;
 */
function escapeQuotes(text)
{
    return text.split("\"").join("&quot;");
}

/**
 * Checks and adds any specific HTML according to Institute page settings e.g notices, etc.
 */
function pageSettings() {
    let organisationId = Number.parseInt(urlHelper.getUrlParam(2));
    instituteService.getPageSettingByOrganisationId(organisationId)
    .then (function (InstitutePageSettings) {
        const settingsObject = JSON.parse(InstitutePageSettings.SettingsValue!=null?InstitutePageSettings.SettingsValue.replace(/'/g, '"'):null); 
        if(settingsObject != null)
        {
            pageSettingsHelper.PMELandingSetUp(organisationId,settingsObject);
            if(settingsObject.Alerts != null)
                $('#blurbContent').prepend(pageSettingsHelper.getNoticesDOM(settingsObject.Alerts));
        }
    });
    pageSettingsHelper.helpdeskLandingSetUp(organisationId);
}



/**
 * Adds the blurb of the institute to the web page
 * 
 * @param {string} text - the blurb of the institute
 */
function additionalInformation(text) {
    if (typeof text === "undefined" || text === null || text.length === 0) {
        text = "FAQ coming soon...";
        $("#faqBtn").addClass("hidden");
    } else {
        $('#faqContent').html("" + text);
    }
}

/**
 * Changes the header image for the instutute
 * 
 * @param {string} filename - the url of the image to use for header img
 */
function headerImage(filename) {
    if (typeof filename !== "undefined" && filename !== null && filename.length > 0) {
        $("#headerImage").prop("src", "/img/headers/" + filename);
    } else {
        $("#headerImage").prop("src", "/img/headers/default.jpg");
    }
}

/**
 * Adds the institution's links to the web page
 * 
 * @param {string} url - the url to use for institute links
 */
function links(url) {
    $('.instituteLink').each(function(index, element) {
        $(element).attr("href", url);
        if (url && $(element).data("show-link")) {
            $(element).text(url);
        }
    });
}

function displayMeta() {
    let param = Number.parseInt(urlHelper.getUrlParam(2));
    instituteService.getOrganisationById(param).then(function(organisation) {
        if (typeof organisation.Name === "undefined") {
            throw new Error("Invalid institute");
        }
        if (organisation.Version == -1) {
            throwError("404 Page not found");
            throw new Error("Invalid institute");
        }
        headerImage(organisation.HeaderImage);
        name(organisation.Name);
        information(organisation.Information);
        additionalInformation(organisation.AdditionalInformation);
        links(organisation.URL);
    }).fail(function(err) {
        logger.error(err);
        bootoast.toast({
            message: "Invalid institute",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    }).always(function(e){
        loading.release();
    });
}

function throwError(message)
{
    $(".pac-inst-banner").closest(".container").empty();
    $(".mainContent .container").html(`<h1>ERROR</h1><p class="h2">${message}</p>`)
}

/**
 * Attaches the search results to the page DOM
 * 
 * @param {array} results - the results from search endpoint
 * @param {object} user - object returned from /me endpoint, for extra info about user (such as eu citizenship)
 * @param {object} studyModes - object returned from /public/studyModes endpoint
 */
function populateSearchResults(results, user, studyModes) {
    let isLoggedIn = localStorage.getItem("SessionToken") ? true : false;
    let resultsHtml = `
        <div id="resultList" class="list-group">
            <div class="list-group-item lead text-center">
                ${results.length} Search Result${results.length > 1 ? "s" : ""}
            </div>
    `;
    if (Array.isArray(results) && results.length > 0) {
        results.forEach(course => {
            let isOpen = [false, false];
            let isEUOpenText = "<span class=\"label label-danger\">EU - Closed</span>";
            let isNonEUOpenText = "<span class=\"label label-danger\">Non EU - Closed</span>";
            let isApplicationLimitReachedText = "<span class=\"label label-danger\">Application limit reached</span>";
            let applyBtn = "";

            isApplicant = false;

            if(user)
                if (localStorage.getItem("UserTypeId") === "1")
                    isApplicant = true;


            let now = moment();
            let euopening = moment(course.EUOpeningDate ? course.EUOpeningDate.replace("Z", "") : null);
            let euclosing = moment(course.EUClosingDate ? course.EUClosingDate.replace("Z", "") : null);


            // If an opening date is not defined or it is in the past, then the course is currently open
            if ((euopening.isValid() && euopening < now) || !euopening.isValid()) {
                isOpen[0] = true;
                // If closing date is defined and it is in the future, then display it
                if (euclosing.isValid() && euclosing > now) {
                    isEUOpenText = "<span class=\"label label-success\"><strong>EU - Closes:</strong> <em>" + euclosing.format("Do MMMM YYYY | h:mm a") + "</em></span>";
                    // TODO: remove this v1 block - v1 dont display closing date
                    if (sessionStorage.getItem("orgv") === "1") {
                        isEUOpenText = "<span class=\"label label-success\">EU - Open</span>";
                    }
                } else if (euclosing.isValid() && euclosing <= now) {
                    // If closing date has passed, close the course
                    isOpen[0] = false;

                    // TODO: remove this v1 block
                    if (sessionStorage.getItem("orgv") === "1") {
                        if (course.AllowLate === "Y") {
                            isOpen[0] = true;
                        }
                    }
                } else {
                    // If no closing date is defined, it's just open
                    isEUOpenText = "<span class=\"label label-success\">EU - Open</span>";
                }
            } else {
                // If opening date is in future, display it
                isEUOpenText = "<span class=\"label label-danger\"><strong>EU - Opens:</strong> <em>" + euopening.format("Do MMMM YYYY | h:mm a") + "</em></span>";
            }

            let noneuopening = moment(course.NonEUOpeningDate ? course.NonEUOpeningDate.replace("Z", "") : null);
            let noneuclosing = moment(course.NonEUClosingDate ? course.NonEUClosingDate.replace("Z", "") : null);

             // If an opening date is not defined or it is in the past, then the course is currently open
             if ((noneuopening.isValid() && noneuopening < now) || !noneuopening.isValid()) {
                isOpen[1] = true;
                // If closing date is defined and it is in the future, then display it
                if (noneuclosing.isValid() && noneuclosing > now) {
                    isNonEUOpenText = "<span class=\"label label-success\"><strong>Non EU - Closes:</strong> <em>" + noneuclosing.format("Do MMMM YYYY | h:mm a") + "</em></span>";
                    // TODO: remove this v1 block - v1 dont display closing date
                    if (sessionStorage.getItem("orgv") === "1") {
                        isNonEUOpenText = "<span class=\"label label-success\">Non EU - Open</span>";
                    }
                } else if (noneuclosing.isValid() && noneuclosing <= now) {
                    // If closing date has passed, close the course
                    isOpen[1] = false;

                    // TODO: remove this v1 block
                    if (sessionStorage.getItem("orgv") === "1") {
                        if (course.AllowLate === "Y") {
                            isOpen[1] = true;
                        }
                    }
                } else {
                    // If no closing date is defined, it's just open
                    isNonEUOpenText = "<span class=\"label label-success\">Non EU - Open</span>";
                }
            } else {
                    // If opening date is in future, display it
                    isNonEUOpenText = "<span class=\"label label-danger\"><strong>Non EU - Opens:</strong> <em>" + noneuopening.format("Do MMMM YYYY | h:mm a") + "</em></span>";
            }

            if(isApplicant)
                //for applicants show button based on the value associated with the EU Citizenship
                isOpen = user.EUCitizen?isOpen[0]:isOpen[1];
            else
                //show apply button if it's open for either for EU or Non EU
                isOpen = isOpen.some((x) => x==true);

            if (isLoggedIn) {
                // TODO: remove v1 stuff once v1 is gone!!!
                // Check if org is on v1 or v2
                // TODO: remove this weird initial check when its not needed - allows for certain v1 courses to use v2 instead
                if (course["_version"] === 2) {
                    applyBtn = `<button class="btn btn-sm ${isOpen ? "btn-success applyBtns" : "btn-danger disabled"}" data-courseid="${course._versionId}" data-workflowid="${course.WorkflowId}"><span class=""><i class="fa fa-${isOpen ? "check" : "times"}"></i></span> Apply</button>`;
                } else if (sessionStorage.getItem("orgv") === "1") {
                    // TODO: remove this weird initial check when its not needed - allows for certain v1 courses to use v2 instead
                    if (course["_version"] === 2) {
                        applyBtn = `<button class="btn btn-sm ${isOpen ? "btn-success applyBtns" : "btn-danger disabled"}" data-courseid="${course._versionId}" data-workflowid="${course.WorkflowId}"><span class=""><i class="fa fa-${isOpen ? "check" : "times"}"></i></span> Apply</button>`;
                    } else if (course.Mode.toLowerCase() === "u" && (course.Inst === "dc" || user.EUCitizen === false)) {
                        applyBtn = `
                            <a
                                class="btn btn-sm ${isOpen ? "btn-success" : "btn-danger disabled"}"
                                href="${_pac_}/ugrad/applications/apply.php?ln=e&inst=${course.Inst}&mode=${course.Mode.toLowerCase()}&course=${course.CourseCode}&nat=${user.EUCitizen === false ? "neu" : "eu"}"
                            >
                                <span class=""><i class="fa fa-${isOpen ? "check" : "times"}"></i></span> Apply
                            </a>
                        `;
                    } else {
                        applyBtn = `
                            <a
                                class="btn btn-sm ${isOpen ? "btn-success" : "btn-danger disabled"}"
                                href="${_pac_}/applications/apply.php?ln=e&inst=${course.Inst}&mode=${course.Mode.toLowerCase()}&course=${course.CourseCode}&nat=${user.EUCitizen === false ? "neu" : "eu"}"
                            >
                                <span class=""><i class="fa fa-${isOpen ? "check" : "times"}"></i></span> Apply
                            </a>
                        `;
                    }
                } else {
                    applyBtn = `<button class="btn btn-sm ${isOpen ? "btn-success applyBtns" : "btn-danger disabled"}" data-courseid="${course.CourseId}" data-workflowid="${course.WorkflowId}"><span class=""><i class="fa fa-${isOpen ? "check" : "times"}"></i></span> Apply</button>`;
                }
            } else {
                applyBtn = `<a class="btn btn-sm btn-default ${isOpen ? "" : "disabled"}" href="/register"><i class="fa fa-user-plus"></i> Register to Apply</a>`;
            }

            if(course.IsCourseApplicationLimitReached)
            {
                applyBtn = `<button class="btn btn-sm btn-danger disabled" data-courseid="${course.CourseId}" data-workflowid="${course.WorkflowId}"><span class=""><i class="fa fa-${isOpen ? "check" : "times"}"></i></span> Apply</button>`;
            }

            // Study Mode
            // TODO: remove v1 stuff once v1 is gone!!!
            let smode = "";
            if (sessionStorage.getItem("orgv") === "1") {
                switch (course.course_study_mode) {
                    case "1": smode = "Full-Time"; break;
                    case "2": smode = "Part-Time"; break;
                    // case "3": smode = "Distance Learning"; break;
                    // case "4": smode = ""; break;
                    default: smode = "Other"; break;
                }
                if (smode.length > 0) {
                    smode = `<span class="label label-default">${smode}</span>`;
                }
            } else {
                smode = `<span class="label label-default">${studyModes[course.StudyModeId]}</span>`;
            }

            // Course prospectus
            let prospectus = "";
            if (course.CourseURL && course.CourseURL.length > 0) {
                prospectus = `<a class="btn btn-sm btn-info" href="${course.CourseURL}" target="_blank"><span class=""><i class="fa fa-book"></i></span> Prospectus</a>`;
            }

            // Course Info
            let info = "";
            if (course.Info && course.Info.trim().length > 0) {
                info = `<a class="btn btn-sm btn-info" href="#" data-toggle="modal" data-target="#infoModal" data-title="${escapeQuotes(course.Name)}" data-info="${escapeQuotes(course.Info)}"><span class=""><i class="fa fa-info-circle"></i></span> Info</a>`;
            }

            resultsHtml += `
                <div class="list-group-item">
                    <div class="row">
                        <div class="col-xs-12 col-md-6 col-lg-8">
                            <div class="row">
                                <div class="col-xs-12">
                                    <h4 class="list-group-item-heading">
                                        ${course.Name}
                                        <small>${course.CourseCode}</small>
                                    </h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="small">
                                        ${course.IsCourseApplicationLimitReached?isApplicationLimitReachedText:isApplicant?user.EUCitizen?isEUOpenText:isNonEUOpenText:isEUOpenText+"\r\n"+isNonEUOpenText}
                                        <span class="label label-warning">${course.CourseType}</span>
                                        ${smode}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-4 text-right">
                            <span>
                                ${prospectus}
                                ${info}
                                ${applyBtn}
                            </span>
                        </div>
                    </div>
                    ${course.CourseNotice ? `<div class="pac-notice">${course.CourseNotice}</div>` : ""}
                </div>
            `;
        });
        // If many results, include button to jump to top of list
        if (results.length > 10) {
            resultsHtml += `<a id="returnToTopBtn" class="list-group-item clearfix btn btn-default"><i class="fa fa-chevron-up"></i> &nbsp;&nbsp; Retun to top &nbsp;&nbsp; <i class="fa fa-chevron-up"></i></a>`;
        }
    } else {
        resultsHtml += "<p class=\"list-group-item clearfix\">No results found.</p>";
    }
    resultsHtml += "</div>";
    $("#courseResults").html(resultsHtml);
    scrollToTopOfResults();
}

function buildAdvancedSearchBox() {
    loading.block();
    let orgId = Number.parseInt(urlHelper.getUrlParam(2));
    instituteService.getOrganisationById(orgId).then(function(organisation) {
        if (typeof organisation.Name === "undefined") {
            throw new Error("Invalid institute");
        }
        sessionStorage.setItem("orgv", organisation.Version);
        // TODO: remove v1 stuff once v1 is gone!!!
        // Check if org is on v1 or v2
        
        if (organisation.Version === "1" || pageSettingsHelper.isParentInstitute(organisation.OrganisationId)) {   
            $("#availabilityOption").remove(); // No need for this option
            // $("#facultyOption").remove(); // No need for this option
            $("#departmentOption").remove(); // No need for this option

            // Build course type options
            $("#courseTypeSelect").empty();
            $("#courseTypeSelect").append(`<option value="0">Any</option>`);
            $("#courseTypeSelect").append(`<option value="2">Taught</option>`);
            $("#courseTypeSelect").append(`<option value="1">Research</option>`);
            $("#courseTypeSelect").append(`<option value="3">Undergraduate</option>`);
            $("#courseTypeSelect").append(`<option value="8">Continuing Education</option>`);

            // Build faculties
            // $("#facultySelect").append(`<option value="0">Any</option>`);
            // v1Service.getFaculties(orgId).then(function(faculties) {
            //     faculties.forEach(function (faculty) {
            //         $("#facultySelect").append(`<option value="${faculty.FacultyId}">${faculty.Name}</option>`);
            //     });

            //     // Build Departments
            //     v1Service.getDepartments(orgId).then(function(departments) {
            //         $("#departmentSelect").append(`<option value="0">Any</option>`);
            //         if (facultyId === 0) {
            //             departments.forEach(function (department) {
            //                 $("#departmentSelect").append(`<option value="${department.DepartmentId}">${department.Name}</option>`);
            //             });
            //         } else {
            //             departments.forEach(function(department) {
            //                 if (department.FacultyId === facultyId) {
            //                     $("#departmentSelect").append(`<option value="${department.DepartmentId}">${department.Name}</option>`);
            //                 }
            //             });
            //         }
            //     });
            // });
            loading.release();
        } else {
            instituteService.getCourseTypesByUniversityId(organisation.UniversityId).then(function (types) {
                $("#courseTypeSelect").empty();
                $("#courseTypeSelect").append(`<option value="0">Any</option>`);
                types.forEach(function (type) {
                    $("#courseTypeSelect").append(`<option value="${type.CourseTypeId}">${type.Name}</option>`);
                });
                buildFaculties();
                buildDepartments();
            }).fail(function(err) {
                logger.error(err);
                bootoast.toast({
                    message: "An unknown error has occurred",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
            }).always(function(e){
                loading.release();
            });
        }
    }).fail(function(err) {
        logger.error(err);
        loading.release();
        bootoast.toast({
            message: "An unknown error has occurred",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    });
}

/**
 * id = facultyId obtained from faculty dropdown
 */
function buildDepartments(id = 0) {
    $("#departmentSelect").empty();
    $("#departmentSelect").append(`<option value="0">Any</option>`);
    let facultyId = Number.parseInt(id);
    let orgId = Number.parseInt(urlHelper.getUrlParam(2));
    instituteService.getOrganisationById(orgId).then(function(organisation) {
        if (typeof organisation.Name === "undefined") {
            throw new Error("Invalid institute");
        }
        instituteService.getDepartmentsByUniversityId(organisation.UniversityId).then(function(departments) {
            if (facultyId === 0) {
                departments.forEach(function (department) {
                    $("#departmentSelect").append(`<option value="${department.DepartmentId}">${department.Name}</option>`);
                });
            } else {
                departments.forEach(function(department) {
                    if (department.FacultyId === facultyId) {
                        $("#departmentSelect").append(`<option value="${department.DepartmentId}">${department.Name}</option>`);
                    }
                });
            }
        }).fail(function (err) {
            logger.error(err);
            bootoast.toast({
                message: "An error has occurred.",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
        }).always(function(e){
            loading.release();
        });
    }).fail(function (err) {
        logger.error(err);
        loading.release();
        bootoast.toast({
            message: "An error has occurred.",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    })
}

function buildSubInstituteBox(organisationId) {
    let children = pageSettingsHelper.getChildInstitutesByOrganisationId(organisationId);
    children.sort();  
    //ensure sub institutes are always displayed in same order by first creating list then filling in values from async calls
    children.forEach(function(organisationId){
        $("#subInstitute").append(`<li ><a id="sub${organisationId}" href="/institute/${organisationId}"></a></li>`);
    });  
    children.forEach(function(organisationId){
        instituteService.getOrganisationById(organisationId).then(function(organisation) {
            let listItemId = "sub" + organisation.OrganisationId;
            $("#" + listItemId).text(`${organisation.Name}`);
        })        
    });         
}

function prepareAdditionalCourseModal(username, OrganisationId, WorkflowId, coursesInBatch) {
    $("#courseBatchModalCourseTable > tbody").html("");
    let deferred = $.Deferred();
    $.when( applyService.getCoursesByOrganisationIdWorkflowId(OrganisationId, WorkflowId), 
            applyService.getApplicationsPerBatchLimitByCourseId(coursesInBatch[0].CourseId),
            applyService.getUserByUserName(username))
        .done(function (coursesByOrganisationId, applicationsPerBatchLimit, applicantInfo) {
            var limit = applicationsPerBatchLimit.AppPerBatchLimit ? applicationsPerBatchLimit.AppPerBatchLimit : 3;

            let now = new Date();
            now.setHours(0, 0, 0, 0);

            let courseAutoComplete = [];
            let courseNameAndIds = {};

            let batchCourses = []

            coursesInBatch.forEach(function (elem) {
                batchCourses.push(elem.CourseId);
                addRowToCourseBatch(elem.CourseId, elem.Name, elem.CourseCode, false);
            })

            let existingCoursesInBatch = coursesInBatch.map(x => x.CourseId);

            if (limit == coursesInBatch.length) {
                $("#courseBatchModalAddAdditionalCourses").hide();
            }
            $(".additional-course-counter-max").text(limit);
            let modalTitle = pageSettingsHelper.getBatchModalTitle(OrganisationId, limit);
            if(limit == 1)
            {             
                $("#courseBatchModal .modal-title").text(modalTitle);
                $(".multiple-courses-true").hide();
                $(".multiple-courses-false").show();
            }
            else
            {
                $("#courseBatchModal .modal-title").text(modalTitle);
                $(".multiple-courses-true").show();
                $(".multiple-courses-false").hide();
            }

            for (let i = 0, len = coursesByOrganisationId.length; i < len; i++) {
                if(existingCoursesInBatch.indexOf(coursesByOrganisationId[i].CourseId) > -1) continue;
                if(applicantInfo.EUCitizen)
                {
                    if(!applyService.isCourseOpen(coursesByOrganisationId[i].EUOpeningDate, coursesByOrganisationId[i].EUClosingDate))
                        continue;
                }
                else
                {
                    if(!applyService.isCourseOpen(coursesByOrganisationId[i].NonEUOpeningDate, coursesByOrganisationId[i].NonEUClosingDate))
                        continue;
                }

                let name = (coursesByOrganisationId[i].CourseCode + " - " + coursesByOrganisationId[i].Name).trim();
                courseAutoComplete.push(name);
                courseNameAndIds[name] = coursesByOrganisationId[i].Id;
            }

            $("#courseBatchModalCourseSearch").typeahead({
                source: courseAutoComplete,
                autoSelect: false,
                items: 'all',
                afterSelect: function (item) {
                    if ($('#courseBatchModalCourseTable > tbody > tr').length >= limit) {
                        bootoast.toast({
                            message: "Maximum amount of additional courses already reached!",
                            timeout: false,
                            type: "danger",
                            icon: "alert"
                        });
                    } else {
                        if (typeof courseNameAndIds[item] !== "undefined") {
                            if ($("#courseBatchModalCourseTable tr[data-courseid='" + courseNameAndIds[item] + "']").length == 0)
                                applyService.getCourseByCourseId(courseNameAndIds[item]).then(function (course) {
                                    addRowToCourseBatch(course.CourseId, course.Name, course.CourseCode);
                                    delete courseNameAndIds[item];
                                    $("#courseBatchModalCourseSearch").val("");
                                });
                        }
                    }
                }
            });
            
            deferred.resolve();
        });
    return deferred;
}

/****************************************************************
 *                          LOCAL FUNCTIONS
 ****************************************************************/

function getInstFromAllById(all, id) {
    for (let ii = 0; ii < all.length; ii++) {
        const inst = all[ii];
        if (inst.Id === id) {
            return inst;
        }
    }
}

function scrollToTopOfResults() {
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
    try {
        document.getElementById("courseResultStartPoint").scrollIntoView({
            behaviour: "smooth",
            block: "center",
            inline: "center"
        });
    } catch (error) {
        try {
            document.getElementById("courseResultStartPoint").scrollIntoView();
        } catch (error) {
        }
    }
}

function buildFaculties() {
    $("#facultySelect").append(`<option value="0">Any</option>`);
    let uniId = Number.parseInt(urlHelper.getUrlParam(2));
    instituteService.getOrganisationById(uniId).then(function(organisation) {
        if (typeof organisation.Name === "undefined") {
            throw new Error("Invalid institute");
        }
        instituteService.getFacultiesByUniversityId(organisation.UniversityId).then(function (faculties) {
            faculties.forEach(function (faculty) {
                $("#facultySelect").append(`<option value="${faculty.FacultyId}">${faculty.Name}</option>`);
            });
        });
    });
}

function populateCountriesSelect(countries){
    let select = $("#countryid");
    countries.forEach(function(country) {
        $(select).append(`<option value="${country.CountryId}">${country.Name}</option>`)
    });
}

/**
 * Adds a row (tr) to the html table of the batch entry modal
 * 
 * @param {number} id - the id number used to select the tr element
 * @param {string} name - the name of the course selected
 * @param {string} code - the course code for the selected course
 * @param {boolean} [del=true] - flag to allow deletable row (the first initial row can not be deleted)
 */
function addRowToCourseBatch(id, name, code, del = true) {
    $("#courseBatchModalCourseTable > tbody").append(`
        <tr data-courseid="${id}">
            <td>${name}</td>
            <td class="text-center">${code}</td>
            <td class="text-center">${del === true ? "<a data-courseid=\"" + id + "\" id=\"courseBatchModalCourseCloseLink-" + id + "\" class=\"text-danger courseBatchModalCourseCloseLink\" href=\"#\"><i class=\"fa fa-close\" aria-hidden=\"true\"></i></a>" : ""}</td>
        </tr>
    `);

    $(".additional-course-counter-current").text($("#courseBatchModalCourseTable > tbody tr").length);
}

function deleteRowFromCourseBatch(id) {
    $('#courseBatchModalCourseTable tr[data-courseid="' + id + '"]').remove();
    $(".additional-course-counter-current").text($("#courseBatchModalCourseTable > tbody tr").length);
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    name: name,
    blurb: information,
    links: links,
    displayMeta: displayMeta,
    populateSearchResults: populateSearchResults,
    scrollToTopOfResults: scrollToTopOfResults,
    buildAdvancedSearchBox: buildAdvancedSearchBox,
    buildDepartments: buildDepartments,
    buildSubInstituteBox: buildSubInstituteBox,  
    populateCountriesSelect: populateCountriesSelect,
    prepareAdditionalCourseModal: prepareAdditionalCourseModal,
    deleteRowFromCourseBatch: deleteRowFromCourseBatch
}