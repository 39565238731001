import "../events/completePasswordResetEvents";
import loading from "../helpers/loading";
let userService = require("../services/userService");

let Token = $("#token").val();

if (Token && Token != "") {
    loading.block();

    userService.validateToken({ Token: Token })
    .then(function (token) {
        
    }).fail(function() {
        $(".token-error-message p").html('Token is invalid or expired. Please <a href="/reset">Click here</a> to reset your password.');
        $(".token-error-message").show();
        $(".password-reset-form").hide();
    }).always(function(){
        loading.release();
    });
}
else {
    $(".token-error-message p").html('Password can not be changed without the password reset token. Please check the link in the E-mail you have received. <br /> If you want to create a new password reset request please <a href="/reset">Click here</a>.');
    $(".token-error-message").show();
    $(".password-reset-form").hide();
}