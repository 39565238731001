const summaryBuilder = require('../builders/summaryBuilder');
const applyService   = require('../services/applyService');
const urlHelper = require("../helpers/urlHelper");
const loadingHelper = require("../helpers/loading");
// const logger = require('../../logger');

var batchId = urlHelper.getQueryVariable('batchId');
var applicationId = urlHelper.getQueryVariable('applicationId');
var errors = urlHelper.getQueryVariable('errors');

// Error codes in showErrorBox refer to 'if' statement blocks, so B1 is the first 'if' statement

loadingHelper.block();
if (batchId && applicationId) {
    // No payment (maybe agent or free course)
    applyService.submitBatch(batchId)
        .then(function(batch) {
            if (batch) {
                $(".paymentNoticeMessage").text("Your application was successfully submitted!");
                $("#paymentNotice").show();
                summaryBuilder.generateAppLink(applicationId);
            } else {
                summaryBuilder.showErrorBox("Submitting application failed", batchId);
            }
            loadingHelper.release();
        })
        .fail(function(err) {
            logger.error(err);
            try {
                summaryBuilder.showErrorBox(err.responseJSON.Exception.originalError.info.message, batchId);
            } catch (error) {
                logger.error(error);
                try {
                    summaryBuilder.showErrorBox(err.responseJSON.Exception.originalError.message, batchId);
                } catch (error) {
                    logger.error(error);
                    summaryBuilder.showErrorBox("Unhandled error (B1)", batchId);
                }
            } finally {
                loadingHelper.release();
            }
        })
} else {
    if (errors) {
        errors = decodeURIComponent(errors);
        errors = errors.slice(1, -1);
        // errorsArray = errors.split(';');
        summaryBuilder.showErrorBox(errors);
    } else {
        summaryBuilder.showErrorBox("Unhandled error (B2)");
    }
    loadingHelper.release();
}