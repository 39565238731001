//Page Settings helper for specific page settings by institute
//ALL institute hardcoding here

let NSROrganisationId = 23;     //NSR helpdesk
let ParentChildInstitutes = [{"Parent":22, "Children":[5,6]}];     //SETU or any other institute groups
let PMEOrganisations = [10,12];


/**
 * 
 * @param {number} organisationId 
 */
function isPMEOrganisation(organisationId) {
    if (PMEOrganisations.includes(organisationId))
        return true;
    return false;   
}

/**
 * Set text  for PME landing page 
 * 
 * @param {number} organisationId 
 * @param {object} settingsObject
 */
function PMELandingSetUp(organisationId, settingsObject) {
    if ( PMEOrganisations.includes(organisationId)) {     
            if(settingsObject.LandingPageApply != null)
                $('#blurbContent').prepend(setApplyButton(settingsObject.LandingPageApply));
    }
    
}

/**
 * For institutes with Apply button on Landing page we need to display appropriate Apply button/message  
 * based upon whether applicant is logged in and closing date has passed or not
 *  @param {object} apply - object containing  { Preamble (preceding text), closed message, apply button URL, closingDate}
 */
function setApplyButton(apply) { 
    var applyText, applyBlock = "";  
    var closing =new Date(apply.ClosingDate), today=new Date();
    if (today < closing) {
        var e=localStorage.getItem("SessionToken");
        if(e&&36===e.length){
            applyText = `<a href=${apply.URL} class="btn btn-block btn-success">Apply</a>`;
        }
        else  applyText = "<div class='alert alert-danger text-center'> Register/Login to Apply</div>";
    }
    else applyText = "<div class='alert alert-danger text-center'>" + apply.ClosedMessage + "</div>";
    applyBlock = apply.Preamble + "<div class='row'><div class='col-xs-offset-4 col-xs-4'>" + applyText + "</div></div>";
    return applyBlock;
}


function getNoticesDOM(alerts)
{
    var notices = "";
    if(alerts != null && alerts.length > 0)
    {
        alerts.forEach(function(alert){
            notices += `<p class='${alert.AlertClass}'>  ${alert.Message}  </p>`;   
        });
    }
    return notices;    
}



/**
 * Set text  for Helpdesk landing page according to passed parameter organisation Id
 * 
 * @param {number} organisationId
 * 
 */
function helpdeskLandingSetUp(organisationId) {
    if (organisationId==NSROrganisationId){
        setCourseText(organisationId);
        $('#departmentTxt').text("College/HEI");  
        $('#courseSearchTerm').attr('placeholder','Find College/HEI');
        $('#availabilityOption').hide();
        $('#helpQuestion').hide();
        $('#faqBtn').css({'height': '80px', 'font-weight': 'bold', 'font-size': '150%', 'padding-top': '20px','white-space': 'normal','display': 'block','word-wrap': 'break-word'});      
    }    
}

/**
 * Returns text parameter for variable modalTitle
 * 
 * @param {number} organisationId
 * @return text parameter for variable modalTitle
 */
function getBatchModalTitle(organisationId, limit) {
    let modalTitle = limit==1? "Course Review":"Multiple Program Titles";
    if (organisationId==NSROrganisationId){
        modalTitle = limit==1? "College/HEI Review":"Multiple College/HEIs";
    }
    return  modalTitle;    
}

/**
 * Set text  on web pages for variable course according to organisation if passed in otherwise set from storage 
 * Set storage parameter for variable course according to organisation if passed in. Always default to 'course' 
 * @param {number} organisationId
 * 
 */
function setCourseText(organisationId=null) {
    if (organisationId == null) {
        let courseTxt = getSavedCourseString();
        $('.courseTxt').text(courseTxt);
        $('.courseTxtTitle').text(courseTxt.charAt(0).toUpperCase() + courseTxt.substring(1));
    }
    else if (organisationId==NSROrganisationId){
        $('.courseTxt').text("college/HEI");  
        $('.courseTxtTitle').text("College/HEI");
        localStorage.setItem("courseStr", "college/HEI"); 
    } 
    else localStorage.setItem("courseStr", "course");  
}

/**
 * Returns text parameter for variable course
 * 
 * @param {number} organisationId
 * @return text parameter for variable course
 */
function getCourseStringByOrganisationId(organisationId) {
    let courseString = 'course';
    if (organisationId==NSROrganisationId)
    courseString = 'college/HEI';
    return courseString;
}

function getSavedCourseString() {
    let courseStr = localStorage.getItem("courseStr");
    if (courseStr == null) courseStr = 'course';
    return courseStr;
}

/**
 * Is this organisation a parent of another institute?
 * 
 * @param {number} organisationId 
 */
function isParentInstitute(organisationId) {
    let parents = ParentChildInstitutes.map(each => each.Parent);
    if (parents.includes(organisationId)) return true;
    return false;
}

/**
 * Is this organisation a child (sub institute) of any institute?
 * 
 * @param {number} organisationId 
 */
function isChildInstitute(organisationId) {
    let childrenArray = ParentChildInstitutes.map(each => each.Children);
    let child = false;
    childrenArray.forEach((children) => {
        if (children.includes(organisationId)) child = true;
    });   
    return child;
}

/**
 * Return array of child institutes for a specified parent institute 
 * 
 * @param {number} organisationId 
 */
function getChildInstitutesByOrganisationId(organisationId) {
    let parentChildObj = ParentChildInstitutes.find(thisOne => thisOne.Parent === organisationId);
    return parentChildObj.Children;
}



/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    PMELandingSetUp: PMELandingSetUp,
    isPMEOrganisation: isPMEOrganisation,
    getNoticesDOM: getNoticesDOM,
    helpdeskLandingSetUp: helpdeskLandingSetUp,
    getCourseStringByOrganisationId: getCourseStringByOrganisationId,
    getSavedCourseString: getSavedCourseString,
    getBatchModalTitle: getBatchModalTitle,
    setCourseText: setCourseText,
    isChildInstitute: isChildInstitute,
    isParentInstitute: isParentInstitute,
    getChildInstitutesByOrganisationId: getChildInstitutesByOrganisationId
}