import "../events/registerEvents";

const registerBuilder = require("../builders/registerBuilder");
const registerService = require("../services/registerService");
const loadingHelper = require('../helpers/loading');

$.validator.setDefaults({
    // debug: true,
    onsubmit: false,
    errorClass: "pac-form-error",
    validClass: "pac-form-valid"
});

$.validator.addMethod("tristate", function(value, element, param) {
    switch(value){
        case "true":
        case "false": return true;
        default: return false;
    }
}, "Please select YES or NO.");

loadingHelper.block();

registerService.getCountries()
.then(function(countries){
    registerBuilder.populateCountriesSelect(countries);
    loadingHelper.release();
});

registerBuilder.toggleAgentApplicantDetails();