const applyService = require('../services/applyService');
const dashboardBuilder = require('../builders/dashboardBuilder');
const loadingHelper = require('../helpers/loading');

/*****************************************************************
 *                          EVENTS
 ****************************************************************/

$("#filtersApplyBtn").on("click", function(e) {
    e.preventDefault();
    dashboardBuilder.populateApplications(window.allApps, dashboardBuilder.getFilters());
    let totalAppliedFilters = 0;
    if ($("#appnameFilterBox").val().length > 0) {
        totalAppliedFilters++;
    }
    if ($("#orgFilterSelect").val() !== "0") {
        totalAppliedFilters++;
    }
    if ($("#statusFilterSelect").val() !== "0") {
        totalAppliedFilters++;
    }
    if ($("#yearFilterSelect").val() !== "0") {
        totalAppliedFilters++;
    }
    if ($("#appnoFilterBox").val().length > 0) {
        totalAppliedFilters++;
    }
    if ($("#createFrom").val().length > 0 || $("#createTo").val().length > 0) {
        totalAppliedFilters++;
    }
    if ($("#actionFrom").val().length > 0 || $("#actionTo").val().length > 0) {
        totalAppliedFilters++;
    }
    if (totalAppliedFilters > 0) {
        $("#filterCount").text(totalAppliedFilters);
    } else {
        $("#filterCount").text("");
    }
});

$("#filtersClearBtn").on("click", function(e) {
    e.preventDefault();
    $("#appnameFilterBox").val("");
    $("#orgFilterSelect").val("0");
    $("#statusFilterSelect").val("0");
    $("#yearFilterSelect").val("0");
    $("#appnoFilterBox").val("");
    $("#createFrom").val("");
    $("#createTo").val("");
    $("#actionFrom").val("");
    $("#actionTo").val("");
    $("#filterCount").text("");
    dashboardBuilder.populateApplications(window.allApps, {});
});

$("#toggleFilters").on("click", function(e) {
    e.preventDefault();
    $("#filtersBox").toggle(500);
});

$("#appnoFilterBoxClearBtn").on("click", function() {
    $("#appnoFilterBox").val("");
});

$("#createFromClearBtn").on("click", function() {
    $("#createFrom").val("");
});

$("#createToClearBtn").on("click", function() {
    $("#createTo").val("");
});

$("#actionFromClearBtn").on("click", function() {
    $("#actionFrom").val("");
});

$("#actionToClearBtn").on("click", function() {
    $("#actionTo").val("");
});

$(document.body).on("click", ".applicationDeleteButton", function(e) {
    e.preventDefault();
    let batchId = $(this).data("batchid");
    let appId = $(this).data("applicationid");
    $("#deleteApplicationConfirmBtn").data("batchid", batchId);
    $("#deleteApplicationConfirmBtn").data("applicationid", appId);
    $("#deleteApplicationConfirmApplicationName").text($("#appBtn" + appId).text());
    $("#deleteApplicationConfirmModal").modal("show");
});

$(document.body).on("click", "#deleteApplicationConfirmBtn", function(e) {
    e.preventDefault();
    let batchId = $(this).data("batchid");
    let appId = $(this).data("applicationid");
    loadingHelper.block();
    applyService.deleteAppFromBatch(batchId, appId).then(function() {
        location.reload();
    }).always(function() {
        loadingHelper.release();
    });
});