const moment = require('moment');
const applyService = require('../services/applyService');
const userService = require('../services/userService');
const applicationDetailsService = require('../services/applicationDetailsService');
const urlHelper = require('../helpers/urlHelper');
const pageSettingsHelper = require("../helpers/pageSettingsHelper");
const loading = require('../helpers/loading');
const fileService = require('../services/fileService');
const fileContainerService = require('../services/fileContainerService');
const registerService = require('../services/registerService');
const logger = require('../helpers/clientLogger');
// const { application } = require('express');

/****************************************************************
 *                          EXPORTED FUNCTIONS
 ****************************************************************/

function batchForm(batchId, appId, selectedTabIndex) {
    loading.block();
    $.when(
        (appId ? applyService.getAttributeValuesBatchFirstApp(batchId, appId) : applyService.getAttributeValuesBatch(batchId)),
        registerService.getCountries()
    ).done(function(attributes, countries) {

        if(attributes.length == 0)
        {
            bootoast.toast({
                message: "No applications in batch",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
            loading.release();
            return 0;
        }

        populateCountriesSelect(countries);
        fillcardHolderCountry(countries, "#cardholderMobilePhoneCountry");
        fillcardHolderCountry(countries, "#agentCardholderMobilePhoneCountry");

        var isUserEUCitizen = null;
        userService.me()
        .then(function (user) {
            isUserEUCitizen = user.UserTypeId == 1?user.EUCitizen:null;

            if(user.UserTypeId == 1)
            {
                isUserEUCitizen = user.EUCitizen;
                $('#cardholderEmailAddress').val(user.UserName);
                $('#cardholderAddressLine1').val(user.AddressLine1);
                $('#cardholderAddressLine2').val(user.AddressLine2);
                $('#cardholderCity').val(user.City);
                $('#cardholderPostalCode').val(user.PostalCode);
                $('#cardholderCountryCode').val(user.CountryId);
            }
            else
            {
                $('#agentCardholderEmailAddress').val(user.UserName);
                $('#agentCardholderAddressLine1').val(user.AddressLine1);
                $('#agentCardholderAddressLine2').val(user.AddressLine2);
                $('#agentCardholderCity').val(user.City);
                $('#agentCardholderPostalCode').val(user.PostalCode);
                $('#agentCardholderCountryCode').val(user.CountryId);

                applicationDetailsService.getApplicantByUserId(attributes[0].UserId).then(function (applicant) {
                    isUserEUCitizen = applicant.EUCitizen;
                });
            }

        })
        .fail(function(err){
            logger.error(err);
            loading.release();
            bootoast.toast({
                message: "Error retrieving user profile data.",
                timeout: false,
                type: "danger",
                icon: "alert"
            });            
        });

        $("#applicationCourseTabs").html("");
        $("#applicationCourseTabsContent").html("");
        // if (attributes[0].BatchStatusId >= 40) {
        //     $("#secondaryApp").data("submitted", true);
        //     $("#secondaryApp").html("<span class='badge'>Submitted</span> Editing additional course(s).");
        // }
        let courseArray = [];
        let courseStr = "course";
        attributes.forEach(function(application) {
            pageSettingsHelper.setCourseText(application.OrganisationId);
            courseStr =  pageSettingsHelper.getCourseStringByOrganisationId(application.OrganisationId); 
            courseArray.push(applyService.getCourseByCourseId(application.CourseId));
        })
        
        $.when(...courseArray).done(function(...courses) {
            let venuesArray = [];
            attributes.forEach(function(application) {
                venuesArray.push(applyService.getCourseVenuesByCourseId(application.CourseId));
            })
            $.when(...venuesArray).done(function(...venues) {

                // Check if apps have been re-ordered
                let hasApplicationBeenReordered = Number.parseInt(urlHelper.getQueryVariable("id"));
                if (Number.isInteger(hasApplicationBeenReordered)) {
                    hasApplicationBeenReordered = true;
                } else {
                    hasApplicationBeenReordered = false;
                }
                // Get highest fee amount
                let highestFee = 0;
                for (course of courses) {
                    let fee = parseInt(course.ApplicationFeeAmount);
                    if (fee > highestFee) {
                        highestFee = fee;
                    }
                }

                if(highestFee > 0) {
                    $("#application-fee").show();
                    $(".application-fee-amount").text(highestFee);
                    $('#agentApplicationModalRequestPayment').show();
                }
                else
                {
                    $("#application-fee").hide();
                    $('#paymentInfo').addClass('hide');
                    $('#agentApplicationModalRequestPayment').hide();
                }

                let questionaire = sortAttributeDataFromAllElements(attributes);
                if (attributes.HasInvalidatedAttributes === false && questionaire.length > 1) {
                    $("#multiCourseInfo").html(`
                        <div class="alert alert-warning">
                            <div>
                                When batch applying to multiple ${courseStr}s,
                                only questions specific or unique to the additional ${courseStr}(s) will show in their application form.
                                All other questions will be taken from the answers you give in the preceeding application form(s).
                            </div>
                            <div class="small">
                                <i>* NOTE *</i> If the additional ${courseStr}(s) have no specific or unique questions then they will just appear blank.
                            </div>
                        `);
                }
                    // First create the tabs for the apps
                questionaire.forEach(function(questionSet, index) {
                    let cBtn = ""; // Close/delete Button
                    if (attributes.length > 1 && attributes[index].StepStatusId === 17) { // 17 = Unsubmitted
                        cBtn = `<button class="btn btn-sm btn-link btn-danger pull-right pac-app-tab-close-btn" data-app-id="${attributes[index].ApplicationId}"><i class="fa fa-times"></i></button>`;
                    }
                    let disabledTabText = "";
                    if (hasApplicationBeenReordered === true) {
                        if (attributes[0].StepStatusId === 17) { // 17 = Unsubmitted
                            if (index > 0 && attributes[index].StepStatusId !== 17) {
                                disabledTabText = " disabled";
                            }
                        } else {
                            if (index > 0) {
                                disabledTabText = " disabled";
                            }
                        }
                    } else {
                        if (attributes[index].StepStatusId !== 17) {
                            disabledTabText = " disabled";
                        }
                    }

                    
                    var courseEUOpen = applyService.isCourseOpen(courses[index].EUOpeningDate, courses[index].EUClosingDate);
                    var courseNonEUOpen = applyService.isCourseOpen(courses[index].NonEUOpeningDate, courses[index].NonEUClosingDate);

                    var statusLabel = '<span class="small"><span class="label label-success">Submitted</span></span>';

                    if(attributes[index].StepStatusId == 17)
                    {
                        var statusLabel = '<span class="small"><span class="label label-default">Unsubmitted</span></span>';
                        if(attributes[index].IsCourseApplicationLimitReached)
                        {
                            statusLabel= '<span class="small"><span class="label label-danger">Application limit reached</span></span>';
                        }
                        else
                        {
                            if((isUserEUCitizen && !courseEUOpen) || (!isUserEUCitizen && !courseNonEUOpen) )
                            {
                                statusLabel= `<span class="small"><span class="label label-danger">${courseStr} closed</span></span>`;
                            }
                        }
                    }

                    $("#applicationCourseTabs").append(`
                        <li role="presentation" class="${index === 0 ? "active" : ""}${disabledTabText}">
                            <a href="#courseTabContent${index}" aria-controls="home" role="tab" data-toggle="tab">
                                <sup id="tabSupErrors${index}" class="hidden" style="color: #d43f3a">Errors</sup>
                                <sup id="tabSup${index}" class="hidden" style="color: #5bc0de">Changes</sup>
                                <span id="tab-${index}-CourseCode">${attributes[index].CourseCode}</span>
                                ${statusLabel}
                                ${cBtn}
                            </a>
                        </li>
                    `);
                    $("#applicationCourseTabsContent").append(`
                        <div id="courseTabContent${index}" class="tab-pane ${index === 0 ? "active" : ""}">
                            <form id="applicationForm${index}" class="apply_from" data-application-id="${attributes[index].ApplicationId}" data-is-application-enabled="${attributes[index].StepStatusId == 17 ? "true" : "false"}"></form>
                        </div>
                    `);
                });
                pageSettingsHelper.setCourseText();       
                $("#applicationCourseTabs").append(`
                    <li role="presentation">
                        <a id="openCourseBatchModalBtn" href="#" role="tab">Add ${courseStr}</a>
                    </li>
                `);
            
                if (typeof attributes[0].Attributes[0] !== "undefined") {
                    $("#applicationForm0").data("Organisation", attributes[0].Attributes[0].AppOrgId);
                }
                questionaire.forEach(function(questionSet, questionaireIndex) {
                    let isAppDisabled = (attributes[questionaireIndex].BatchStatusId >= 40 && attributes[questionaireIndex].StepStatusId !== 17);
                    // If attributes of a submitted app are invalidated then do not disable and allow editing
                    if (!questionSet.isValid) {
                        isAppDisabled = false;
                    }
                    if (hasApplicationBeenReordered === true && questionaireIndex > 0 && attributes[0].StepStatusId !== 17) {
                        isAppDisabled = true;
                    }
                    $("#applicationForm" + questionaireIndex).data('CourseId', courses[questionaireIndex].CourseId);
                    $("#applicationForm" + questionaireIndex).data('ApplicationId', attributes[questionaireIndex].ApplicationId);
                    $("#applicationForm" + questionaireIndex).data("VenueId", attributes[questionaireIndex].VenueId)
                    $("#applicationForm" + questionaireIndex).data('ApplicationNumber', attributes[questionaireIndex].ApplicationNumber);
                    $("#applicationForm" + questionaireIndex).data('BatchStatusId', attributes[questionaireIndex].BatchStatusId);
                    $("#applicationForm" + questionaireIndex).data('ApplicantEmail', attributes[questionaireIndex].ApplicantEmail);
                    $("#applicationForm" + questionaireIndex).data('TemplateVersionId', attributes[questionaireIndex].TemplateVersionId);
                    $("#applicationForm" + questionaireIndex).data('WorkflowId', attributes[questionaireIndex].WorkflowId);
                    if(attributes[questionaireIndex].AgentUserId)
                        $("#applicationForm" + questionaireIndex).data('AgentUserId', attributes[questionaireIndex].AgentUserId);
                    if (typeof attributes[questionaireIndex].Attributes[0] !== "undefined") {
                        $("#applicationForm" + questionaireIndex).data('OrganisationId', attributes[0].Attributes[0].AppOrgId);
                    }
                    $("#courseTabContent" + questionaireIndex + " > form").html(`
                        <div class="row">
                            <div class="col-xs-12">
                            ${attributes[questionaireIndex].IsCourseApplicationLimitReached?'<h4><div class="alert alert-danger">Application limit for this ' + courseStr + ' has been reached. Application can no longer be submitted. </div></h4>':""}

                                <div class="applicationCourseTabsContentCourseInfo">
                                    <div class="h1 courseName">${courses[questionaireIndex].Name}</div>
                                    <div class="h4 courseCode">${courses[questionaireIndex].CourseCode}</div>
                                    <div class="h4 selectedVenue">${attributes[questionaireIndex].VenueId?"Venue: "+attributes[questionaireIndex].VenueName:""}</div>
                                    <div class="courseInfo">${courses[questionaireIndex].Info}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-4 col-md-3">
                                <div id="pagesNavAffix${questionaireIndex}" class="">
                                    <button
                                        class="navbar-toggle collapsed"
                                        data-toggle="collapse"
                                        data-target="#pagesNavCollapse${questionaireIndex}"
                                        type="button"
                                        aria-expanded="false">
                                            <span class="btn btn-primary">
                                                <span class="sr-only">Toggle navigation</span>
                                                <i class="fa fa-bars"></i>
                                                Navigation
                                            </span>
                                    </button>
                                    <div id="pagesNavCollapse${questionaireIndex}" class="collapse navbar-collapse" role="navigation">
                                        <div class="panel panel-default">
                                            <div class="panel-heading text-center"><Strong>Pages</Strong></div>
                                            <div id="pagesNav" class="panel-body">
                                                <ul id="courseTabContentTabs${questionaireIndex}" class="nav nav-pills nav-stacked">
                                                </ul>
                                            </div>
                                        </div>
                                        <p id="openSupervisorModalBtnBox${questionaireIndex}" class="openSupervisorModalBtnBox text-center">
                                            <a id="openSupervisorModalBtn${questionaireIndex}" class="openSupervisorModalBtn btn btn-block btn-warning pac-apply-action-btn" role="button" data-form-index="${questionaireIndex}"><i class="fa fa-fw fa-lg fa-eye"></i> &nbsp; Request supervisor</a>
                                        </p>
                                        <p id="openRecommendationModalBtnBox${questionaireIndex}" class="openRecommendationModalBtnBox text-center">
                                            <a id="openRecommendationModalBtn${questionaireIndex}" class="openRecommendationModalBtn btn btn-block btn-warning pac-apply-action-btn" role="button" data-form-index="${questionaireIndex}"><i class="fa fa-fw fa-lg fa-thumbs-up"></i> &nbsp; Request referee</a>
                                        </p>
                                        <p id="openVenueModalBtnBox${questionaireIndex}" class="openVenueModalBtnBox text-center">
                                            <a id="openVenueModalBtn${questionaireIndex}" class="openVenueModalBtn btn btn-block btn-${attributes[questionaireIndex].VenueId?"success":"warning"} pac-apply-action-btn" role="button" data-form-index="${questionaireIndex}"><i class="fa fa-fw fa-lg fa-building"></i> &nbsp; <span>${attributes[questionaireIndex].VenueId?"Change":"Select"} venue</span></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-8 col-md-9">
                                <div id="formContainer${questionaireIndex}" class="formContainer">
                                    <p id="invalidMessage${questionaireIndex}" class="invalidMessage"></p>
                                    <form id="applicationForm0" action="#" method="POST" enctype="multipart/form-data" class="form-horizontal apply_from">
                                        <div id="formTabsContent${questionaireIndex}" class="tab-content">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>`);
                    if (attributes[0].HasInvalidatedAttributes === true) {
                        if (questionaireIndex === 0) {
                            $("#applicationForm" + questionaireIndex).data("invalid", "true");
                            $("#invalidMessage" + questionaireIndex).before(`
                                <div class="alert alert-info">
                                    Please provide the requested information. You must click submit changes when you are done.
                                </div>
                            `);
                            // Repurpose the submit button for returning form to assessor
                            $("#applicationFormSubmitBtn").html(`<i class="fa fa-fw fa-lg fa-save"></i> Submit changes`);
                            $("#applicationFormSubmitBtn").data("formindex", 0); // This is hardcoded to first app in batch, this is the invalidated app
                            $("#applicationFormSaveProgressBtn").addClass("hidden");
                            //DEV-2546 - Apply show/hide conditions for invalidated attributes
                            if (attributes[0].IsShowHideConditionsUsed === true) {
                                $("#applicationForm" + questionaireIndex).data("applyShowHideConditions", "true");
                            } else {
                                $("#applicationForm" + questionaireIndex).data("applyShowHideConditions", "false");
                            }
                        }
                    }

                    // Create HTML for pages, to store the attributes in seperated tabs
                    let pageKeys = Object.keys(questionSet.pages);
                    if (Array.isArray(pageKeys) && pageKeys.length > 0) {
                        // $("#courseTabContentTabs").html("");
                        // $("#formTabsContent").html("");
                        pageKeys.forEach(function (pageName, pageIndex) {
                            let page = questionSet.pages[pageName];
                            if(questionSet.isValid){
                                page.PageInstructions = questionSet.pages[pageName][0].Instructions;
                                page.PageName = questionSet.pages[pageName][0].PageName;
                            } else {
                                // Assessor invalidated questionSet
                                page = {
                                    PageInstructions: "",
                                    PageName: "Requested"
                                };
                            }
                
                            $("#courseTabContentTabs" + questionaireIndex).append(`
                                <li id="app${questionaireIndex}-tab${pageIndex}li" class="courseTabContentTabs" role="presentation">
                                    <a  id="app${questionaireIndex}-tabLink${pageIndex}"
                                        class="formTabLinks"
                                        data-toggle="tab"
                                        role="tab"
                                        href="#app${questionaireIndex}-tab${pageIndex}">${page.PageName}</a>
                                </li>
                            `);

                            $("#formTabsContent" + questionaireIndex).append(`<div id="app${questionaireIndex}-tab${pageIndex}" class="tab-pane" role="tabpanel"></div>`);

                            if (page.PageInstructions) {
                                $("#app" + questionaireIndex + "-tab" + pageIndex).prepend(`<div class="pageInstructions pac-apply-form-group">${page.PageInstructions}</div>`);
                            }
                        });
                
                        // Create HTML for each attribute and append to the page/tab HTML (created above)
                        pageKeys.forEach(function (pageName, pageIndex) {
                            let page = questionSet.pages[pageName];
                            
                            if (Array.isArray(page) && page.length > 0) {
                                page.forEach(function(attribute) {
                                    if (hasApplicationBeenReordered) {
                                        if (attributes[0].StepStatusId === 17) { // 17 = Unsubmitted
                                            if (attribute.InheritValueFromMainAppInBatch === false || attributes.length === 1) {
                                                $("#app" + questionaireIndex + "-tab" + pageIndex).append(generateAttributeHtml(attribute, countries, questionaireIndex, isAppDisabled));
                                                if(attribute.AttributeTypeId == 10 || attribute.AttributeTypeId == 14 || attribute.AttributeTypeId == 15) {
                                                    renderAttrtibuteFileList(attribute);
                                                }
                                            }
                                        } else {
                                            $("#app" + questionaireIndex + "-tab" + pageIndex).append(generateAttributeHtml(attribute, countries, questionaireIndex, isAppDisabled));
                                            if(attribute.AttributeTypeId == 10 || attribute.AttributeTypeId == 14 || attribute.AttributeTypeId == 15) {
                                                renderAttrtibuteFileList(attribute);
                                            }
                                        }
                                    } else {
                                        $("#app" + questionaireIndex + "-tab" + pageIndex).append(generateAttributeHtml(attribute, countries, questionaireIndex, isAppDisabled));
                                        if(attribute.AttributeTypeId == 10 || attribute.AttributeTypeId == 14 || attribute.AttributeTypeId == 15) {
                                            renderAttrtibuteFileList(attribute);
                                        }
                                    }
                                });
                            } else {
                                $("#app" + questionaireIndex + "-tab" + pageIndex).html(`<div><span class="bg-success text-center h2">No questions to answer on this page</span></div><br>`);
                            }
                            // Add next/previous buttons
                            // Uses a data- attribute to store the id of the next/previous tab (not the id of tab that it belongs to)
                            if (pageIndex > 0) {
                                $("#app" + questionaireIndex + "-tab" + pageIndex).append(`
                                    <button class="btn btn-default pull-left applyPrevBtn" data-tabid="${pageIndex - 1}" data-formid="${questionaireIndex}"><i class="fa fa-arrow-left"></i>&nbsp; Previous</button>
                                `);
                            }
                            if (pageIndex < Object.keys(questionSet.pages).length - 1) {
                                $("#app" + questionaireIndex + "-tab" + pageIndex).append(`
                                    <button class="btn btn-default pull-right applyNextBtn" data-tabid="${pageIndex + 1}" data-formid="${questionaireIndex}">Next &nbsp;<i class="fa fa-arrow-right"></i></button>
                                `);
                            }
                            
                            if (pageIndex == Object.keys(questionSet.pages).length - 1) {
                                $("#app" + questionaireIndex + "-tab" + pageIndex).append(`
                                <button type="button" id="applicationFormSubmitBtn_bottom" class="btn btn-success pull-right"><i class="fa fa-fw fa-lg fa-paper-plane"></i> Submit application(s)</button>
                                `);
                            }
                            $("#app" + questionaireIndex + "-tab" + pageIndex).append(`
                                <div class="clearfix"></div>
                            `);
                        });
                    } else {
                        $("#formTabsContent" + questionaireIndex).html("&nbsp; No questions found.");
                        $("#pagesNavCollapse" + questionaireIndex).remove();
                    }
                
                    // Manipulate tab CSS
                    $("#app" + questionaireIndex + "-tab0").addClass("active");
                    $("#app" + questionaireIndex + "-tab0li").addClass("active");

                    if ($("#app" + questionaireIndex + "-tab0li a").data("instructions") !== "undefined") {
                        $("#app" + questionaireIndex + "-tab0 > .pageInstructions").show();
                        $("#app" + questionaireIndex + "-tab0 > .pageInstructions").html($("#app" + questionaireIndex + "-tab0li a").data("instructions"));
                    } else {
                        $("#app" + questionaireIndex + "-tab0 > .pageInstructions").hide();
                    }

                    // Initiate tabs
                    $().tab();

                    // Check which attributes to show based on their condition JSON
                    if (questionSet.isValid) {
                        conditionCheck(questionaireIndex);
                    }

                    if (courses[questionaireIndex].SupervisorRequired === true && courses[questionaireIndex].SupervisorAmount != null) {
                        $("#openSupervisorModalBtnBox" + questionaireIndex).show();
                        $(".supervisor-amount").text(courses[questionaireIndex].SupervisorAmount);
                        $(".supervisor-amount").data("departmentId", courses[questionaireIndex].DepartmentId);
                    }
                    if (courses[questionaireIndex].RecommendationRequired === true && courses[questionaireIndex].RecommendationAmount != null) {
                        $("#openRecommendationModalBtnBox" + questionaireIndex).show();
                        $(".recommendation-amount").text(courses[questionaireIndex].RecommendationAmount);
                    }

                    if(attributes[questionaireIndex].AllowApplicantAgentToChangeVenue || attributes[questionaireIndex].StepStatusId == 17) //if unsubmitted, or if allowed by the step
                    {
                        switch(VenueButtonVisibleCheck(venues[questionaireIndex]))
                        {
                            case 0:
                            case 1:
                                $(`#openVenueModalBtnBox${questionaireIndex}`).remove();
                                break;
                            case 2:
                                $(`#openVenueModalBtnBox${questionaireIndex} > a`).removeClass("btn-success");
                                $(`#openVenueModalBtnBox${questionaireIndex} > a`).addClass("all-venues-full btn-warning disabled");
                                $(`#openVenueModalBtnBox${questionaireIndex} span`).attr("title", "All venues are full");
                                $(`#openVenueModalBtnBox${questionaireIndex}`).show();
                                break;
                            case 3:
                                $(`#openVenueModalBtnBox${questionaireIndex}`).show();
                                break;
                            default:
                                $(`#openVenueModalBtnBox${questionaireIndex}`).remove();
                                break;
                        }
                    }
                    else
                    {
                        $(`#openVenueModalBtnBox${questionaireIndex}`).remove();
                    }
                        
                    // Set isValid 'data' variable to HTML (this needs to be here after questionSet validity has been established)
                    $('#applicationForm' + questionaireIndex).data('isValid', questionSet.isValid);
                    
                    // Store current form state to use for dirtiness
                    $("#applicationForm" + questionaireIndex).data("formState", JSON.stringify($("#applicationForm" + questionaireIndex).serializeArray()));

                });

            }).fail(function(err) {
                logger.error(err);
                loading.release();
                bootoast.toast({
                    message: "An unknown error has occurred",
                    timeout: false,
                    type: "danger",
                    icon: "alert"
                });
            })
            
        }).fail(function(err) {
            logger.error(err);
            loading.release();
            bootoast.toast({
                message: "An unknown error has occurred",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
        })
        

        if (Number.isInteger(selectedTabIndex)) {
            $("#applicationCourseTabs li:eq(" + selectedTabIndex + ") a").tab('show');
        }
            
    }).fail(function(err) {
        logger.error(err);
        loading.release();
        bootoast.toast({
            message: "An unknown error has occurred",
            timeout: false,
            type: "danger",
            icon: "alert"
        });
    }).always(function(e){
        loading.release();
    });
}

function VenueButtonVisibleCheck(venues)
{
    var isArray = Array.isArray(venues) && venues.length > 0;

    if(isArray)
    {
        var enabledVenues = venues.filter(venue => venue.IsVenueEnabled && venue.IsEnabled);
        var hasVenues = enabledVenues.length > 0;
        
        if(hasVenues)
        {
            var hasOpenVenues = enabledVenues.filter(venue => venue.ApplicationLimit == 0 || (venue.ApplicationLimit > 0 && venue.SubmittedApplications < venue.ApplicationLimit)).length > 0;

            if(hasOpenVenues)
                return 3; //Course uses venues and there are open slots.
            else
                return 2; //Course uses venues, but all available venues are full
        }
        else
        {
            return 1; //There are no enabled venues (course has venues assigned, but doesn't use them)
        }
    }
    else
    {
        return 0; //Course has no venues
    }
}

/**
 * Displays course data
 * 
 * @param {object} course - The course object returned from API
 */
function displayCourseData(course) {
    if (course.Name) {
        $('.courseName').text("" + course.Name);
    }
    if (course.CourseCode) {
        $('.courseCode').text("" + course.CourseCode);
    }
    if (course.Info) {
        if (course.Info.indexOf("<br>") < 0)
            $('.courseInfo').text(course.Info);
        else {
            $('.courseInfo').html("");
            var split = course.Info.split("<br>");
            split.forEach(function (line) {
                $('.courseInfo').append("<span>" + line + "</span>");
            })
        }
    }

    var EUOpen = applyService.isCourseOpen(course.EUOpeningDate, course.EUClosingDate);
    var NonEUOpen = applyService.isCourseOpen(course.NonEUOpeningDate, course.NonEUClosingDate);

    if(!EUOpen || !NonEUOpen)
        generateAgentWarningForEUNonEU(EUOpen, NonEUOpen)

}

function generateAgentWarningForEUNonEU(EUOpen, NonEUOpen)
{
    if(EUOpen)
    {
        $(".agent-notice-eu-text").text("Non EU Citizens")
    }
    else if (NonEUOpen)
    {
        $(".agent-notice-eu-text").text("Non EU Citizens")
    } else
    {
        $(".agent-notice-eu-text").text("EU and Non EU Citizens")
    }

    $(".agent-eu-noneu-notice").show();
    return;
}

function prepareCourseModal(course, applicantInfoPrompt) {
    $.when(applyService.getCoursesByOrganisationIdWorkflowId(course.OrganisationId, course.WorkflowId),
        applyService.getApplicationsPerBatchLimitByCourseId(course.CourseId),
        parseInt(localStorage.UserTypeId)==1?applyService.getUserByUserName($("#userMenuElement .dropdown-toggle").text().trim()):null)
        .done(function (coursesByOrganisationId, applicationsPerBatchLimit, applicantInfo) {
            var limit = applicationsPerBatchLimit.AppPerBatchLimit ? applicationsPerBatchLimit.AppPerBatchLimit : 3;


            if(applicantInfo)
            {
                $("#courseBatchModal").data("applicant-eu", applicantInfo.EUCitizen);
            }

            if(limit == 1)
            {
                // $("#openCourseBatchModalBtn").closest("p").remove();
            }

            let now = new Date();
            now.setHours(0, 0, 0, 0);

            let courseAutoComplete = [];
            let courseNameAndIds = {};

            addRowToCourseBatch(course.CourseId, course.Name, course.CourseCode, false);

            $(".additional-course-counter-max").text(limit);

            if(limit == 1)
            {
                $(".multiple-courses-true").hide();
                $(".multiple-courses-false").show();
            }
            else
            {
                $(".multiple-courses-true").show();
                $(".multiple-courses-false").hide();
            }

            for (let i = 0, len = coursesByOrganisationId.length; i < len; i++) {
                if(parseInt(localStorage.UserTypeId) == 1)
                {
                    if(applicantInfo.EUCitizen)
                    {
                        if(!applyService.isCourseOpen(coursesByOrganisationId[i].EUOpeningDate, coursesByOrganisationId[i].EUClosingDate))
                            continue;
                    }
                    else
                    {
                        if(!applyService.isCourseOpen(coursesByOrganisationId[i].NonEUOpeningDate, coursesByOrganisationId[i].NonEUClosingDate))
                            continue;
                    }
                    
                }
                else
                {
                    if(!applyService.isCourseOpen(coursesByOrganisationId[i].NonEUOpeningDate, coursesByOrganisationId[i].NonEUClosingDate))
                            continue;
                }
                
                let courseId = urlHelper.getQueryVariable('c');
                if (courseId) {
                    if (courseId == coursesByOrganisationId[i].CourseId) {
                        continue;
                    }
                }

                let name = (coursesByOrganisationId[i].CourseCode + " - " + coursesByOrganisationId[i].Name).trim();
                courseAutoComplete.push(name);
                courseNameAndIds[name] = coursesByOrganisationId[i].Id;
            }

            $("#courseBatchModalCourseSearch").typeahead({
                source: courseAutoComplete,
                autoSelect: false,
                items: 'all',
                afterSelect: function (item) {
                    if ($('#courseBatchModalCourseTable > tbody > tr').length >= limit) {
                        bootoast.toast({
                            message: "Maximum amount of additional courses already reached!",
                            timeout: false,
                            type: "danger",
                            icon: "alert"
                        });
                    } else {
                        if (typeof courseNameAndIds[item] !== "undefined") {
                            if ($("#courseBatchModalCourseTable tr[data-courseid='" + courseNameAndIds[item] + "']").length == 0)
                                applyService.getCourseByCourseId(courseNameAndIds[item]).then(function (course) {
                                    if(isCourseDateValid(course, $("#courseBatchModal").data("applicant-eu")))
                                    {
                                        addRowToCourseBatch(course.CourseId, course.Name, course.CourseCode);
                                        delete courseNameAndIds[item];
                                        $("#courseBatchModalCourseSearch").val("");
                                    }
                                    else
                                    {
                                        bootoast.toast({
                                            message: "Unable to select the course due to EU / NonEU requirements!",
                                            timeout: false,
                                            type: "danger",
                                            icon: "alert"
                                        });
                                    }
                                    
                                }).fail(function(err) {
                                    logger.error(err);
                                    bootoast.toast({
                                        message: "An unknown error has occurred",
                                        timeout: false,
                                        type: "danger",
                                        icon: "alert"
                                    });
                                });
                        }
                    }
                }
            });

            if (applicantInfoPrompt) {
                $("#applicantDetailsModal").modal({
                    backdrop: "static",
                    keyboard: false
                });
            } else {
                $("#courseBatchModal").modal({
                    backdrop: "static",
                    keyboard: false
                });
            }
        });
}

function isCourseDateValid(course, ApplicantEU)
{
    if(ApplicantEU != null)
    {
        if(ApplicantEU)
        {
            return applyService.isCourseOpen(coursesByOrganisationId[i].EUOpeningDate, coursesByOrganisationId[i].EUClosingDate);
        }
        else
        {
            return applyService.isCourseOpen(coursesByOrganisationId[i].NonEUOpeningDate, coursesByOrganisationId[i].NonEUClosingDate);

        }
    }
    return true;
}

function prepareAdditionalCourseModal(OrganisationId, WorkflowId, coursesInBatch) {
    $("#courseBatchModalCourseTable > tbody").html("");
    return new Promise(function (resolve, reject) {

        $.when( applyService.getCoursesByOrganisationIdWorkflowId(OrganisationId, WorkflowId), 
                applyService.getApplicationsPerBatchLimitByCourseId(coursesInBatch[0].CourseId),
                applyService.getUserByUserName($("#applicationForm0").data("ApplicantEmail").trim()))
            .done(function (coursesByOrganisationId, applicationsPerBatchLimit, applicantInfo) {
                var limit = applicationsPerBatchLimit.AppPerBatchLimit ? applicationsPerBatchLimit.AppPerBatchLimit : 3;

                let now = new Date();
                now.setHours(0, 0, 0, 0);

                let courseAutoComplete = [];
                let courseNameAndIds = {};

                let batchCourses = []

                coursesInBatch.forEach(function (elem) {
                    batchCourses.push(elem.CourseId);
                    addRowToCourseBatch(elem.CourseId, elem.Name, elem.CourseCode, false);
                })

                let existingCoursesInBatch = coursesInBatch.map(x => x.CourseId);

                if (limit == coursesInBatch.length) {
                    $("#courseBatchModalAddAdditionalCourses").hide();
                }

                $(".additional-course-counter-max").text(limit);

                if(limit == 1)
                {
                    $(".multiple-courses-true").hide();
                    $(".multiple-courses-false").show();
                }
                else
                {
                    $(".multiple-courses-true").show();
                    $(".multiple-courses-false").hide();
                }

                for (let i = 0, len = coursesByOrganisationId.length; i < len; i++) {
                    if(existingCoursesInBatch.indexOf(coursesByOrganisationId[i].CourseId) > -1) continue;
                    if(applicantInfo.EUCitizen)
                    {
                        if(!applyService.isCourseOpen(coursesByOrganisationId[i].EUOpeningDate, coursesByOrganisationId[i].EUClosingDate))
                            continue;
                    }
                    else
                    {
                        if(!applyService.isCourseOpen(coursesByOrganisationId[i].NonEUOpeningDate, coursesByOrganisationId[i].NonEUClosingDate))
                            continue;
                    }

                    let name = (coursesByOrganisationId[i].CourseCode + " - " + coursesByOrganisationId[i].Name).trim();
                    courseAutoComplete.push(name);
                    courseNameAndIds[name] = coursesByOrganisationId[i].Id;
                }

                resolve();

                $("#courseBatchModalCourseSearch").typeahead({
                    source: courseAutoComplete,
                    autoSelect: false,
                    items: 'all',
                    afterSelect: function (item) {
                        if ($('#courseBatchModalCourseTable > tbody > tr').length >= limit) {
                            let courseStr = pageSettingsHelper.getSavedCourseString();
                            bootoast.toast({
                                message: `Maximum amount of additional ${courseStr}s already reached!`,
                                timeout: false,
                                type: "danger",
                                icon: "alert"
                            });
                        } else {
                            if (typeof courseNameAndIds[item] !== "undefined") {
                                if ($("#courseBatchModalCourseTable tr[data-courseid='" + courseNameAndIds[item] + "']").length == 0)
                                    applyService.getCourseByCourseId(courseNameAndIds[item]).then(function (course) {
                                        addRowToCourseBatch(course.CourseId, course.Name, course.CourseCode);
                                        delete courseNameAndIds[item];
                                        $("#courseBatchModalCourseSearch").val("");
                                    });
                            }
                        }
                    }
                });
            });
    });
}

function populatePagesMenu(pages){
    
}

/**
 * Deletes a row (tr) from the html table of the batch entry modal
 * 
 * @param {number} id - the id number used to select the tr element
 */
function deleteRowFromCourseBatch(id) {
    $('#courseBatchModalCourseTable tr[data-courseid="' + id + '"]').remove();

    $(".additional-course-counter-current").text($("#courseBatchModalCourseTable > tbody tr").length);
}

function renderFileList(applicationId, containerId, attributeInstanceId, filesInContainer) {
    var isApplicationEnabled = $(`form[data-application-id="${applicationId}"]`).data('is-application-enabled');
    var fileTable = $(`.attribute-file-table[data-application-id="${applicationId}"][data-attribute-instance-id="${attributeInstanceId}"]`);
    fileTable.empty();
    for (var i = 0; i < filesInContainer.length; i++) {
        if (i === 0) {
            fileTable.append(`
                <div class="file-table-title">Your uploaded file(s) for this question</div>
            `);
        }
        var file = filesInContainer[i];
        var fileId = (file.fileId === undefined ? file.FileId : file.fileId);
        var fileName = (file.name === undefined ? file.Name : file.name);
        var fileRowId = 'file-tr-' + applicationId + '-' + attributeInstanceId + '-' + i;
        fileTable.append('<div id="' + fileRowId + '" data-file-id="' + fileId + '" class="file-table-row" data-container-id="' + containerId + '"></div>');
        if(isApplicationEnabled == true)
        {
            $('#' + fileRowId).append('<div class="btn btn-xs btn-danger delete-file"><i class="fa fa-times"></i></div>');
        }
        $('#' + fileRowId).append('<span class="file-item" data-file-id="' + fileId + '">' + fileName + '</span>');
    }
    // Draw border box
    if (filesInContainer.length > 0) {
        fileTable.addClass("attribute-file-box");
    } else {
        fileTable.removeClass("attribute-file-box");
    }
}

function renderAttrtibuteFileList(attribute) {
    if(attribute && attribute.Value && attribute.Value.length > 0)
    {
        var containerId = attribute.Value[0].ContainerId;
        var attributeInstanceId = attribute.AttributeInstanceId;
        var applicationId = attribute.ApplicationId;
        renderFileList(applicationId, containerId, attributeInstanceId, attribute.Value);
    }
}


/****************************************************************
 *                          LOCAL FUNCTIONS
 ****************************************************************/

/**
 * Adds a row (tr) to the html table of the batch entry modal
 * 
 * @param {number} id - the id number used to select the tr element
 * @param {string} name - the name of the course selected
 * @param {string} code - the course code for the selected course
 * @param {boolean} [del=true] - flag to allow deletable row (the first initial row can not be deleted)
 */
function addRowToCourseBatch(id, name, code, del = true) {
    $("#courseBatchModalCourseTable > tbody").append(`
        <tr data-courseid="${id}">
            <td>${name}</td>
            <td class="text-center">${code}</td>
            <td class="text-center">${del === true ? "<a data-courseid=\"" + id + "\" id=\"courseBatchModalCourseCloseLink-" + id + "\" class=\"text-danger courseBatchModalCourseCloseLink\" href=\"#\"><i class=\"fa fa-close\" aria-hidden=\"true\"></i></a>" : ""}</td>
        </tr>
    `);

    $(".additional-course-counter-current").text($("#courseBatchModalCourseTable > tbody tr").length);
}

function conditionCheck(formIndex) {

    // First check if form has been invalidated, then disable condition checking - show all the invalid questions
    if ($("#applicationForm" + formIndex).data("invalid") === "true" && $("#applicationForm" + formIndex).data("applyShowHideConditions") === "false") {
        return;
    }
    // Loop through all form attributes to check the condition for it
    $("#applicationForm" + formIndex + " .form-group").each(function (i, e) {
        if ($(e).data("hidecond") && $(e).data("hidecond") !== null) {
            let id = $(e).attr("id").substring("div-".length);
            let json = $(e).data("hidecond");

            // Loop through conditions for the current attribute
            // let showCurrentAttribute = checkHideCondition(id, json);
            let showCurrentAttribute = true;

            // Combine same keys together
            let condBuilder = {};
            json.conditions.forEach(function (cond) {
                if (condBuilder.hasOwnProperty(cond.Key))
                    condBuilder[cond.Key] += ", " + String(cond.Value);
                else {
                    condBuilder[cond.Key] = {};
                    condBuilder[cond.Key] = String(cond.Value);
                }
            });

            let responses = [];

            Object.keys(condBuilder).forEach(function (key) {

                var fieldValue = null;
                var numberOfApps = $("#applicationCourseTabs li[role='presentation']").length-1;

                for(i = 0;i< numberOfApps;i++)
                {
                    fieldValue = getFieldValue(i, key);
                    if(fieldValue != null) break;
                }
                if (fieldValue != null)
                {
                    // Check if current attribute matches the value of defined condition
                    if (condBuilder[key].indexOf(fieldValue) > -1) {
                        responses.push(true);
                    } else {
                        responses.push(false);
                    }
                }
                else {
                    //Attribute not found in any app, show by default
                    responses.push(json.showConditions?true:false);
                }


            });

            if (responses.length == 0) {
                $(e).removeClass("hidden-attribute");
            } else {
                if (json.showConditions) {
                    if (responses.indexOf(false) < 0) {
                        $(e).removeClass("hidden-attribute");
                    } else {
                        $(e).addClass("hidden-attribute");
                        $("#" + $(e).attr("id")).val(""); // Clear the value so it doesn't get saved to application
                    }
                } else {
                    if (responses.indexOf(true) < 0) {
                        $(e).removeClass("hidden-attribute");
                    } else {
                        $(e).addClass("hidden-attribute");
                        $("#" + $(e).attr("id")).val(""); // Clear the value so it doesn't get saved to application
                    }
                }
            }
        }
    });
}

function conditionCheckAllApps()
{
    let numberOfApps = $("#applicationCourseTabs li[role='presentation']").length-1;
    for(i = 0;i< numberOfApps;i++)
    {
        conditionCheck(i);
    }
}

function getFieldValue(formIndex, key)
{
    var text = null;
    if ($("#" + formIndex + "-" + key).get(0) != undefined) {
        // Get the value of the element that current attribute depends on (will differ for HTML inputs)
        switch ($("#" + formIndex + "-" + key).get(0).tagName) {
            case "SELECT":
                text = $("#" + formIndex + "-" + key).find(":selected").text();
                break;
            case "INPUT":
                if ($("#" + formIndex + "-" + key).attr("type") === "checkbox") {
                    text = "" + $("#" + formIndex + "-" + key).is(":checked"); // Force string instead of boolean
                } else if ($("#" + formIndex + "-" + key).attr("type") === "radio") {
                    text = $("input[name ='" + key + "']:checked").attr("id");
                    switch (text) {
                        case formIndex + "-" + key + "-on":
                            text = "true";
                            break;
                        case formIndex + "-" + key + "-off":
                            text = "false";
                            break;
                        default:
                            text = "na";
                            break;
                    }
                } else {
                    text = $("#" + formIndex + "-" + key).val();
                }
                break;
            default:
                text = $("#" + formIndex + "-" + key).val();
                break;
        }
    }

    return text!=null?text.trim():text;
}

// Organize all attributes whilst also checking the validity status of each
// If any attributes are invalid, only return the list of invalid attributes in 1 page/tab, else return all
function sortAttributeDataFromAllElements(attributes) {
    let allData = [];
    attributes.forEach(function(attributeCollection, collectionIndex) {
        // if (attributeCollection.BatchStatusId >= 40 && attributeCollection.StepStatusId !== 17) {
        //     return;
        // }
        let invalidData = { Invalid: [] };
        let isValid = true;
        let collectionData = {};
        // Loop through all the attributes and check if any are invalid
        attributeCollection.Attributes.forEach(function(attribute, index) {
            if(!(attribute.SortOrder in collectionData) && !Array.isArray(collectionData[attribute.SortOrder])){
                collectionData[attribute.SortOrder] = [];
            }
            collectionData[attribute.SortOrder].push(attribute);
            if (!isAttributeValid(attribute)) {
                isValid = false;
                invalidData["Invalid"].push(attribute);
            }
        });
        allData.push({
            pages: attributeCollection.HasInvalidatedAttributes ? invalidData : collectionData,
            isValid: !attributeCollection.HasInvalidatedAttributes // Flip this as we want isValid not HasInvalid
        });
    });

    return allData;
}

function generateAttributeHtml(attribute, countries, index, isAppDisabled) {
    let multidocs = ""
    let multipleOption = ""
    let note = "";
    let description = "";
    if (attribute.Description && attribute.Description.length > 0) {
        description = `
            <div class="row">
                <div class="col-xs-12">
                    <div class="muted small">
                        <div id="help${attribute.AttributeInstanceId}" class="">
                            ${attribute.Description}
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
    if (attribute.AttributeTypeId == 10) {
        note = "ONLY a single file can be submitted for this question";
    }
    if (attribute.AttributeTypeId == 14) {
        multidocs = "multidocs";
        multipleOption = "multiple";
        note = "Multiple Files can be Uploaded. For assessment they will be merged in the order they are selected/listed";
    }
    if (attribute.AttributeTypeId == 15) {
        return `
            <div id="div-${index}-${attribute.ColumnName}" class="form-group pac-apply-form-group ${multidocs} form-attribute" data-attribute-instance-id="${attribute.AttributeInstanceId}" data-hideCond='${attribute.HideCondition}'>  
                <div class="row">
                    <div class="col-xs-6 col-sm-4">
                        <div class="label">
                            ${attribute.DisplayName}
                            ${ attribute.Mandatory ? "<span class=\"red\">*<span>" : ""}
                        </div>
                    </div>
                    <div class="col-xs-4 col-sm-6 ${ attribute.Mandatory ? "mandatory-attribute" : "optional-attribute"}">
                        ${ generateAttributeTypeFormElementHtml(attribute, multipleOption, countries, index, isAppDisabled)}
                    </div>
                    <div class="col-xs-2 col-sm-2">
                        <i class="fa fa-download fa-3x form-document-link" data-file-id="${attribute.FormDocumentId}"></i>
                    </div>
                </div>
                ${description}
                <div class="muted small">Please download the required file by clicking the <i class="fa fa-download form-document-link" data-file-id="${attribute.FormDocumentId}"></i> icon , fill it out and re-upload it here.</div>
            </div>
        `;
    }
    return `
        <div id="div-${index}-${attribute.ColumnName}" class="form-group pac-apply-form-group ${multidocs} form-attribute" data-attribute-instance-id="${attribute.AttributeInstanceId}" data-hideCond='${attribute.HideCondition}'>  
            <div class="row">
                <div class="col-xs-6 col-sm-4">
                    <div class="label">
                        ${attribute.DisplayName}
                        ${ attribute.Mandatory ? "<span>*<span>" : ""}
                    </div>
                </div>
                <div class="col-xs-6 col-sm-8 ${ attribute.Mandatory ? "mandatory-attribute" : "optional-attribute"}">
                    ${ generateAttributeTypeFormElementHtml(attribute, multipleOption, countries, index, isAppDisabled)}
                    <!--<span class="help-block"></span>-->
                </div>
            </div>
            ${description}
            ${note}
        </div>
    `;
}

function generateAttributeTypeFormElementHtml(attribute, multipleOption, countries, formindex, isAppDisabled) {
    let html = "";
    switch (attribute.AttributeTypeId) {
        case 1: // Number
        case 2: // Decimal Number
        case 12: // Number (positive int, non zero)
            html = `
                <input
                    id="${formindex}-${attribute.ColumnName}"
                    class="form-control"
                    name="${attribute.ColumnName}"
                    type="text"
                    value="${attribute.Value != null ? attribute.Value.replace(/\"/gi, '&#34;') : ""}"
                    ${isAppDisabled ? " disabled" : ""}>
            `;
            break;
        case 3: // Short text (single line)
            html = `
                <input
                    id="${formindex}-${attribute.ColumnName}"
                    class="form-control"
                    name="${attribute.ColumnName}"
                    type="text"
                    maxlength="100"
                    value="${attribute.Value != null ? attribute.Value.replace(/\"/gi, '&#34;') : ""}"
                    ${isAppDisabled ? " disabled" : ""}>
            `;
            break;
        case 8: // URL
            html = `
                <input
                    id="${formindex}-${attribute.ColumnName}"
                    class="form-control"
                    name="${attribute.ColumnName}"
                    type="text"
                    maxlength="2083"
                    value="${attribute.Value != null ? attribute.Value.replace(/\"/gi, '&#34;') : ""}"
                    ${isAppDisabled ? " disabled" : ""}>
            `;
            break;
        case 9: // Email
            html = `
                <input
                    id="${formindex}-${attribute.ColumnName}"
                    class="form-control"
                    name="${attribute.ColumnName}"
                    type="text"
                    maxlength="254"
                    value="${attribute.Value != null ? attribute.Value.replace(/\"/gi, '&#34;') : ""}"
                    ${isAppDisabled ? " disabled" : ""}>
            `;
            break;
        case 11: // Phone number
            html = `
                <input
                    id="${formindex}-${attribute.ColumnName}"
                    class="form-control"
                    name="${attribute.ColumnName}"
                    type="text"
                    maxlength="255"
                    value="${attribute.Value != null ? attribute.Value.replace(/\"/gi, '&#34;') : ""}"
                    ${isAppDisabled ? " disabled" : ""}>
            `;
            break;
        case 4: // Long text (multiline)
            html = `
                <textarea
                    id="${formindex}-${attribute.ColumnName}"
                    class="form-control textCounterTextArea"
                    name="${attribute.ColumnName}"
                    cols="30"
                    rows="10"
                    ${isAppDisabled ? " disabled" : ""}
                    data-formIndex="${formindex}"
                >${attribute.Value != null ? attribute.Value.replace(/\"/gi, '&#34;') : ""}</textarea>
                <div class="row">
                    <div class="col-xs-12 col-sm-6">
                        <div class="textCounter">
                            Character Count
                            <span id="charCounter-${formindex}-${attribute.ColumnName}" class="badge">0</span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 text-right">
                        <div class="textCounter">
                            Word Count
                            <span id="wordCounter-${formindex}-${attribute.ColumnName}" class="badge">0</span>
                        </div>
                    </div>
                </div>
            `;
            break;
        case 5: // Boolean
            html = `
                <input
                    id="${formindex}-${attribute.ColumnName}"
                    class="form-control"
                    name="${attribute.ColumnName}"
                    type="checkbox"
                    ${checkBooleanValue(attribute.Value) ? "checked" : ""}
                    ${isAppDisabled ? " disabled" : ""}>
            `;
            break;
        case 6: // Date
            // format the date for use in html date input (YYYY-MM-DD)
            if (attribute.Value) {
                let mom = moment(attribute.Value);
                if (mom.isValid()) {
                    attribute.Value = mom.format("YYYY-MM-DD");
                }
            }
            html = `
                <input
                    id="${formindex}-${attribute.ColumnName}"
                    class="form-control"
                    name="${attribute.ColumnName}"
                    type="date"
                    ${attribute.Value ? "value=\"" + attribute.Value + "\"" : ""}
                    ${isAppDisabled ? " disabled" : ""}>
            `;
            break;
        case 7: // Lookup
            html = `
                <select
                    id="${formindex}-${attribute.ColumnName}"
                    class="form-control"
                    name="${attribute.ColumnName}"
                    ${isAppDisabled ? " disabled" : ""}>
                    <option value="" disabled ${attribute.Value === null ? "selected" : ""}>-- Please Select --</option>
            `;
            if (Array.isArray(attribute.SelectableValues)) {
                attribute.SelectableValues.forEach(val => {
                    html += `
                        <option
                            value="${val.ValueId}"
                            ${attribute.Value === val.Value ? "selected" : ""}
                        >${val.Value}</option>
                    `;
                });
            }
            html += "</select>";
            break;          
        case 13: // Years dropdown
            let currentYear = new Date().getFullYear();
            html = `
                <select
                    id="${formindex}-${attribute.ColumnName}"
                    class="form-control"
                    name="${attribute.ColumnName}"
                    ${isAppDisabled ? " disabled" : ""}>
            `;
            html += `<option></option>`;
            for (let ii = currentYear + 5; ii > currentYear - 20; ii--) {
                html += `
                    <option value="${ii}" ${attribute.Value === ii ? "selected" : ""}>
                        ${ii}
                    </option>
                `;
            }
            html += "</select>";
            break;
        case 10: // File
        case 14: // Multi-file
        case 15: // Form document
            html = `
                <input
                    id="${formindex}-${attribute.ColumnName}"
                    class="form-control"
                    name="${attribute.ColumnName}"
                    type="file"
                    ${multipleOption}
                    ${(typeof attribute.ValidityStatus !== "undefined" && attribute.ValidityStatus === 0) ? "data-edit='true'" : ""}
                    ${isAppDisabled ? " disabled" : ""}>
                <div id="file-table-${attribute.ColumnName}" class="attribute-file-table small" data-application-id="${attribute.ApplicationId}" data-attribute-instance-id="${attribute.AttributeInstanceId}">
                </div>
            `;
            break;
        case 16: // Three state toggle
            html = `
                <div class="form-control pac-3state ${isAppDisabled ? " disabled" : ""}">
                    <input id="${formindex}-${attribute.ColumnName}-on" name="${attribute.ColumnName}" type="radio" value="true" ${checkBooleanValue(attribute.Value) === true ? "checked=\"checked\"" : ""} ${isAppDisabled ? " disabled" : ""} />
                    <label for="${formindex}-${attribute.ColumnName}-on" class="tristateon ${isAppDisabled ? " disabled" : ""}" onclick="">Yes</label>
                    
                    <input id="${formindex}-${attribute.ColumnName}" name="${attribute.ColumnName}" type="radio" value="na" disabled ${checkBooleanValue(attribute.Value) === null ? "checked=\"checked\"" : ""} ${isAppDisabled ? " disabled" : ""} />
                    <label for="${formindex}-${attribute.ColumnName}" class="disabled" onclick="">&nbsp;</label>
                    
                    <input id="${formindex}-${attribute.ColumnName}-off" name="${attribute.ColumnName}" type="radio" value="false" ${checkBooleanValue(attribute.Value) === false ? "checked=\"checked\"" : ""} ${isAppDisabled ? " disabled" : ""} />
                    <label for="${formindex}-${attribute.ColumnName}-off" class="tristateoff ${isAppDisabled ? " disabled" : ""}" onclick="">No</label>
                    
                    <a></a>
                </div>
            `;
            break;
        case 17: // Country
            html = `
                <select
                    id="${formindex}-${attribute.ColumnName}"
                    class="form-control"
                    name="${attribute.ColumnName}"
                    ${isAppDisabled ? " disabled" : ""}>
            `;
            if (Array.isArray(countries)) {
                countries.forEach(val => {
                    html += `
                        <option
                            value="${val.CountryId}"
                            ${parseInt(attribute.Value) === val.CountryId ? "selected" : ""}
                        >${val.Name}</option>
                    `;
                });
            }
            html += "</select>";
            break;
        case 18: // Priority list
            let values = [];
            if(attribute.Value && attribute.Value.length > 0)
            {
                attribute.Value.forEach(function(item){
                    if(item && item.ValueId){
                        values.push(item.ValueId);
                    }
                    else
                    {
                        values.push(null);
                    }
                });
            }

            html = `<div class="priority-list-div" data-column-name="${attribute.ColumnName}">`;
            
            //A hidden field that holds the JSON array of data from the priority list select elements.
            html += `<input type="hidden" id="${formindex}-${attribute.ColumnName}" name="${attribute.ColumnName}" class="priority-list" value="${JSON.stringify(values)}" ${isAppDisabled ? " disabled" : ""} />`;

            for (let priorityListItemIndex = 0; priorityListItemIndex < attribute.NumberOfPriorityListItems; priorityListItemIndex++)
            {
                let currentValue = values[priorityListItemIndex];
                html += `
                    <div style="margin-bottom:.5rem;">
                    <span>${priorityListItemIndex + 1}.</span>
                    <select
                        id="${attribute.ColumnName}-${priorityListItemIndex}"
                        class="priority-list-select form-control"
                        style="display:inline-block;width:95%;margin-left:.25rem;"
                        data-column-name="${attribute.ColumnName}"
                        data-formindex="${formindex}"
                        ${isAppDisabled ? " disabled" : ""}>
                `;
                if (Array.isArray(attribute.SelectableValues)) {
                    html += `<option value="" ${currentValue == "" ? "selected" : ""}>-- Please select your option --</option>`;                    
                    attribute.SelectableValues.forEach(val => {
                        if(!values.includes(parseInt(val.ValueId)) || parseInt(val.ValueId) == parseInt(currentValue)){
                            html += `<option value="${val.ValueId}" ${val.ValueId == currentValue ? "selected" : ""}>${val.Value}</option>`;
                        }                        
                    });
                }
                html += "</select></div>";
            }
            html += "</div>";
            break;  
        case 19: // Constrained long text
            let constraints = JSON.parse(attribute.Constraint);
            html = `
                <textarea
                    id="${formindex}-${attribute.ColumnName}"
                    class="form-control textCounterTextArea"
                    name="${attribute.ColumnName}"
                    cols="30"
                    rows="10"
                    ${isAppDisabled ? " disabled" : ""}
                    data-formIndex="${formindex}"
                >${attribute.Value != null ? attribute.Value.replace(/\"/gi, '&#34;') : ""}</textarea>
                <div class="row">
                    <div class="col-xs-12 col-sm-6">
                        <div class="textCounter">
                            Character Count
                            <span class="badge">
                                <span id="charCounter-${formindex}-${attribute.ColumnName}">${attribute.Value != null ? attribute.Value.length : "0"}</span>
                            </span>
                        </div>
                        <div class="small">
                            Min: ${constraints.min_chars} | Max: ${constraints.max_chars}
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 text-right">
                        <div class="textCounter">
                            Word Count
                            <span class="badge">
                                <span id="wordCounter-${formindex}-${attribute.ColumnName}">${attribute.Value != null ? attribute.Value.trim().replace(/\s\s+/g, " ").split(/\s/).length : "0"}</span>
                            </span>
                        </div>
                        <div class="small">
                            Min: ${constraints.min_words} | Max: ${constraints.max_words}
                        </div>
                    </div>
                </div>
            `;
            break;
        default:
            html = `<div class="red"><strong>** Error finding attribute type</strong></div>`
            break;
    }
    return html;
}

function setPriorityList(attribute, formindex)
{
    var selects = $('*[data-attribute-instance-id="'+ attribute.AttributeInstanceId +'"]').find('select');
    var values = [];
    selects.each(function (i, e){
        values.push(parseInt($(e).val()));
    });

    $("input#" + formindex + "-" + attribute.ColumnName).val(JSON.stringify(values));

    selects.each(function (i, e){
        var currentValue = $(e).val();
        $(e).children('option').remove();
        if (Array.isArray(attribute.LookupValues)) {
            $(e).append(`<option value="" ${currentValue == "" ? "selected" : ""}>-- Please select your option --</option>`);
            attribute.LookupValues.forEach(val => {
                if(!values.includes(parseInt(val.ValueId)) || parseInt(val.ValueId) == parseInt(currentValue)){
                    $(e).append(`<option value="${val.ValueId}" ${val.ValueId == currentValue ? "selected" : ""}>${val.Value}</option>`);
                }
            });
        }
    });
}

function checkBooleanValue(Value) {
    if (Value === undefined || Value === null) return null;
    if (Value === "true" || Value === "True" || Value === true || Value === 1 || Value === "On") return true;
    if (Value === "false" || Value === "False" || Value === false || Value === 0 || Value === "Off") return false;
    return null;
}

function isAttributeValid(attribute) {
    // TRUE = valid attribute (default)
    // FALSE = not valid attribute
    if (typeof attribute.ValidityStatus !== "undefined" && attribute.ValidityStatus === 0) {
        return false;
    }
    return true;
}

function removeSubmitApplicationButton() {
    $("#applicationFormSubmitBtn").parent().remove();
}

function RemoveSaveProgressButton()
{
    $("#applicationFormSaveProgressBtn").parent().remove();
}

function checkIfTemplateVersionMatches(course, application) {
    return course.ApplicationTemplateVersionId === application.TemplateVersionId;
}

function fillRecommendations(references) {
    $(".recommendation-inputs").html("");
    let reqNum = parseInt($(".recommendation-amount").text());
    let curReqNum = 0;
    let invalidNum = 0;

    if (!$.isEmptyObject(references)) {
        references.forEach(function (reference) {

            let statusClass = "";
            let statusDOM = "";
            let cancelDOM = "disabled";


            if (reference.IsAnswered) {
                if (reference.IsRecommendationRequestAccepted) {
                    statusDOM = '<span class="label label-success">Accepted</span>';
                }
                else {
                    invalidNum++;
                    statusClass = "recommendation-rejected";
                    statusDOM = '<span class="label label-danger">Rejected</span>';
                }
            }
            if (reference.IsAnswerable) {
                statusDOM = '<span class="label label-default">Pending</span>';
                cancelDOM = '';
            }
            if (reference.HasExpired) {
                invalidNum++;
                statusClass = "recommendation-expired";
                statusDOM = '<span class="label label-warning">Expired</span>';
            }
            if (reference.IsCancelled) {
                invalidNum++;
                statusClass = "recommendation-cancelled";
                statusDOM = '<span class="label label-info">Cancelled</span>';
            }
            curReqNum++;
            $(".recommendation-inputs").append(`<div class="form-group">
                    <div class="col-xs-1">
                        <input type="checkbox" class="form-control action-checkbox" data-id="${reference.RecommendationId}" ${cancelDOM} />
                    </div>
                    <div class="col-xs-2">E-mail: </div>
                    <div class="col-xs-7">
                        <input class="form-control ${statusClass}" name="recommender-${curReqNum}" disabled type="email" value="${reference.Email}" />
                    </div>
                    <div class="col-xs-2">${statusDOM}</div>
                </div>`);


        });
    }

    if (curReqNum - invalidNum < reqNum) {
        curReqNum -= invalidNum;
        for (curReqNum; curReqNum < reqNum; curReqNum++)
            $(".recommendation-inputs").append(`<div class="form-group">
            <div class="col-xs-1"></div>
            <div class="col-xs-2">E-mail: </div>
            <div class="col-xs-7">
                <input class="form-control" name="recommender-${curReqNum}" type="email" required />
            </div>
        </div>`);
        $("#recommendationModalRequest").show();
    }
    else {
        $("#recommendationModalRequest").hide();
    }
}

function fillSupervisors(supervisorRequests, supervisors) {

    $(".supervision-inputs").html("");
    let reqNum = parseInt($(".supervisor-amount").text());
    let curReqNum = 0;
    let invalidNum = 0;

    var supervisorOption = '<option selected disabled>-- please choose --</option>';

    supervisors.forEach(function (sup) {
        supervisorOption += `<option value="${sup.SupervisorId}">${sup.Name}</option>`
    })

    if (!$.isEmptyObject(supervisorRequests)) {
        supervisorRequests.forEach(function (supervisorRequest) {
            let status = "";

            let statusClass = "";
            let statusDOM = "";
            let cancelDOM = "disabled";

            if (supervisorRequest.IsAnswered) {
                if (supervisorRequest.IsAccepted) {
                    statusDOM = '<span class="label label-success">Accepted</span>';
                }
                else {
                    invalidNum++;
                    statusClass = "supervision-rejected";
                    statusDOM = '<span class="label label-danger">Rejected</span>';
                }
            }
            if (supervisorRequest.IsAnswerable) {
                statusDOM = '<span class="label label-default">Pending</span>';
                cancelDOM = '';
            }
            if (supervisorRequest.HasExpired) {
                invalidNum++;
                statusClass = "supervision-expired";
                statusDOM = '<span class="label label-warning">Expired</span>';
            }
            if (supervisorRequest.IsCancelled) {
                invalidNum++;
                statusClass = "supervision-cancelled";
                statusDOM = '<span class="label label-info">Cancelled</span>';
            }

            curReqNum++;
            $(".supervision-inputs").append(`<div class="form-group">
                        <div class="col-xs-1">
                            <input type="checkbox" class="form-control action-checkbox" data-id="${supervisorRequest.SupervisionRequestId}" ${cancelDOM} />
                        </div>
                        <div class="col-xs-2">Supervisor: </div>
                        <div class="col-xs-7">
                            <select class="form-control ${statusClass}" name="supervisor-${curReqNum}" disabled>
                                ${supervisorOption}
                            </select>
                        </div>
                        <div class="col-xs-2">${statusDOM}</div>
                    </div>`);
            $(".supervision-inputs select").last().val(supervisorRequest.SupervisorId);

        });
    }

    if (curReqNum - invalidNum < reqNum) {
        curReqNum -= invalidNum;
        for (curReqNum; curReqNum < reqNum; curReqNum++)
            $(".supervision-inputs").append(`<div class="form-group">
                <div class="col-xs-1"></div>
                <div class="col-xs-2">Supervisor: </div>
                <div class="col-xs-7">
                    <select class="form-control" name="supervisor-${curReqNum}">
                        ${supervisorOption}
                    </select>
                </div>
            </div>`);
        $("#supervisionModalRequest").show();
    }
    else {
        $("#supervisionModalRequest").hide();
    }
}



function prepareVenueModal(venues, venueId) {

    var dropdown = $(`#venue-dropdown`);
    $(dropdown).empty();
    $(dropdown).append('<option selected disabled>-- please choose --</option>');
    if(Array.isArray(venues))
    {
        venues.forEach(function(venue){
            var state = checkVenueState(venue, venueId);
            if(state > 0)
                $(dropdown).append(`<option ${state==2?`class="disabled-venue-option"`:""} data-venue-name="${venue.VenueName}" value="${venue.VenueId}" ${state==1?"":"disabled"}>${state==2?`[Unavailable]`:""} ${venue.CourseName} - ${venue.VenueName}</option>`)
        });
    }
    $("#venueModal .modal-title").text(`${(venueId != null && venueId != "")?"Change":"Select"} venue`)

    if(venueId != null && venueId != "")
        $("#venue-dropdown").val(venueId);
    else
        $("#venueModal .modal-title").text("Select venue");
}

function checkVenueState(venue, venueId)
{
    var venueOn = venue.IsVenueEnabled && venue.IsEnabled;
    var venueFree = venue.ApplicationLimit == 0 || ( venue.ApplicationLimit > 0 && venue.SubmittedApplications < venue.ApplicationLimit );
    var venueSelected = venue.VenueId == venueId;

    if(venueOn && venueFree)
        return 1;
    else
    {
        if(venueSelected) return 2;
        return 0;
    }
}

function fillcardHolderCountry(data, select)
{
    $(select).empty();
    data.forEach( function(country){
        $(select).append(`<option value="${country.CallingCode}">${country.Name} (+${country.CallingCode})</option>`);
    });
}

function populateCountriesSelect(countries){
    let select = $("#countryid");
    let cardholderCountryCodeSelect = $("#cardholderCountryCode");
    let agentCardholderCountryCodeSelect = $("#agentCardholderCountryCode");
    countries.forEach(function(country) {
        //$(select).append(`<option value="${country.CountryId}">${country.Name}</option>`);
        $(cardholderCountryCodeSelect).append(`<option value="${country.CountryId}" data-isocode="${country.Iso3166Code}">${country.Name}</option>`);
        $(agentCardholderCountryCodeSelect).append(`<option value="${country.CountryId}" data-isocode="${country.Iso3166Code}">${country.Name}</option>`);
    });
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/
module.exports = {
    displayCourseData: displayCourseData,
    deleteRowFromCourseBatch: deleteRowFromCourseBatch,
    renderFileList: renderFileList,
    conditionCheck: conditionCheck,
    getFieldValue: getFieldValue,
    conditionCheckAllApps:conditionCheckAllApps,
    removeSubmitApplicationButton: removeSubmitApplicationButton,
    RemoveSaveProgressButton: RemoveSaveProgressButton,
    prepareAdditionalCourseModal: prepareAdditionalCourseModal,
    checkIfTemplateVersionMatches: checkIfTemplateVersionMatches,
    fillRecommendations: fillRecommendations,
    fillSupervisors: fillSupervisors,
    prepareVenueModal: prepareVenueModal,
    setPriorityList: setPriorityList,
    populateCountriesSelect: populateCountriesSelect,
    batchForm: batchForm
}