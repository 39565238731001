const microLayer = require('./microLayerService');
const error = require('../services/errorHandling');
const logger = require('../helpers/clientLogger');

function GetApplication(data) {
    return microLayer.execute(microLayer.httpMethods.PUT, "public/supervisionRequests/application", data)
        .then(function (response) { return response })
        .fail(function (err) { logger.error(err); error.handleWithToast(err, "There was an error while getting supervision request data.") });
}

function UpdateBySupervisor(data) {
    return microLayer.execute(microLayer.httpMethods.PUT, "public/supervisionRequests", data)
        .then(function (response) { return response })
        .fail(function (err) { logger.error(err); error.handleWithToast(err, "There was an error sending supervision request info.") });
    
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    GetApplication: GetApplication,
    UpdateBySupervisor: UpdateBySupervisor
}