const microLayer = require('../services/microLayerService');
const logger = require('../helpers/clientLogger');

module.exports = {
    getApplicantPdfExport: getApplicantPdfExport,
    getByFileId: getByFileId,
    getByContainerId: getByContainerId,
    getByApplicationIdAttributeInstanceId: getByApplicationIdAttributeInstanceId,
    getByApplicationId: getByApplicationId,
    getDownloadUrl: getDownloadUrl,
    uploadContainerFiles: uploadContainerFiles,
    deleteFile: deleteFile,
    uploadRecommendationFile: uploadRecommendationFile,
    uploadApplicantFile: uploadApplicantFile
}

function getApplicantPdfExport(applicationId, data) {
    return microLayer.fileServer(
        microLayer.httpMethods.POST,
        `Applications/${applicationId}/ApplicantPdfExport`,
        data,
        {
            headers: {
                Authorization: localStorage.getItem("SessionToken")
            },
            xhrFields: {
                withCredentials: true,
                responseType: "arraybuffer"
            }
        })
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
        });        
}

function getByFileId(id) {
    return microLayer.execute(microLayer.httpMethods.GET, "files/" + id)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
        });        
}

function getByContainerId(ContainerId) {
    return microLayer.execute(microLayer.httpMethods.GET, "files/" + ContainerId + "/ByContainerId")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
        });        
}

function getByApplicationIdAttributeInstanceId(ApplicationId, AttributeInstanceId) {
    return microLayer.execute(microLayer.httpMethods.GET, "files/" + ApplicationId + "/" + AttributeInstanceId + "/ByApplicationIdAttributeInstanceId")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
        });        
}

function getByApplicationId(ApplicationId) {
    return microLayer.execute(microLayer.httpMethods.GET, "files/application/" + ApplicationId)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
        });        
}

function getDownloadUrl(FileId) {
    return microLayer.execute(microLayer.httpMethods.GET, "files/" + FileId + "/GetDownloadUrl")
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
        });        
}

function uploadContainerFiles(formData) {
    return microLayer.fileServer(microLayer.httpMethods.POST, "ApplicationDocuments", formData)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);
            var error = "An error has occurred uploading file(s).";
            if(err && err.responseText)
            {
                if(err.responseText.indexOf("password"))
                {
                    error = "The document is password protected or invalid."
                }
            }
            bootoast.toast({
                message: error,
                timeout: false,
                type: "danger",
                icon: "alert"
            });
            // error.log(err.status, err.statusText, err.responseText);
        });
}

function deleteFile(fileId) {
    return microLayer.fileServer(microLayer.httpMethods.DELETE, "Files/" + fileId)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);            
            bootoast.toast({
                message: "There was an error deleting file.",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
            // error.log(err.status, err.statusText, err.responseText);
        });
}  

function uploadRecommendationFile(formData)
{
    return microLayer.fileServer(microLayer.httpMethods.POST, "Recommendations", formData)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);            
            bootoast.toast({
                message: "There was an error uploading the reference file.",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
            // error.log(err.status, err.statusText, err.responseText);
        });
}

function uploadApplicantFile(formData)
{
    return microLayer.fileServer(microLayer.httpMethods.POST, "ApplicantsDocuments", formData)
        .then(function (response) {
            return response;
        })
        .fail(function (err) {
            logger.error(err);            
            bootoast.toast({
                message: "There was an error uploading the file.",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
            // error.log(err.status, err.statusText, err.responseText);
        });
}