const profileService = require("../services/profileService");
const loading = require("../helpers/loading");
const logger = require('../helpers/clientLogger');

function buildCountries(countries)
{
    countries.forEach(function(country){
        $("#countryid").append('<option value="'+country.CountryId+'">'+country.Name+'</option>')
    })
    
}

function buildProfile(me)
{
    $("#formContainer").data('isAgent', me.UserTypeId==2);
    $("#name").val(me.FirstName);
    $("#surname").val(me.LastName);
    $("#address1").val(me.AddressLine1);
    $("#address2").val(me.AddressLine2);
    $("#city").val(me.City);
    $("#postalcode").val(me.PostalCode);
    if(me.CountryId != null)
        $("#countryid").val(me.CountryId);

    $("#phonenumber").val(me.PhoneNumber);
    if(me.DateOfBirth != null)
    $("#dateofbirth").val(me.DateOfBirth.substring(0,10));
    if(me.EUCitizen != null)
        $("#EUCitizen").prop('checked', me.EUCitizen);

    toggleAgentApplicantDetails(me.UserTypeId);

    if(me.UserTypeId == 2)
    {
        $("#agentName").val(me.DisplayName);
        $("#agentCompany").val(me.Company);
        $("#euBlock").hide();
        $("#nameBlock").hide();
        $("#dobBlock").hide();
    } else {
        $("#euBlock").show();
        $("#nameBlock").show();
        $("#dobBlock").show();
    }

    if($("#submitUpdateProfileFormBtn").data("check-validation"))
        $("#submitUpdateProfileFormBtn").click();
}

function buildAgentApproval(agentApprovalStatuses) {
    if (localStorage.getItem("UserTypeId") === "2") {
        loading.block();
        $("#approvalPanel").removeClass("hidden");

        agentApprovalStatuses.forEach((org, orgIndex) => {
            $("#approval").append(`
            <div class="list-group-item lgi-flex">
                <p>
                    ${org.OrganisationName}
                </p>
                <p class="big-label">
                    <span id="approvalStatus-${org.OrganisationId}" class="label ${org.AgentStatusId==20?'label-success':'label-danger'} pull-right">
                        ${org.AgentStatus}
                    </span>
                </p>
            </div>
        `);
        })

        loading.release();
    } else {
        $("#approvalPanel").addClass("hidden");
    }
}

function toggleAgentApplicantDetails(utid) {
    if (utid==2) {
        $("#agentDetails").show();
    } else {
        $("#agentDetails").hide();
    }
}

module.exports = {
    buildCountries: buildCountries,
    buildProfile: buildProfile,
    buildAgentApproval: buildAgentApproval,
    toggleAgentApplicantDetails: toggleAgentApplicantDetails
}