const auth = require('../services/authentication');
const instituteService = require('../services/instituteService');
const pageSettingsHelper = require("../helpers/pageSettingsHelper");

/****************************************************************
 *                          EXPORTED FUNCTIONS
 ****************************************************************/

/**
 * Adds the institute menu HTML to the header
 */
function instMenu() {
    instituteService.getUniversities().then(function(universities) {
        let unisNamesArray = [];
        let sortedUnis = [];
        // 1. get all the uni names
        universities.forEach(function(university) {
            unisNamesArray.push(university.Name);
        });
        // 2. Sort names alphabetically
        unisNamesArray.sort();
        // 3. for each name, get the uni and store it in sorted array
        unisNamesArray.forEach(function(name) {
            universities.forEach(function(university) {
                if (university.Name === name) {
                    sortedUnis.push(university);
                }
            });
        });
        // 4. build menu from sorted array
        sortedUnis.forEach(function(university) {
            if (parseInt(university.Version) > 0 ) {
                $("#inst-menu-dropdown").append(`
                    <li><a href="/institute/${university.OrganisationId}">${university.Name}</a></li>
                `);
            }
        });
    });
}

/**
 * Replaces the login button on header menu with a user menu
 */
function hideLoginButton() {
    $("#userMenuElement").html(`
        <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">${localStorage.UserName}</a>
        <ul class="dropdown-menu">
            <li><a href="/dashboard">My Applications</a></li>
            <li><a href="/profile">My Profile</a></li>
            <li><a class="logoutBtn" href="#">Logout</a></li>
        </ul>
    `);
    $("#menuRegBtn").html("");
    $("#userMenuElement").removeClass("apply_now");
    $("#userMenuElement").addClass("dropdown");
}

/**
 * Replaces the user menu on header menu with a login button
 */
function showLoginButton() {
    $("#userMenuElement").html(`<a id="loginBtn" href="#" role="button">Login</a>`);
    // $("#menuRegBtn").html(`<a href="/register" role="button">Register</a>`);
    $("#userMenuElement").removeClass("dropdown");
    $("#userMenuElement").addClass("apply_now");
}

/**
 * This function is used to display a message/HTML to the header
 * (which means EVERY page on the site)
 * The msg will sit between the nav bar and the main page content
 * 
 * @param {string} msg - The text/html to display
 * @param {string} type - A string to determine which type (colour) of bootstrap CSS alert component to use (Defaults to 'danger')
 */
function broadcastMsg(msg, type = "danger") {
    let typeText = "danger"; // Default type
    switch (type) {
        case "primary":
        case "success":
        case "info":
        case "warning":
            typeText = type;
            break;
    }
    $("#msgBroadcastContainer").html('<div class="alert alert-' + typeText + '">' + msg + '</div>');
}

function togglePendingActionContainer(show = true)
{

    //exceptions
    if(window.location.pathname.startsWith("/dashboard"))
    {
        $("#pendingActionWarningContainer a").hide();
    }
    if(window.location.pathname.startsWith("/apply"))
    {
        show = false;
    }

    if(show)
    {
        $("#pendingActionWarningContainer").show();
    }
    else
    {
        $("#pendingActionWarningContainer").hide();
    }
}

function checkUserData(data)
{
    if(data.UserTypeId == 1)
    {
        //check if user is already redirected to correct data
        if(window.location.href.indexOf("MissingProfileData")<0)
        {
            //if not, check profile and redirect if needed
            var profileValid = true;

            if(data.EUCitizen    == null) profileValid = false;
            if(data.FirstName    == null) profileValid = false;
            if(data.LastName     == null) profileValid = false;
            if(data.AddressLine1 == null) profileValid = false;
            if(data.City         == null) profileValid = false;
            if(data.PostalCode   == null) profileValid = false;
            if(data.CountryId    == null) profileValid = false;
            if(data.PhoneNumber  == null) profileValid = false;
            if(data.DateOfBirth  == null) profileValid = false;

            if(!profileValid)
                window.location.href = "/profile?MissingProfileData"
        }
        else
        {
            //concat a list of invalid fields
            var invalidFields = [];

            if(data.FirstName    == null || data.FirstName == "")    invalidFields.push("First Name");
            if(data.LastName     == null || data.LastName     == "") invalidFields.push("Last Name");
            if(data.AddressLine1 == null || data.AddressLine1 == "") invalidFields.push("Address line 1");
            if(data.City         == null || data.City         == "") invalidFields.push("City");
            if(data.PostalCode   == null || data.PostalCode  == "")  invalidFields.push("Post code");
            if(data.CountryId    == null)                            invalidFields.push("Country");
            if(data.PhoneNumber  == null || data.PhoneNumber == "")  invalidFields.push("Phone Number");
            if(data.DateOfBirth  == null)                            invalidFields.push("Date of Birth");
            if(data.EUCitizen    == null)                            invalidFields.push("EU Citizen");

            //If any other field except this checkbox is false, this will trigger html validation to highlight the missing fields.
            if(invalidFields.length > 1 || invalidFields.length == 1 && invalidFields[0] != "EU Citizen")
                $("#submitUpdateProfileFormBtn").data("check-validation", true);

            $(".user-data-panel").prepend(`<div class="alert alert-danger">
                <strong>Please note:</strong> Your profile is missing some information. Please check the form below and correct/add the information and press the <b>Update</b> button.<br />
                <span>Missing fields: <b>${invalidFields.join(", ")}</b></span>
            </div>`)
        }
    }
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    instMenu: instMenu,
    hideLoginButton: hideLoginButton,
    showLoginButton: showLoginButton,
    broadcastMsg: broadcastMsg,
    togglePendingActionContainer: togglePendingActionContainer,
    checkUserData: checkUserData
}