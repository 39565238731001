const microLayer = require('../services/microLayerService');
const logger = require('../helpers/clientLogger');

module.exports = {
	getQueryVariable:getQueryVariable,
	getRoomAttributes: getRoomAttributes,
	getAllRoomAttributes: getAllRoomAttributes,
	getSlot: getSlot,
	bookSlot: bookSlot,
	unbookSlot: unbookSlot
}

function getQueryVariable(variable){
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i=0; i<vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return null;
  }

//ROOM ATTRIBUTES
function getRoomAttributes(roomId) {         
	return microLayer.execute(microLayer.httpMethods.GET, "interviews/rooms/"+roomId+"/attributes")
		.then(function (attributes) {
			return attributes;
		})
		.fail(function(err){
			logger.error(err);
            bootoast.toast({
                message: "There was an error getting room attributes.",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
			console.log(err.status, err.statusText, err.responseText);
		});        
}

function getAllRoomAttributes() {         
	return microLayer.execute(microLayer.httpMethods.GET, "interviews/roomAttributes")
		.then(function (attributes) {
			return attributes;
		})
		.fail(function(err){
			logger.error(err);
            bootoast.toast({
                message: "There was an error getting room attribute.",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
			console.log(err.status, err.statusText, err.responseText);
		});        
}

//SLOT MANAGEMENT
function getSlot(slotId) {
	return microLayer.execute(microLayer.httpMethods.GET, "interviews/slots/" + slotId)
		.then(function (response) {
			return response;
		})
		.fail(function(err){
			logger.error(err);
            bootoast.toast({
                message: "There was an error retrieving an interview slot.",
                timeout: false,
                type: "danger",
                icon: "alert"
            });
			console.log(err.status, err.statusText, err.responseText);
		});  
}

function bookSlot(slotId, slotFormData) {         
	return microLayer.execute(microLayer.httpMethods.PUT, "interviews/slots/" + slotId + "/book", slotFormData)
		.then(function (slot) {
			return slot;
		})
		.fail(function(err){
			logger.error(err);
			if(err && err.responseJSON && err.responseJSON.originalError && err.responseJSON.originalError.info && err.responseJSON.originalError.info.class == 16 && err.responseJSON.originalError.info.state == 1)
			{
				bootoast.toast({
					message: err.responseJSON.originalError.info.message,
                    timeout: false,
					type: "danger",
                    icon: "alert"
				});
			}
			else
			{
				bootoast.toast({
					message: "There was an error booking an interview slot.",
                    timeout: false,
					type: "danger",
                    icon: "alert"
				});
			}
			console.log(err.status, err.statusText, err.responseText);
		});        
}

function unbookSlot(slotId) {         
	return microLayer.execute(microLayer.httpMethods.PUT, "interviews/slots/" + slotId + "/unbook")
		.then(function (slot) {
			return slot;
		})
		.fail(function(err){
			logger.error(err);			
			if(err && err.responseJSON && err.responseJSON.originalError && err.responseJSON.originalError.info && err.responseJSON.originalError.info.class == 16 && err.responseJSON.originalError.info.state == 1)
			{
				bootoast.toast({
					message: err.responseJSON.originalError.info.message,
                    timeout: false,
					type: "danger",
                    icon: "alert"
				});
				
			}
			else
			{
				bootoast.toast({
					message: "There was an error unbooking an interview slot.",
                    timeout: false,
					type: "danger",
                    icon: "alert"
				});
			}
			console.log(err.status, err.statusText, err.responseText);
		});        
}