/****************************************************************
 *                          EXPORTED FUNCTIONS
 ****************************************************************/

/**
 * Extracts the value of a URL variable
 * 
 * @param {string} variable - the name of the variable to extract from URL parameters
 * @return the value of the variable or NULL if variable does not exist
 */
function getQueryVariable(variable) {
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return null;
}

/**
 * Gets a url param in browser
 * @param position The position of the url param to get (starting index 1);
 * position 2 will get the 2nd url param;
 * -1 will get the last param;
 * defaults to -1
 */
function getUrlParam(position = -1) {
    let thePath = window.location.pathname;
    let pathSegments = thePath.split("/");
    if (position === -1) {
        position = pathSegments.length - 1;
    }
    return pathSegments[position];
}

/****************************************************************
 *                          MODULE EXPORTS
 ****************************************************************/

module.exports = {
    getQueryVariable: getQueryVariable,
    getUrlParam: getUrlParam
}