import "../events/supervisionEvents";
const supervisionBuilder = require("../builders/supervisionBuilder");
const supervisionService = require("../services/supervisionService");
const loadingHelper = require('../helpers/loading');
const urlHelper = require('../helpers/urlHelper');


loadingHelper.block();

let UUID = urlHelper.getQueryVariable("u");

if(UUID == null || UUID == "")
{
    supervisionBuilder.buildNoEntryPage("The link for the supervision request you have followed is not valid.");
    loadingHelper.release();
}
else
{
    supervisionService.GetApplication({SupervisionRequestUuid:UUID})
    .then(function(app){
        if(!$.isEmptyObject(app))
        {
            supervisionBuilder.buildSupervisionPage(app);
        }
        else
        {
            supervisionBuilder.buildNoEntryPage("The link for the supervision request you have followed is not valid.");
        }
        loadingHelper.release();
    })
}




